import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import {
  IManufactory,
  IManufactoryCategoriesResponse,
  IManufactoryCategory,
} from '@app/core/interface/manufactories.interface';
import { Component, Inject, OnInit } from '@angular/core';
import { AddFactoryGroup } from './add-factory.group';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { ManufactoriesFacade } from '@app/core/facade/manufactories.facade';
import { CountryList } from '@app/core/utils/country-list';
import { map, Observable, startWith } from 'rxjs';
import {
  ICountry,
  ISuppliersResponse,
  ISuppliersErrorResponse,
  ISupplier,
} from '@app/core/interface/suppliers.interface';
import { SetCountry } from '@app/core/utils/country';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

interface IDialogData extends IManufactory {
  supplierManufactories: IManufactory[] | undefined;
  supplier?: string;
  logo: string | null;
}

@Component({
  selector: 'app-add-factory',
  templateUrl: './add-factory.component.html',
  styleUrls: ['./add-factory.component.scss'],
  providers: [AddFactoryGroup, ManufactoriesFacade, SuppliersFacade],
})
export class AddFactoryComponent implements OnInit {
  form: UntypedFormGroup;
  countryList = CountryList.isoCountries;
  filteredCountries!: Observable<ICountry[]>;
  supplierList?: ISupplier[];
  categories?: IManufactoryCategory[];
  selectedSupplier?: ISupplier;
  supplierLogo!: string | null | undefined;
  supplierManufactoriesList?: IManufactory[];

  constructor(
    private _group: AddFactoryGroup,
    private _facade: ManufactoriesFacade,
    private _suppliersFacade: SuppliersFacade,
    private _snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,
    public dialogRef: MatDialogRef<AddFactoryComponent>
  ) {
    this.form = this._group.addFactory;
    if (data) {
      this.supplierLogo = this.data.logo;
    }
    this.form.patchValue({
      ...this.data,
      country: SetCountry(this.countryList, this.data.country),
      is_main_location: this.data.uuid ? this.data.is_main_location : !this.data.supplierManufactories?.length,
    });

    this.filteredCountries = this.form.controls['country'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || ''))
    );
  }

  ngOnInit(): void {
    this._suppliersFacade.getSupplierList$('is_active', 999999).subscribe({
      next: this.supplierListSuccess.bind(this),
      error: this._error.bind(this),
    });

    this._facade.getManufactoryCategories$().subscribe({
      next: this.categoriesListSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  supplierListSuccess(data: ISuppliersResponse): void {
    this.supplierList = data.results;
    this.selectedSupplier = this.supplierList.find(supplier => supplier.uuid === this.data.supplier);
    this.supplierManufactoriesList = this.data.supplierManufactories;
  }

  categoriesListSuccess(data: IManufactoryCategoriesResponse): void {
    this.categories = data.results;
  }

  addFactory(): void {
    if (this.form.valid && !this.data.uuid) this.form.controls['supplier'].markAsDirty();
    const changedFromValues = GetDirtyValues(this.form);
    if (changedFromValues['country'])
      if (typeof changedFromValues['country'] === 'string') {
        this.countryList.filter(country => {
          if (country.name.toLowerCase() === changedFromValues['country'].toLowerCase()) {
            changedFromValues['country'] = country.code;
            this.form.controls['country'].patchValue(country);
          }
        });
      } else {
        changedFromValues['country'] = this.form.controls['country'].value.code;
      }
    changedFromValues['is_main_location'] = this.form.controls['is_main_location'].value;
    if (
      this.form.valid &&
      !this.data.uuid &&
      (changedFromValues['country'] || changedFromValues['region'] || changedFromValues['address'])
    ) {
      this._facade.createSupplierSite$(changedFromValues).subscribe({
        next: this._createSupplierFactorySuccess.bind(this),
        error: this._error.bind(this),
      });
    } else if (this.form.valid && this.data.uuid) {
      this._facade.updateSupplierSite$(this.data.uuid, { ...changedFromValues }).subscribe({
        next: this._createSupplierFactorySuccess.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  saveImageData(file: File): void {
    this.form.get('logo')?.setValue(file);
    this.form.get('logo')?.markAsDirty();
  }

  private _filter(value: object): ICountry[] {
    const filterValue = typeof value === 'string' || value instanceof String ? value.toString().toLowerCase() : '';

    return this.countryList.filter(country => country.name.toLowerCase().includes(filterValue));
  }

  private _createSupplierFactorySuccess(data: IManufactory): void {
    this._snackbarService.openTypeSnackbar(
      `Factory ${data.name} ${this.data.uuid ? 'Updated' : 'Added'}`,
      NotificationType.success
    );
    this.dialogRef.close(true);
  }

  private _error(error: IManufactory | ISuppliersErrorResponse): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  /**
   * Shows only name as display value and keeps hole obj as actual value (code is used for requiest and name for display)
   * @param subject ICountry from country dropdown
   * @returns country name for display
   */
  displayFn(subject: ICountry | null): string {
    return subject?.name ?? '';
  }
}
