import { Component, OnInit, ViewChild } from '@angular/core';
import { IBrandFeatures, IBrandFeaturesResponse } from '@app/core/interface/brands.interface';
import { NotificationType, PROJECT_MANAGEMENT } from '@app/core/constants';

import { BrandsFacade } from '@app/core/facade/brands.facade';
import { CreateProductDialogComponent } from '@app/shared/components/create-product-dialog/create-product-dialog.component';
import { ICreateProductResponse } from '@app/core/interface/register.interface';
import { IDashboardBrandInfo } from '@app/core/interface/dashboard.interface';
import { IProduct } from '@app/core/interface/products.interface';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  providers: [ProductsFacade],
})
export class ProductsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  productsDashboardInfo?: IDashboardBrandInfo;
  assetPath = environment.assetPath;
  brandFeatures!: IBrandFeatures[];
  projectManagement!: IBrandFeatures | undefined;
  show_project!: boolean;

  constructor(
    private _productsFacade: ProductsFacade,
    private _router: Router,
    private _snackbarService: SnackbarService,
    private _brandsFacade: BrandsFacade,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this._productsFacade.getProductsDashboard$().subscribe({
      next: this._getProductsSuccess.bind(this),
      error: this._error.bind(this),
    });
    this.getBrandFeatures();
  }

  openCreateDialog(productForCopy?: IProduct) {
    const config = productForCopy
      ? {
          data: { mode: 'duplicate', productId: productForCopy.uuid },
        }
      : {};
    const dialogRef = this.dialog.open(CreateProductDialogComponent, {
      ...config,
      width: '1160px',
      height: '768px',
      panelClass: ['overflow-hidden-dialog', 'top-padding-0'],
    });

    dialogRef.afterClosed().subscribe((result: ICreateProductResponse) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  private _getProductsSuccess(data: IDashboardBrandInfo): void {
    if (data) {
      this.productsDashboardInfo = data;
    } else {
      this._router.navigate(['/products/create-product']);
    }
  }

  getBrandFeatures(): void {
    this._brandsFacade.getBrandFeatures$().subscribe({
      next: this._successBrandFeatures.bind(this),
      error: this._error.bind(this),
    });
  }

  private _successBrandFeatures(data: IBrandFeaturesResponse) {
    this.brandFeatures = data.results;
    if (this.brandFeatures) {
      this.projectManagement = this.brandFeatures.find(project => project.name === PROJECT_MANAGEMENT);
      if (this.projectManagement) {
        this.show_project = this.projectManagement.is_enabled;
      }
    }
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
