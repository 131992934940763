import { Component, Inject, OnInit, OnDestroy } from '@angular/core';

import { ChainOverviewGroup } from '../../chain-overview.group';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IPublicationGroup, IPublicationType } from '@app/core/interface/publication.interface';
import { PublicationFacade } from '@app/core/facade/publication.facade';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { CustomPublicationsFacade } from '@app/module/publication/custom-publications/custom-publications.facade';

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss'],
})
export class CreateGroupComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  selectedClassification?: IPublicationType;
  selectedSteps: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      productChainUuid: string;
      group?: IPublicationGroup;
      type_step: string;
    },
    private _group: ChainOverviewGroup,
    private _facade: PublicationFacade,
    private _customPublication: CustomPublicationsFacade,
    private dialogRef: MatDialogRef<CreateGroupComponent>,
    private _snackbarService: SnackbarService
  ) {
    this.form = this._group.createGroupForm;
    this.form.get('chain')?.setValue(this.data.productChainUuid);
    this.form.get('chain')?.markAsDirty();
    this.form.get('type_step')?.setValue(this.data.type_step);
    this.form.get('type_step')?.markAsDirty();
    this.form.get('color')?.setValue(this.data.group?.color ? this.data.group.color : '#000000');
    this.form.get('color')?.markAsDirty();
  }

  ngOnInit(): void {
    if (this.data.group) {
      this.form.patchValue({
        name: this.data.group.name,
      });
    }
  }

  ngOnDestroy(): void {
    this.data.group = undefined;
    this.form.reset();
  }

  saveChanges(): void {
    this._customPublication.createPublicationGroup$({ ...this.form.value }).subscribe({
      next: this.createGroupSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  createGroupSuccess(data: IPublicationGroup) {
    this.dialogRef.close(data);
  }

  updateGroup(): void {
    if (this.data.group) {
      this._customPublication.updatePublicationGroup$({ ...this.form.value }, this.data.group.uuid).subscribe({
        next: () => this.dialogRef.close({ ...this.form.value }),
        error: this._error.bind(this),
      });
    }
  }

  deleteGroup(uuid: string): void {
    this._facade.deletePublicationGroup$(uuid).subscribe({
      next: () => this.dialogRef.close(true),
      error: this._error.bind(this),
    });
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  colorPickerChange(color: string): void {
    this.form.get('color')?.setValue(color);
    this.form.get('color')?.markAsDirty();
  }
}
