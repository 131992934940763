import {
  ICreatePublicationStep,
  IDeletePublicationGroup,
  IMapBoxFullAddress,
  IProductPublication,
  IPublicationErrorResponse,
  IPublicationStep,
} from '../interface/publication.interface';
import { IPreviewPublication, Step, Type } from '../interface/preview_publicaton.interface';
import { Subject, take } from 'rxjs';

import { ICustomPublicationStep } from '@app/module/publication/custom-publications/custom-publications.models';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ObservableInput } from 'rxjs/internal/types';
import { PublicationService } from '../service/publication.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { generateFormData } from '../utils/generate-formData';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class PublicationFacade {
  expandedType = new Subject<number>();
  onStepSelected = new Subject<{ step: Step; type: Type }>();
  constructor(private _publicationService: PublicationService) {}

  getPublicationProductChains$(uuid: string): Observable<IProductPublication[]> {
    return this._publicationService.getPublicationProductChains$(uuid).pipe(
      take(1),
      catchError((err): ObservableInput<IProductPublication[]> => throwError(() => err.error))
    );
  }
  updatePublicationChainStep$(data: Partial<ICreatePublicationStep>, uuid: string): Observable<ICustomPublicationStep> {
    const formData = generateFormData(data);
    return this._publicationService.updatePublicationChainStep$(formData, uuid).pipe(
      take(1),
      catchError(
        ({ error }: ICustomPublicationStep): ObservableInput<ICustomPublicationStep> => throwError(() => error)
      )
    );
  }

  deletePublicationStepCustomFields$(groupUuid: string, customFieldUuid: string): Observable<IPublicationStep> {
    const formData = generateFormData({ custom_field: customFieldUuid });
    return this._publicationService.deletePublicationStepCustomFields$(formData, groupUuid).pipe(
      take(1),
      catchError(({ error }: IPublicationStep): Observable<IPublicationStep> => throwError(() => error))
    );
  }

  updateChainClassificationsPosition$(data: string[]): Observable<string[]> {
    return this._publicationService.updateChainClassificationsPosition$(data).pipe(
      take(1),
      catchError((error: IPublicationErrorResponse): ObservableInput<string[]> => throwError(() => error))
    );
  }

  updateStepsPosition$(data: string[]): Observable<string[]> {
    const formData = generateFormData({
      steps: data,
    });
    return this._publicationService.updateStepsPosition$(formData).pipe(
      take(1),
      catchError((error: IPublicationErrorResponse): ObservableInput<string[]> => throwError(() => error))
    );
  }

  deletePublicationGroup$(uuid: string): Observable<IDeletePublicationGroup> {
    return this._publicationService.deletePublicationGroup$(uuid).pipe(
      take(1),
      catchError(
        ({ error }: IDeletePublicationGroup): ObservableInput<IDeletePublicationGroup> => throwError(() => error)
      )
    );
  }

  getFullAddress$(searchInput: string): Observable<IMapBoxFullAddress> {
    return this._publicationService.getFullAddress$(searchInput).pipe(
      take(1),
      catchError((err): ObservableInput<IMapBoxFullAddress> => throwError(() => err.error))
    );
  }

  getPreviewPublication$(productUid: string): Observable<IPreviewPublication> {
    return this._publicationService.getPreviewData$(productUid).pipe(
      take(1),
      catchError(({ error }: IPreviewPublication): ObservableInput<IPreviewPublication> => throwError(() => error))
    );
  }

  addCustomFieldToGroup(custom_field: string, uuid: string): Observable<string[]> {
    const formData = generateFormData({ custom_field });
    return this._publicationService.addCustomFieldToGroup(formData, uuid).pipe(
      take(1),
      catchError((error: IPublicationErrorResponse): ObservableInput<string[]> => throwError(() => error))
    );
  }
}
