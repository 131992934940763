<div *ngIf="isVisibleBackToTableButton()" class="back-btn back-to-top-button cursor-pointer">
  <app-custom-icon
    id="back-btn"
    matTooltip="Back to table of contents"
    [icon_category]="'files'"
    [icon_name]="'table'"
    (click)="backToTable()"
  >
  </app-custom-icon>
</div>

<p class="title" id="idTitle">Service agreement for SaaS services</p>
<div class="content">
  <!-- Introduction -->
  <p class="content-title">Introduction</p>
  <div class="content-text">
    <p>
      Product DNA SA (PDNA) markets application services accessible online of which it is the owner (SaaS services). The
      Client wishes to acquire new information technology tools and to use a SaaS service offered by PDNA in connection
      with its business operations. PDNA, in keeping with its obligation to inform and advise its clients, has
      communicated to the Client the present commercial proposal and/or Documentation presenting the Service to the
      Client, which the Client acknowledges it has read. It is the responsibility of the Client, notably on the basis of
      this information, to ensure the Services are complementary to its particular needs and constraints. To this end,
      prior to accepting the Agreement, the Client may ask PDNA for any supplementary information and/or to attend a
      demonstration of the Service, after which point the Client acknowledges that it has been sufficiently informed of
      the aforementioned products.
    </p>
    <p>
      Any specification or document prepared by the Client indicating any additional requests shall under no
      circumstances be taken into account by PDNA for purposes of the Agreement, except upon PDNA’s express consent
      before execution the Agreement, with the understanding that any agreed-upon specifications or additional requests
      shall be included as an annex to the Agreement. Any special requests made by the Client to modify the Service to
      better conform to the Client’s needs can only be performed by PDNA in the frame of Professional Services which are
      not within the scope of the present Master Service Agreement (MSA) for SaaS Services. The Client acknowledges that
      the Professional Services proposed by PDNA are necessary for the proper use of the Service. It is the
      responsibility of the Client to assess whether it will use the Professional Services offered by PDNA.
    </p>
  </div>
  <!-- Table of contents -->
  <p class="content-title" id="idContentTable" #contentTable>Table of contents</p>

  <ol>
    @for (item of tableOfContents; track $index) {
      <li
        class="content-order-list cursor-pointer"
        (click)="lazyLoadAndGoToViewPort(item.id)"
        (mouseover)="hoverItem($index)"
        (mouseleave)="unhoverItem($index)"
      >
        {{ item.title }}
        <app-custom-icon
          class="cursor-pointer"
          icon_category="arrows"
          icon_name="{{ 'arrow-circle-down' }}"
          [hidden]="!item.hovered"
        >
        </app-custom-icon>
      </li>
    }
  </ol>

  <!-- 1. Definitions -->
  @defer (when definitionArray.includes(currentLazyLoadingTrigger()); on viewport) {
    <p class="content-title" id="idDefinition">1. Definitions</p>
    <div class="content-text">
      <p>
        For implementation hereof, the terms and expressions identified by a capital letter have the meaning indicated
        below, whether used in the singular or plural. Agreement. Means either:
      </p>
      <p>
        • a set of documents including or incorporating by reference the “Statement of Work”, the “Order Form”, this MSA
        for SaaS Services, as well as the Terms of Service and the Technical Prerequisites and any other general terms
        and conditions applicable to ordered elements referred to in the “Description of Items Orders”; or
      </p>
      <p>
        • an order placed online that is validated by an authorized signatory of the Client and that includes or
        incorporates by reference the “SOW” (indicating the quantity and price of such items), the "SEPA Mandate" (if
        applicable), this MSA, as well as the Terms of Service and the Technical Prerequisites and any other general
        terms and conditions applicable to ordered elements referred to in the “Description of Items Orders”.
      </p>
      <p>
        <b>Client.</b> Means the business who is identified as such in the Order Form or online order and who is
        entering into the Order Form or online order for its or his business or professional purposes only.
      </p>
      <p>
        <b>Client Data.</b> Means information and data (including Personal Data), of which the Client is the owner
        and/or controller and that the Client enters, fills in, transmits, collects, stores and or processes in
        connection with performance of the Agreement.
      </p>
      <p>
        <b>Documentation.</b> Means the information made available by PDNA describing the methods for use of the
        Service, in the form of user documentation accompanying the Service and/or online help.
      </p>
      <p>
        <b>Effective Date.</b> Means the date that the Agreement comes into effect, as indicated on the signature page
        on the Order Form.
      </p>
      <p><b>Extended Service Period.</b> Shall have the meaning given in Clause ‘Effective Date – Term’.</p>
      <p><b>Initial Service Period.</b> Shall have the meaning given in Clause ‘Effective Date – Term’</p>
      <p>
        <b>Personal Data.</b> Means any information relating to an identified or identifiable natural person as defined
        in more detail in the applicable data protection and privacy legislation in force from time to time in
        Switzerland, including the General Data Protection Regulation ((EU) 2016/679 – “GDPR”) (collectively “Applicable
        Regulations”), that Client processes in connection with the performance of the Agreement.
      </p>
      <p>
        <b>Portal.</b> Means the web services portal which PDNA makes available to its clients. The Portal is accessible
        at the address https://www.productdna.com or any other website address, as communicated by PDNA
      </p>
      <p>
        <b>Professional Services.</b> Means the PDNA professional services to implement the Service offered by PDNA
        (analysis, configuration, training, etc.) and subscribed for by the Client in the “SOW”, which are subject to
        separate terms and conditions.
      </p>
      <p>
        <b>Service.</b> Means the standard application functionalities delivered online, as well as Updates and Support,
        invoiced as a subscription and/or on a usage basis. The Services are intended for professional use.
      </p>
      <p><b>Service Activation.</b> Means activation of the Service by PDNA.</p>
      <p>
        <b>Support.</b> Means assistance to the Client in case of failure of the Service to operate in all material
        aspects in accordance with the Documentation. Support can only be performed by PDNA where the Client has the
        technical equipment allowing for remote assistance.
      </p>
      <p>
        <b>Technical Prerequisites.</b> Means the latest version of the list of characteristics of the hardware and
        computer devices of the Client recommended by PDNA which must be implemented and complied with by the Client to
        access and use the Service. The Technical Prerequisites may be amended by PDNA from time to time and the latest
        updated version will be available on PDNA’s website, which is permanently accessible at:
        https://www.productdna.com or at any other website address that PDNA will communicate to Client. It is the
        Client's responsibility to upgrade its User Workstations according to any modifications made to the Technical
        Prerequisites
      </p>
      <p><b>Term.</b> Means the Initial Service Period and any Extended Service Period.</p>
      <p>
        <b>Third Party Service.</b> Means the application functionalities delivered online of which the author,
        publisher and operator is a third party but for which PDNA has obtained distribution and sub-licensing rights.
      </p>
      <p>
        <b>Updates.</b> Means modifications, adaptations and error corrections made in PDNA’s sole discretion to the
        existing standard application functionalities accessible as part of the Service, excluding adaptations and
        modifications requiring a re-write of a substantial portion of the existing standard application services. The
        Updates are provided according to the Terms of Service.
      </p>
      <p>
        <b>User Workstation.</b> Means the hardware and information technology equipment of the Client allowing it to
        access the Service. The User Workstation must comply with the Technical Prerequisites
      </p>
      <p>
        <b>User.</b> Means, under the Client’s responsibility, any natural person authorized by the Client to use the
        Service, as further defined below in Clause “Right of Access”.
      </p>
    </div>
  } @loading {
    Loading...
  } @placeholder {
    <p>Placeholder...</p>
  } @error {
    <p>Someting went wrong ... Please try again</p>
  }
  <!-- 2. Purpose -->
  @defer (when purposeArray.includes(currentLazyLoadingTrigger()); on viewport) {
    <p class="content-title" id="idPurpose">2. Purpose</p>
    <p class="content-sub-title">2.1 Acceptance of the Agreement</p>
    <div class="content-text">
      <p>
        The Client is deemed to have read the Agreement as defined in Clause “Definitions,” and to have duly accepted it
        without reservation. The Agreement is deemed accepted by the Client upon the latter’s execution of the Order
        Form and/or of the direct debit authorization in printed form or when the Client concludes an order online
        making reference to this MSA and acknowledging acceptance of the entire Agreement. Any modification of this MSA
        for SaaS Services must be duly accepted and signed by the Parties, failing which, any amendment or alteration
        made on the pre-printed part of the Agreement (i.e., “Order Form,” “SOW”) shall be deemed null and void and
        without effect.
      </p>
    </div>
    <p class="content-sub-title">2.2 Purpose</p>
    <div class="content-text">
      <p>
        The Agreement may be accepted by the Client remotely, and the Client acknowledges that faxes bearing the
        signature of one of the Client’s duly authorized representatives that are received by PDNA constitute evidence
        of a valid contract and can be enforced by PDNA accordingly. Electronic acceptance of the Agreement has the same
        probative value between the Parties as a printed agreement. The computerized records stored in the Parties’
        archives shall be stored in accordance with reasonable security measures and shall be deemed valid proof of the
        communications between the Parties. The archiving of contractual documents is made on a reliable, durable
        support medium which can be validly produced as evidence.
      </p>
    </div>
  } @loading {
    Loading...
  } @placeholder {
    <p>Placeholder...</p>
  } @error {
    <p>Someting went wrong ... Please try again</p>
  }
  <!-- 3. Effective date -->
  @defer (when effectiveDateArray.includes(currentLazyLoadingTrigger()); on viewport) {
    <p class="content-title" id="idEffectiveDate">3. Effective date – term</p>
    <div class="content-text">
      <p>
        The Agreement takes effect on the Effective Date and remains in force for the Term of the Service, unless
        terminated earlier in accordance with Clause “Termination”.
      </p>
      <p>
        Except as stated otherwise in the SOW, the Service is concluded for an initial service period of twelve (12)
        months, commencing on Service Activation (Initial Service Period). Unless terminated earlier in accordance with
        Clause “Termination” or this Clause, the Service will automatically extend for twelve (12) months (Extended
        Service Period) at the end of the Initial Service Period and at the end of each Extended Service Period. Either
        Party may give written notice by registered mail to the other Party, not later than one (1) month before the end
        of the Initial Service Period or the relevant Extended Service Period, to terminate this Agreement at the end of
        the Initial Service Term or the relevant Extended Service Term.
      </p>
      <p>
        Activation of a supplementary optional service in the course of provision of the Service will not modify the
        Term.
      </p>
    </div>
  } @loading {
    Loading...
  } @placeholder {
    <p>Placeholder...</p>
  } @error {
    <p>Someting went wrong ... Please try again</p>
  }
  <!-- 4. Scope of the service -->
  @defer (when scopeOfServiceArray.includes(currentLazyLoadingTrigger()); on viewport) {
    <p class="content-title" id="idScopeOfTheService">4. Scope of the service</p>
    <div class="content-text">
      <p>
        The list of standard application functionalities accessible as part of the Service as ordered by the Client is
        specified in the “SOW” or in the online order form.
      </p>
    </div>
  } @loading {
    Loading...
  } @placeholder {
    <p>Placeholder...</p>
  } @error {
    <p>Someting went wrong ... Please try again</p>
  }
  <!-- 5. Service activation -->
  @defer (when serviceActivationArray.includes(currentLazyLoadingTrigger()); on viewport) {
    <p class="content-title" id="idServiceActivatione">5. Service activatione</p>
    <div class="content-text">
      <p>
        The list of standard application functionalities accessible as part of the Service as ordered by the Client is
        specified in the “SOW” or in the online order form.
      </p>
    </div>
  } @loading {
    Loading...
  } @placeholder {
    <p>Placeholder...</p>
  } @error {
    <p>Someting went wrong ... Please try again</p>
  }
  <!-- 6. Right of access to the service -->
  @defer (when rightOfAccess.includes(currentLazyLoadingTrigger()); on viewport) {
    <p class="content-title" id="idRightOfAccess">6. Right of access to the service</p>
    <p class="content-sub-title">6.1 Right of access</p>
    <div class="content-text">
      <p>
        PDNA, subject to the provisions of the Agreement and the Client’s payment in full of the subscription fees
        provided in the “SOW” or in the online order form, grants to the Client a non-exclusive, non-transferable,
        non-sublicensable right to permit the Users to use the Service and the Documentation during the Term for the
        Client’s internal business operations. This right to use the Service is limited as fixed in the “SOW” and “Order
        Form” or in the online order form and if applicable, in the MSA.
      </p>
      <p>
        Access to the Service, in whole or in parts, may require the installation of an executable program installed on
        the hardware and computer devices or on the User Workstation.
      </p>
    </div>
    <p class="content-sub-title">6.2 Intellectual property</p>
    <div class="content-text">
      <p>
        The Client acknowledges and agrees that PDNA and/or its licensors own all intellectual property rights in the
        Service and the Documentation. Except as expressly stated herein, this Agreement does not grant the Client any
        rights to, under or in, any patents, copyright, database right, trade secrets, trade names, trademarks (whether
        registered or unregistered), or any other rights or licenses in respect of the Service or the Documentation.
        This Agreement does not confer on the Client any property rights concerning the Service, its technology or the
        intellectual property rights of PDNA or a third party. The Client shall not cause any damage whatsoever to the
        Service or notably use the Service other than for its intended purpose according to the conditions in the
        Agreement. Consequently, the Client shall refrain from any reverse engineering of the Service with a view to
        creating a competing product or Service and/or copying or reproducing any functionalities, functions or graphic
        attributes of the Service. The Client:
      </p>
      <p>
        • undertakes to solely use the Service in conformity to its Documentation for the sole purposes of its
        professional activity;
      </p>
      <p>
        • is solely liable for the contents published and/or downloaded via the Services and is solely liable for the
        nature, content, accuracy, integrity and legality of the Client Data sent to PDNA in the frame of the Service
        and for the resulting operations. In particular, considering the authorized use of the Service by the Client,
        the latter shall refrain from sending or storing non-professional data and more generally any data which is
        unlawful, obscene, defamatory, or illegal data or data violating third party rights, protection of children or
        other privacy rights;
      </p>
      <p>
        • undertakes to refrain from distributing the Service, making it available to third parties or leasing it unless
        otherwise specified in the corresponding SOW;
      </p>
      <p>
        • undertakes not to distort or interfere with the integrity or implementation of the Service or the data it
        contains;
      </p>
      <p>
        • undertakes not to seek to obtain unauthorized access to the Service or systems or networks associated with it.
      </p>
    </div>
  } @loading {
    Loading...
  } @placeholder {
    <p>Placeholder...</p>
  } @error {
    <p>Someting went wrong ... Please try again</p>
  }
  <!-- 7. Conditions of execution of service -->
  @defer (when conditionsOfExecutionArray.includes(currentLazyLoadingTrigger()); on viewport) {
    <p class="content-title" id="idConditionsOfExecution">7. Conditions of execution of service</p>
    <p class="content-sub-title">7.1 Supply of the service</p>
    <div class="content-text">
      <p>
        PDNA agrees to use reasonable skill and care in supplying the Service as provided by the MSA or the SOW, which
        specifies among other things the content, limitations, term, associated procedures and methods for establishing
        Updates and Support.
      </p>
    </div>
    <p class="content-sub-title">7.2 Responsibilities of the Client</p>
    <div class="content-text">
      <p>
        The Service shall be used by the Client under its sole control, direction and responsibility. The Client shall
        act as guarantor for compliance by Users under this Agreement. Without limiting the foregoing, the Client is
        responsible for:
      </p>
      <p>
        • adopting and maintaining procedures and measures to protect its User Workstations, hardware, software, and
        passwords, including against any virus or intrusion;
      </p>
      <p>
        • obtaining and maintaining all necessary licenses, consents, and permissions necessary for PDNA, its
        contractors and agents to perform their obligations under this Agreement, including without limitation the
        Service;
      </p>
      <p>• compliance with the latest updated version of the Technical Prerequisites;</p>
      <p>• selecting, obtaining and maintaining all required internet access and telecommunications;</p>
      <p>
        • being, to the extent permitted by law and except as otherwise expressly provided in this Agreement, solely
        responsible for procuring, maintaining and securing its network connections and telecommunications links from
        its systems to PDNA's datacenters, and all problems, conditions, delays, delivery failures and all other loss or
        damage arising from or relating to the Client's network connections or telecommunications links or caused by the
        internet.
      </p>
      <p>
        • appointment, among its personnel, of a preferred contact for PDNA acting as SaaS administrator for the Client,
        for the Service, and including concerning security issues;
      </p>
      <p>
        • use of login or access codes sent to it by PDNA on execution of the Service. It shall ensure that no
        unauthorized person has access to the Service; and
      </p>
      <p>
        • errors committed by its personnel and Users while connected to or using the Service, notably concerning
        internet access and browsing.
      </p>
      <p>
        PDNA disclaims all liability for the quality and electronic transmission of data using telecommunications
        networks and more generally the quality and reliability of the internet connection between the Client’s User
        Workstations and the Service access point. In addition, PDNA shall not be responsible for accidental destruction
        of the Client’s data by the Client or a third party accessing the Service without any fault of PDNA. PDNA
        reserves the right to charge the Client for any time PDNA must spend to research the causes of any incidents
        arising from acts other than (i) those committed by PDNA or (ii) that have resulted from the Services that PDNA
        provides.
      </p>
    </div>
    <p class="content-sub-title">7.3 Exclusions from the Service</p>
    <div class="content-text">
      <p>The following are excluded from the Service:</p>
      <p>
        • any services relating to installation and satisfactory functioning of the User Workstation and the Client's
        infrastructure (telecommunications, networks, security equipment) allowing the Client to access and use the
        Service;
      </p>
      <p>• resolutions of problems caused by error or incorrect operation by Users;</p>
      <p>• Professional Services.</p>
    </div>
    <p class="content-sub-title">7.4 Warranty</p>
    <div class="content-text">
      <p>PDNA warrants that each Service will conform in all material respects to its Documentation.</p>
      <p>
        PDNA does not warrant that the Service is exempt from any defect but shall use reasonable endeavors to remedy
        with all reasonable possible diligence any reproducible errors in the Service observed with reference to the
        Documentation.
      </p>
      <p>
        The warranty of conformity for the Service is expressly limited to its conformity to the Documentation and shall
        not be extended to a warranty of conformity to the specific needs or specific activity of a Client. PDNA does
        not warrant the capability of the Service to achieve the objectives or results which the Client has set and/or
        execute specific tasks which may have been the reasons for its decision to conclude this Agreement. The Client,
        or any representative appointed by the Client, is therefore responsible for ensuring that the Service is
        appropriate for its needs or the specific activity in the territory where the Service is used.
      </p>
      <p>
        To the extent legally permitted, any other warranties other than those set out in this Clause are expressly
        excluded.
      </p>
    </div>
    <p class="content-sub-title">7.5 Development of the Services</p>
    <div class="content-text">
      <p>
        PDNA will remain solely responsible for the development of the Service notably regarding technological
        developments. PDNA without constraint, may design, organize and size the Service, modify it and upgrade it if
        necessary, with partners and suppliers of its choice without the prior written consent of the Client, provided
        this does not reduce PDNA's undertakings in the MSA or/and SOW.
      </p>
    </div>
    <p class="content-sub-title">7.6 Amendment of the MSA or/and SOW</p>
    <div class="content-text">
      <p>
        PDNA may amend the MSA on one (1) month’s prior notice to Client by mail or email to its SaaS administrators or
        by posting information on the Portal and/or any other appropriate means. At the end of one (1) month notice
        period, if the Client has not terminated the Agreement as permitted by Clause “Termination of the Agreement by
        the Client pursuant to Clause Amendment of the MSA”, the Client will be deemed to have accepted the amended MSA.
        The latest version of the MSA shall be permanently accessible on the PDNA website https://www.productdna.com/MSA
        or any other website address notified by PDNA.
      </p>
      <p>
        Notwithstanding the foregoing, PDNA may amend the MSA to comply with any law or regulation. In such case, PDNA
        will make every effort to notify the Client of the amendment within a reasonable timeframe.
      </p>
    </div>
    <p class="content-sub-title">7.7 Upgrades</p>
    <div class="content-text">
      <p>
        The Client acknowledges that legislative or regulatory changes may, at any time, render the standard application
        functionalities accessible as part of the Service out of date. PDNA, as part of the Service, will update the
        standard application functionalities accessible as part of the Service so it satisfies the new legal provisions
        provided the updates do not require re-writing a significant proportion of the existing standard application
        functionalities.
      </p>
      <p>
        The Client also acknowledges that advances in technology, changes in legislation and changes in the requests of
        clientele may result in PDNA updating the Service, which may result in upgrading the Technical Prerequisites,
        for which PDNA disclaims all liability.
      </p>
    </div>
  } @loading {
    <p>Loading...</p>
  } @placeholder {
    <p>Placeholder...</p>
  } @error {
    <p>Someting went wrong ... Please try again</p>
  }
  <!-- 8. Client data -->
  @defer (when clientDataArray.includes(currentLazyLoadingTrigger()); on viewport) {
    <p class="content-title" id="idClientData">8. Client data</p>
    <p class="content-sub-title">8.1 Personal Data</p>
    <div class="content-text">
      <p>
        Provisions pertaining to protection of Personal Data are inserted in Annex “Protection of Personal Data Policy”.
      </p>
    </div>
    <p class="content-sub-title">8.2 Client Data Location</p>
    <div class="content-text">
      <p>
        Unless otherwise provided in the SOW, the Client Data is localized at one or more sites in Switzerland or the
        European Union.
      </p>
    </div>
    <p class="content-sub-title">8.3 Non-use of Client Data</p>
    <div class="content-text">
      <p>
        The Client remains the owner of the Client Data. Excluding the use described in Clause “Use of Statistical
        Information,” PDNA shall refrain from using, modifying, assigning or transferring to a third party, in whole or
        in part, whether or not for consideration, the Client Data which may have been communicated to PDNA by the
        Client for implementation of the Service, for purposes other than purposes of the Agreement. The Customer shall
        have sole responsibility for the legality, reliability, integrity, accuracy and quality of all Client Data.
      </p>
    </div>
    <p class="content-sub-title">8.4 Return of Client Data</p>
    <div class="content-text">
      <p>
        Upon expiry or on termination of the Agreement, access to the Service are closed on the last day of the Service.
        The Client shall, before that date, (i) retrieve accessible Client Data using the Service functionalities or
        (ii) request PDNA to provide a copy of its latest backup of the Client Data. Unless otherwise provided in the
        MSA, the return of the latest backup of the Client Data by PDNA will be made in a market standard format by
        download or for large files, by optical disc or other physical medium in the frame of a service billable at the
        current rate. Unless otherwise provided in the MSA, PDNA will begin deleting Client Data (both production Client
        Data and back-up copies) sixty (60) days following the effective date of expiration or termination of the
        Agreement, in accordance with PDNA’s policy as regards to backup retention.
      </p>
    </div>
    <p class="content-sub-title">8.5 Use of statistical information</p>
    <div class="content-text">
      <p>
        Notwithstanding Clause “Non-use of Client Data,” PDNA may use Client Data as and to the extent necessary to
        prepare its invoices, develop usage statistics and to provide support to the Client. PDNA may also compile and
        publish anonymized aggregate statistics, provided they do not identify the Client’s Confidential Information and
        do not include any Personal Data. PDNA shall retain all intellectual property rights over the results of such
        statistical analysis.
      </p>
    </div>
  } @loading {
    <p>Loading...</p>
  } @placeholder {
    <p>Placeholder...</p>
  } @error {
    <p>Someting went wrong ... Please try again</p>
  }
  <!-- 9. Security of the service -->
  @defer (when securityOfServiceArray.includes(currentLazyLoadingTrigger()); on viewport) {
    <p class="content-title" id="idSecurityofservice">9. Security of the service</p>
    <p class="content-sub-title">9.1 Management of security</p>
    <div class="content-text">
      <p>
        PDNA shall implement state of the art technical measures to ensure physical and logical security of the servers
        and networks under its responsibility and control. Each Party will promptly notify the other Party on becoming
        aware of any fact likely to constitute an attack on the physical or logical security of the environment of the
        other Party (e.g. attempted intrusion).
      </p>
    </div>
    <p class="content-sub-title">9.2. Security of Client Data</p>
    <div class="content-text">
      <p>
        PDNA shall implement state of the art measures to preserve the security of Client Data and prevent unauthorized
        modification, loss or disclosure, including by:
      </p>
      <p>
        • adopting all useful measures according to state of the art to avoid any fraudulent use or misappropriation of
        Client Data;
      </p>
      <p>
        • refraining from making copies of documents and media containing Client Data entrusted to it, except those
        strictly necessary for provision of the Service;
      </p>
      <p>
        • respecting confidentiality and refraining from disclosing Client Data to other persons, whether private or
        public, legal or natural, except to the extent disclosure is required by law or a competent administrative or
        judicial authority, or is necessary in connection with a judicial action pursuant to Clauses “Amicable Disputes
        Resolution” and “Applicable Law and Jurisdiction”.
      </p>
      <p>
        PDNA shall ensure logical separation between Client Data and the data of other clients. Provisions pertaining to
        security of Personal Data are inserted in Annex “Protection of Personal Data Policy”.
      </p>
    </div>
  } @loading {
    <p>Loading...</p>
  } @placeholder {
    <p>Placeholder...</p>
  } @error {
    <p>Someting went wrong ... Please try again</p>
  }
  <!-- 10. Combating fraud -->
  @defer (when combatingFraudArray.includes(currentLazyLoadingTrigger()); on viewport) {
    <p class="content-title" id="idCombatingfraud">10. Combating fraud</p>
    <div class="content-text">
      <p>
        The Client warrants that it will use the Service provided by PDNA in compliance with the applicable laws and
        regulations, notably concerning tax. Should PDNA be held jointly and severally liable by the tax administration
        for payment of backdated tax issued following irregular use by the Client of the Services, the Client shall
        indemnify PDNA against all liabilities, costs, expenses, damages and losses (including but not limited to any
        direct, indirect or consequential losses, loss of profit, loss of reputation and all interest, penalties and
        legal costs (calculated on a full indemnity basis) and all other reasonable professional costs and expenses)
        suffered or incurred by PDNA arising out of or in connection with the claim of the tax administration against
        PDNA.
      </p>
    </div>
  } @loading {
    <p>Loading...</p>
  } @placeholder {
    <p>Placeholder...</p>
  } @error {
    <p>Someting went wrong ... Please try again</p>
  }
  <!-- 11. Prices and invoices -->
  @defer (when pricesInvoicesArray.includes(currentLazyLoadingTrigger()); on viewport) {
    <p class="content-title" id="idPricesInvoices">11. Prices and invoices</p>
    <p class="content-sub-title">11.1 Prices</p>
    <div class="content-text">
      <p>
        The prices of Service Activation and the Service are indicated in the “SOW” and “Order Form”, or in the online
        order form. All prices listed are exclusive of VAT and all other charges, taxes, duties or fees payable by the
        Client under the regulations in force on the date that PDNA’s invoice is issued to the Client and which shall
        remain the Client's sole responsibility.
      </p>
    </div>
    <p class="content-sub-title">11.2. Invoices</p>
    <div class="content-text">
      <p>
        Service Activation will be invoiced upon completion. Unless otherwise stated in the SOW, the Service will be
        invoiced:
      </p>
      <p>• monthly, in advance, for subscription fees; or</p>
      <p>• monthly in arrears for fees based on usage.</p>
      <p>
        The first invoice for subscription Services shall be sent on the date that PDNA notifies the Client of the
        Service access codes (effective Service Activation), or on the first day of the following month. PDNA will
        invoice Services on the basis of calendar periods (months, quarters, years) and not anniversary periods. If
        applicable, the first and/or the last invoice will be prorated for partial calendar periods.
      </p>
      <p>
        Notwithstanding anything to the contrary in the Order Forms and the MSA, PDNA reserves the right to invoice the
        service annually in advance if the amount of services ordered by the Client is less than CHF 1’000.- per annum
        (exclusive of taxes). Moreover, if the Client has subscribed to services from PDNA under several agreements,
        PDNA also reserves the right to invoice the Clients for all services ordered under the Agreement, as well as for
        Services ordered under other agreements in a single invoice, which may be issued annually if the cumulative
        amount of such invoices is less than CHF 10’000.- per annum (exclusive of taxes). In cases where a Client has
        entered into multiple contracts with PDNA and two or more of the contracts have different invoice periods, PDNA
        reserves the right to invoice the Services under all of the contracts together with a uniform invoicing period,
        with the understanding that the uniform invoicing period must correspond with the invoicing period of the
        contract with the highest CHF payment amount. If the Client consumes more of a Service than the access threshold
        specified in “SOW” or in the online order form, PDNA may invoice the Client for the overage at the rates
        then-currently in effect, combined with an adjustment invoice covering all periods since the threshold was
        exceeded.
      </p>
    </div>
    <p class="content-sub-title">11.3 Price revision</p>
    <div class="content-text">
      <p>
        During the Initial Service Period, PDNA may increase the fees payable for the Services under this Agreement once
        per calendar year by an amount not to exceed six percent (6%) per year. During any relevant Extended Service
        Period, PDNA may increase the fees payable for the Services under this Agreement once per calendar year. If a
        Client refuses a price increase, such Client shall have the right to terminate the Agreement by sending notice
        to PDNA via registered mail within thirty (30) days following the date that the invoice containing the new price
        is issued. If the Client duly exercises such right, the Services will continue to be provided to the Client
        according to the previous price until the end of the sixth (6th) month following the date on which the invoice
        containing the price increase was issued before it ends.
      </p>
    </div>
  } @loading {
    <p>Loading...</p>
  } @placeholder {
    <p>Placeholder...</p>
  } @error {
    <p>Someting went wrong ... Please try again</p>
  }
  <!-- 12. Payment -->
  @defer (when paymentArray.includes(currentLazyLoadingTrigger()); on viewport) {
    <p class="content-title" id="idPayment">12. Payment</p>
    <p class="content-sub-title">12.1 Payment terms</p>
    <div class="content-text">
      <p><b>SERVICE ACTIVATION FEES</b></p>
      <p>
        Upon execution of the Agreement, the Client shall pay PDNA the total amount for Service Activation inclusive of
        taxes if the total amount is less than or equal to CHF 1’000.-. If the total fees for Service Activation exceeds
        CHF 1’000.-, the Client shall pay PDNA, upon execution of the Agreement, by wire transfer or direct debit, a
        minimum advance of thirty (30%) percent of the total amount of the Service Activation fees; said advance shall
        not be less than CHF 500.-. Unless already paid by the Client according to the provisions set forth hereinabove,
        the Client shall pay any invoices related to Service Activation due to PDNA (including for online orders)
        without discount within thirty (30) days of the invoice’s issuance date, by either direct debit or wire
        transfer. Notwithstanding the foregoing, with respect to online orders made by Clients placing their first order
        with PDNA, the Client shall pay any invoices related to Service Activation due PDNA upfront by credit card
        without discount.
      </p>
      <p><b>SERVICE FEES</b></p>
      <p>
        Any invoices due to PDNA in relation to any Services (including Services ordered online) shall be paid by the
        Client by direct debit without discount within thirty (30) days of the invoice’s issuance. The Client agrees to
        provide its bank details (IBAN and BIC). If the Client successively signs several contracts and chooses to pay
        PDNA by direct debit each time a payment is due, the Client shall thereby accept that each of the contracts will
        be regulated by a single direct debit mandate, of which the amount may vary, in light of the addition and
        termination of other contracts over time. The provisions of this Clause will apply to all Services except for
        Services governed by the MSA that have bespoke payment terms provisions.
      </p>
    </div>
    <p class="content-sub-title">12.2 Payment default</p>
    <div class="content-text">
      <p>
        Should the Client default on its obligation to pay the price agreed between the Parties, PDNA reserves the right
        fifteen (15) days after sending notice formally demanding payment via registered mail (and provided that the
        Client makes no payment or only a partial payment after receipt of such formal demand), to immediately suspend
        the Client’s right to access the Service and any other existing services until payment of the full amount due is
        remitted, and/or to terminate the Agreement as of right without any need for any further formal notice, without
        prejudice to any other recourse which PDNA may have against the Client to obtain compensation for the loss
        suffered by the default.
      </p>
      <p>
        Moreover, PDNA may invoice interest of 5% for late payments. The interest will be calculated per day of delay
        from the due date until payment of the overdue sum, including interest, whether before or after judgement. If
        the Client fails to make a payment due to PDNA under this Agreement, the Client shall also pay PDNA, as
        liquidated damages, CHF 2’000.- for the collection costs incurred by PDNA. The Parties confirm that these
        liquidated damages are reasonable and proportionate to protect PDNA’s interest in collecting the overdue amount.
      </p>
      <p>
        Non-payment by the Client of an invoice that is due shall entitle PDNA to require payment of all other
        outstanding invoices, even if they are not yet due.
      </p>
      <p>
        All fees following the bank’s rejection of the Client's payment shall remain the financial responsibility of the
        latter.
      </p>
    </div>
    <p class="content-sub-title">12.3 General</p>
    <div class="content-text">
      <p>
        PDNA reserves the right to decide how partial payments made by the Client are deducted from amounts due to PDNA.
        The Client shall refrain from any set-off with amounts which may be due to it from PDNA under the Agreement, or
        any other agreement which may exist between the Parties, without the prior written agreement of PDNA.
      </p>
    </div>
  } @loading {
    <p>Loading...</p>
  } @placeholder {
    <p>Placeholder...</p>
  } @error {
    <p>Someting went wrong ... Please try again</p>
  }
  <!-- 13. Cooperation -->
  @defer (when cooperationArray.includes(currentLazyLoadingTrigger()); on viewport) {
    <p class="content-title" id="idCooperation">13. Cooperation</p>
    <div class="content-text">
      <p>
        Satisfactory performance of the Agreement and proper provision of the Service requires active and continuing
        cooperation in good faith between the Parties. Consequently, each of the Parties undertakes to:
      </p>
      <p>• actively commit to fulfillment of its obligations under the Agreement;</p>
      <p>• refrain from any conduct which could affect and/or hinder fulfillment of the other Party's obligations;</p>
      <p>
        • provide by a mutually agreed-upon date, all information and documents necessary for implementation of the
        Agreement;
      </p>
      <p>
        • alert the other as quickly as possible in the case of difficulty and agree on deploying the best possible
        solution as promptly as possible.
      </p>
      <p>
        The Parties shall meet as frequently as necessary to ensure satisfactory conduct of the Agreement and more
        particularly to verify the correct and proper provision of the Service. Specifically, it is the Client's
        responsibility to send PDNA all information concerning the Client that is necessary for the proper provision of
        the Services provided and to inform PDNA of any difficulties of which it may become aware or which its knowledge
        of its field of activity enable it to foresee as the Services are implemented. In addition, the Client will
        retain competent, qualified and trained Users throughout the entire duration of implementation of the Agreement.
      </p>
    </div>
  } @loading {
    Loading...
  } @placeholder {
    <p>Placeholder...</p>
  } @error {
    <p>Someting went wrong ... Please try again</p>
  }
  <!-- 14. Indemnity - Liability - Insurance -->
  @defer (when indemnityArray.includes(currentLazyLoadingTrigger()); on viewport) {
    <p class="content-title" id="idIndemnity">14. Indemnity - Liability - Insurance</p>
    <p class="content-sub-title">14.1 Indemnity</p>
    <div class="content-text">
      <p>
        The Client shall defend, indemnify and hold harmless PDNA against claims, actions, proceedings, losses, damages,
        expenses and costs (including without limitation court costs and reasonable legal fees) arising out of or in
        connection with the Client's use of the Services and/or Documentation, provided that:
      </p>
      <p>• the Client is given prompt notice of any such claim;</p>
      <p>
        • PDNA provides reasonable co-operation to the Client in the defense and settlement of such claim, at the
        Client's expense; and
      </p>
      <p>• the Client is given sole authority to defend or settle the claim.</p>
    </div>
    <p class="content-sub-title">14.2. Liability</p>
    <div class="content-text">
      <p>Except as expressly and specifically provided in this Agreement:</p>
      <p>
        • the Client assumes sole responsibility for results obtained from the use of the Services and the Documentation
        by the Client, and for conclusions drawn from such use. PDNA shall have no liability for any damage caused by
        errors or omissions in any information, instructions or scripts provided to PDNA by the Client in connection
        with the Services, or any actions taken by PDNA at the Client's direction;
      </p>
      <p>
        • all warranties, representations, conditions and all other terms of any kind whatsoever implied by statute or
        common law are, to the fullest extent permitted by applicable law, excluded from this Agreement; and
      </p>
      <p>• the Services and the Documentation are provided to the Client on an "as is" basis.</p>
      <p>
        Nothing in this Agreement excludes the liability of PDNA • for death or personal injury caused by PDNA's
        negligence; or
      </p>
      <p>• for fraud or fraudulent misrepresentation.</p>
      <p>
        Subject to the aforementioned: • PDNA shall not be liable whether in tort (including for negligence or breach of
        statutory duty), contract, misrepresentation, restitution or otherwise for any loss of profits, loss of
        business, depletion of goodwill and/or similar losses or loss or corruption of data or information, or pure
        economic loss, or for any special, indirect or consequential loss, costs, damages, charges or expenses however
        arising under this Agreement; and
      </p>
      <p>
        • PDNA's total aggregate liability in contract, tort (including negligence or breach of statutory duty),
        misrepresentation, restitution or otherwise, arising in connection with the performance or contemplated
        performance of this Agreement shall be limited to the total fees paid by the Client for the Services during the
        12 months immediately preceding the date on which the claim arose.
      </p>
      <p>
        The Parties acknowledge that the prices in the Agreement reflects the distribution of risks under the Agreement
        and the economic balance sought by the Parties, and that the Agreement would not have been concluded under these
        conditions without the liability limitations defined herein. The Parties expressly agree that the foregoing
        liability limitations shall survive termination of the Agreement.
      </p>
    </div>
    <p class="content-sub-title">14.3 Insurance</p>
    <div class="content-text">
      <p>
        PDNA undertakes to maintain in force a professional indemnity insurance policy covering damages which may occur
        during performance of the Agreement.
      </p>
    </div>
  } @loading {
    Loading...
  } @placeholder {
    <p>Placeholder...</p>
  } @error {
    <p>Someting went wrong ... Please try again</p>
  }
  <!-- 15. Termination -->
  @defer (when terminationArray.includes(currentLazyLoadingTrigger()); on viewport) {
    <p class="content-title" id="idTermination">15. Termination</p>
    <p class="content-sub-title">15.1 Termination of the Agreement for material breach</p>
    <div class="content-text">
      <p>
        Each Party may terminate the Agreement as of right for cause if the other Party commits a material breach of its
        obligations under this Agreement without prejudice to any other rights and remedies available to PDNA.
        Termination of the Agreement shall take effect three (3) months after receipt by the other Party of a notice by
        registered mail detailing the breach, unless the Client proves it has remedied the breach before the end of the
        notice period.
      </p>
    </div>
    <p class="content-sub-title">15.2. Termination of the Agreement for breach of the availability rate</p>
    <div class="content-text">
      <p>
        The Client may terminate the Agreement for cause if the Service, for a period of three (3) consecutive months,
        fails to meet the availability rate as indicated in the SOW, without prejudice to any other rights and remedies
        available to the Client under the Agreement. Termination of the Agreement shall take effect three (3) months
        after receipt by PDNA of a registered mail detailing the breach, unless PDNA proves it has remedied the breach
        before the end of the notice period.
      </p>
    </div>
    <p class="content-sub-title">
      15.3 Termination of the Agreement by the Client pursuant to the Clause “Amendment of the MSA”
    </p>
    <div class="content-text">
      <p>
        The Client may terminate the Agreement by sending a notice via registered mail to PDNA if PDNA amends the MSA
        pursuant to Clause “Amendment of the MSA” and in a manner materially reducing PDNA’s obligations as regards to
        availability rates, management of backups and access conditions to Support. Neither Party shall be liable to the
        other because of such termination. The Client’s notice under this Clause will refer to this Clause and be sent
        by the Client to PDNA within one month of notification by PDNA of the amendment. Termination of the Agreement
        shall take effect six (6) months after the date of the Client’s notice in order to allow the Client time to
        arrange an alternative solution.
      </p>
    </div>
    <p class="content-sub-title">15.4 Effect of termination</p>
    <div class="content-text">
      <p>
        In the event of termination, the Client shall cease to use the Service from the day of termination of the
        Agreement. The provisions of Clause “Return of Client Data” shall then apply.
      </p>
    </div>
  } @loading {
    Loading...
  } @placeholder {
    <p>Placeholder...</p>
  } @error {
    <p>Someting went wrong ... Please try again</p>
  }
  <!-- 16. Force majeure -->
  @defer (when forceMajeureArray.includes(currentLazyLoadingTrigger()); on viewport) {
    <p class="content-title" id="idForce">16. Force majeure</p>
    <div class="content-text">
      <p>
        Neither Party shall be responsible for a breach of any of its contractual obligations if it was prevented from
        fulfilling said obligations by a Force Majeure Event. Force Majeure Event means any circumstance not within a
        party's reasonable control including, without limitation acts of God, flood, drought, earthquake or other
        natural disaster; epidemic or pandemic (being precise that COVID-19 is considered as a Force Majeure Event);
        terrorist attack, civil war, civil commotion or riots, war, threat of or preparation for war, armed conflict,
        imposition of sanctions, embargo, or breaking off of diplomatic relations; nuclear, chemical or biological
        contamination or sonic boom; any law or any action taken by a government or public authority, including without
        limitation imposing an export or import restriction, quota or prohibition, or failing to grant a necessary
        license or consent; collapse of buildings, fire, explosion or accident; any labour or trade dispute, strikes,
        industrial action or lockouts (other than in each case by the party seeking to rely on this clause, or companies
        in the same group as that party); non-performance by suppliers or subcontractors (other than by companies in the
        same group as the party seeking to rely on this clause); and interruption or failure of utility service,
        telecommunication services and telecommunication networks (other than by companies in the same group as the
        party seeking to rely on this clause).
      </p>
      <p>
        In these cases, the Party invoking the Force Majeure Event shall notify the other Party by registered mail as
        promptly as possible of the occurrence of such event and the necessary extension of the deadlines for
        fulfillment of its obligations.
      </p>
      <p>
        If the impediment is temporary, fulfillment of the obligation is suspended until the Party invoking the Force
        Majeure Event is no longer impeded by such Force Majeure Event. The Party invoking the Force Majeure Event shall
        keep the other Party informed and undertakes to make every effort to limit the duration of the suspension. If
        the suspension continues beyond three (3) months, either of the Parties may terminate the Agreement without
        liability by notice to the other Party by registered mail.
      </p>
      <p>
        If the impediment is permanent, the Agreement is terminated as of right and the Parties are discharged of their
        obligations. In the event of termination of the Agreement for a Force Majeure Event, PDNA undertakes to deploy
        the resources available to it to retrieve a maximum amount of Client Data.
      </p>
    </div>
  } @loading {
    Loading...
  } @placeholder {
    <p>Placeholder...</p>
  } @error {
    <p>Someting went wrong ... Please try again</p>
  }
  <!-- 17. Confidentiality -->
  @defer (when confidentialityArray.includes(currentLazyLoadingTrigger()); on viewport) {
    <p class="content-title" id="idConfidentiality">17. Confidentiality</p>
    <div class="content-text">
      <p>
        All documents, all data (including Client Data), all deliverables and/or all know-how whether covered or not by
        intellectual property laws, irrespective of their form or nature (commercial, industrial, technical, financial,
        etc.), disclosed by one Party (the “Disclosing Party”) to the other Party (the “Recipient”), or of which they
        have knowledge during implementation of the Agreement, including without limitation the terms of the Agreement,
        shall be considered confidential (hereinafter the “Confidential Information” ).
      </p>
      <p>
        Confidential Information does not include information which (i) was in the possession of the Recipient prior to
        its disclosure by the Disclosing Party provided said possession was not the direct or indirect result of
        unauthorized disclosure of the information by a third party, (ii) is public on the date of acceptance of the
        Agreement, or which is made public after said date, provided it is not made public due to breach by the
        Recipient of its confidentiality obligations under the Agreement, or (iii) is regularly and legally acquired
        independently of the Agreement by the Recipient.
      </p>
      <p>
        The Recipient will refrain from using the Disclosing Party’s Confidential Information other than for the purpose
        of implementing the Agreement, to protect the Disclosing Party’s Confidential Information and not to disclose it
        to third parties other than its employees, affiliated companies and subcontractors who have a need to know for
        the purposes of the Agreement, without the prior written consent of the Disclosing Party. The Parties will adopt
        all necessary measures to ensure that their employees, affiliated companies and subcontractors with access to
        Confidential Information are aware of the confidential nature of the Confidential Information communicated and
        that they comply with the obligations in this Clause.
      </p>
      <p>
        Notwithstanding the foregoing, the Recipient may disclose the Disclosing Party’s Confidential Information as
        required by the order or requirement of a court, administrative agency, or other governmental body; provided,
        however, that the Recipient, unless prohibited by law, will provide the Disclosing Party sufficient notice to
        allow the Disclosing Party to seek a protective order or similar relief. The Recipient will limit disclosure
        under this paragraph to the portion of the Disclosing Party’s Confidential Information it reasonably believes it
        is required to disclose. Before a Party discloses any Confidential Information pursuant to this Clause it shall,
        to the extent permitted by law, use all reasonable endeavors to give the other Party as much notice of this
        disclosure as possible. If a Party is unable to inform the other Party before Confidential Information is
        disclosed pursuant to this Clause it shall, to the extent permitted by law, inform the other Party of the full
        circumstances of the disclosure and the information that has been disclosed as soon as reasonably practicable
        after such disclosure has been made. Any violation of the undertakings in this Clause by the Recipient will
        constitute a material breach of its obligations for which the Recipient shall be fully liable and shall
        indemnify the Disclosing Party for the loss suffered.
      </p>
      <p>
        The Parties undertake to comply with the obligations under this Clause throughout the entire term of the
        Agreement and for a period of five (5) years after termination or expiration of the Agreement. In this regard,
        once the Agreement has expired or is terminated, each Party shall return to the other Party all documents
        containing Confidential Information, or warrant to the other Party the destruction of all Confidential
        Information in its possession. Under no circumstances may a copy of documents containing Confidential
        Information be retained by a Party, except by exceptional and written agreement of the other Party.
      </p>
    </div>
  } @loading {
    <p>Loading...</p>
  } @placeholder {
    <p>Placeholder...</p>
  } @error {
    <p>Someting went wrong ... Please try again</p>
  }
  <!-- 18. Subcontracting -->
  @defer (when subcontractingArray.includes(currentLazyLoadingTrigger()); on viewport) {
    <p class="content-title" id="idSubcontracting">18. Subcontracting</p>
    <div class="content-text">
      <p>
        The Client accepts that PDNA may freely, without prior formality, subcontract all or some of its obligations
        under the Agreement. In the event of subcontracting, PDNA shall remain solely responsible for compliance by
        subcontractors with the obligations imposed by the Agreement. The Parties agree that provisions pertaining to
        sub-processors are inserted in Annex “Protection of Personal Data Policy”.
      </p>
    </div>
  } @loading {
    <p>Loading...</p>
  } @placeholder {
    <p>Placeholder...</p>
  } @error {
    <p>Someting went wrong ... Please try again</p>
  }
  <!-- 19. Assignment -->
  @defer (when assignmentArray.includes(currentLazyLoadingTrigger()); on viewport) {
    <p class="content-title" id="idAssignment">19. Assignment</p>
    <div class="content-text">
      <p>
        The Agreement, including the rights and obligations provided thereto, may be assigned by the Client, whether in
        whole or in part, for consideration or otherwise, subject to prior written consent of PDNA.
      </p>
      <p>
        PDNA may assign, mortgage, charge, subcontract, delegate, declare a trust over, freely transfer, or deal in any
        other manner the Agreement by giving notice, including the rights and obligations provided thereto, without
        formalities. Upon written notice of the assignment to the Client, PDNA shall be discharged of any obligations
        under the Agreement and shall not be held jointly and severally liable for implementation of the Agreement by
        the assignee.
      </p>
    </div>
  } @loading {
    <p>Loading...</p>
  } @placeholder {
    <p>Placeholder...</p>
  } @error {
    <p>Someting went wrong ... Please try again</p>
  }
  <!-- 20. Regulations -->
  @defer (when regulationsArray.includes(currentLazyLoadingTrigger()); on viewport) {
    <p class="content-title" id="idRegulations">20. Regulations</p>
    <p class="content-sub-title">20.1 Social regulations</p>
    <div class="content-text">
      <p>
        PDNA undertakes to apply all legal and regulatory provisions incumbent on it concerning labor law and social
        security legislation, and in particular to provide the Client, upon request, with all certificates the Client is
        entitled to request. PDNA’s employees shall remain PDNA’s employees at all times. PDNA’s employees fulfill their
        functions, under the management, supervision and responsibility of PDNA, which shall be responsible throughout
        the term of the Agreement, for their administrative, accounting and social management.
      </p>
    </div>
    <p class="content-sub-title">20.2 Compliance</p>
    <div class="content-text">
      <p>
        The Client represents that it shall not, and shall not permit any of its subsidiaries or affiliates or any of
        its or their respective directors, officers, managers, employees, independent contractors, representatives or
        agents, to engage in any activity, practice or conduct which would constitute an offence under ethics,
        anti-bribery and anti-corruption applicable laws and regulations including without limitation the OECD
        Convention, the US Foreign Corrupt Practices Act (FCPA) and the Swiss anti-money laundering act.
      </p>
      <p>
        The Client notably represents that it shall not use any corporate funds for unlawful contributions, gifts,
        entertainment or other unlawful expenses relating to political activity, made, offered or authorized any
        unlawful payment to foreign or domestic government officials or employees, whether directly or indirectly, or
        made, offered or authorized any unlawful bribe, rebate, payoff, influence payment, kickback or other similar
        unlawful payment, whether directly or indirectly. Should the Client become aware of a breach of this Clause, the
        Client shall notify PDNA within twenty four (24) hours on becoming aware of such breach.
      </p>
    </div>
  } @loading {
    <p>Loading...</p>
  } @placeholder {
    <p>Placeholder...</p>
  } @error {
    <p>Someting went wrong ... Please try again</p>
  }
  <!-- 21. Miscellaneous Provisions -->
  @defer (when miscellaneousArray.includes(currentLazyLoadingTrigger()); on viewport) {
    <p class="content-title" id="idMiscellaneous">21. Miscellaneous Provisions</p>
    <p class="content-sub-title">21.1 Independence of the Parties</p>
    <div class="content-text">
      <p>
        The Parties are and will act as independent contractors. Nothing in the Agreement may be construed or implied to
        create an agency, association, partnership or joint venture. At no time will either Party make any commitments
        or incur any charge or expense for or in the name of the other.
      </p>
    </div>
    <p class="content-sub-title">21.2 Entire Agreement</p>
    <div class="content-text">
      <p>
        The Parties acknowledge that the Agreement and all other terms and conditions incorporated by reference herein,
        contains all the terms agreed between the Parties concerning the subject matter of the Agreement, and replace
        all prior, whether verbal or written, undertakings concluded between the Parties concerning the subject matter
        hereof. The Agreement prevails over any other document, including any general terms and conditions of purchase
        of the Client.
      </p>
    </div>
    <p class="content-sub-title">21.3 Amendments</p>
    <div class="content-text">
      <p>
        Except for the Terms of Service which may be amended by PDNA pursuant to the procedure defined in the Clause
        “Amendments of Terms of Service” and the Technical Prerequisites which may be upgraded, the Agreement shall not
        be modified or amended except by written amendments signed by duly authorized representatives of the Parties.
      </p>
      <p>
        The Parties have assessed the risks associated with the performance of the Agreement, which they accept, and
        waive any re-negotiation of the terms whatever the circumstances.
      </p>
    </div>
    <p class="content-sub-title">21.4 Headings</p>
    <div class="content-text">
      <p>
        The headings of the Agreement are inserted solely to facilitate reading of the contractual documents. Should the
        heading of a paragraph or a Clause in a contractual document distort understanding of the text, the text of the
        paragraph or Clause alone shall be considered and not its heading.
      </p>
    </div>
    <p class="content-sub-title">21.5 Severability</p>
    <div class="content-text">
      <p>
        If any provision of the Agreement or its application to any person or circumstances is to any extent invalid or
        unenforceable, the remainder of the Agreement, or the application thereof to any person or circumstances other
        than those as to which it is invalid or unenforceable, will not be affected, and each provision of the Agreement
        will be valid and enforced to the fullest extent of the law.
      </p>
    </div>
    <p class="content-sub-title">21.6 Non-waiver</p>
    <div class="content-text">
      <p>
        Failure by either Party to enforce any right pursuant to the Agreement shall not be construed as the waiver of
        such right and shall not affect that Party's right to later enforce it.
      </p>
    </div>
    <p class="content-sub-title">21.7 Know-how</p>
    <div class="content-text">
      <p>
        Each Party shall remain the owner of the know-how it possesses independently of the Agreement or the know- how
        it may acquire during performance of the Agreement, and shall remain free to use it. PDNA is free to perform
        similar services on behalf of other clients. Neither of the Parties may claim any right whatsoever over the
        know-how of the other Party.
      </p>
    </div>
    <p class="content-sub-title">21.8 Commercial Reference</p>
    <div class="content-text">
      <p>
        Subject to prior written authorization of the Parties, the Client authorizes PDNA to cite the Client name and
        use and/or reproduce the Client logo and/or trademarks as a commercial reference in commercial documents and
        press advertisements in any form whatsoever, on any support medium, and on documents used and/or prepared by
        PDNA in the framework of the Agreement.
      </p>
    </div>
  } @loading {
    <p>Loading...</p>
  } @placeholder {
    <p>Placeholder...</p>
  } @error {
    <p>Someting went wrong ... Please try again</p>
  }
  <!-- 22. Applicable law and jurisdiction -->
  @defer (when applicableArray.includes(currentLazyLoadingTrigger()); on viewport) {
    <p class="content-title" id="idApplicable">22. Applicable law and jurisdiction</p>
    <div class="content-text">
      <p>
        This agreement and any dispute or claim (including non-contractual disputes or claims) arising out of or in
        connection with it or its subject matter or formation shall be governed by and construed in accordance with the
        law of Switzerland.
      </p>
      <p>
        Failing reaching an amicable resolution, each party irrevocably agrees that the courts of the state of Vaud
        shall have exclusive jurisdiction to settle any dispute or claim (including non-contractual disputes or claims)
        arising out of or in connection with this agreement or its subject matter or formation.
      </p>
    </div>
  } @loading {
    <p>Loading...</p>
  } @placeholder {
    <p>Placeholder...</p>
  } @error {
    <p>Someting went wrong ... Please try again</p>
  }
</div>
