import { ISupplier } from '@app/core/interface/suppliers.interface';

import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductChainGroup } from '../product-chain.group';
import { Observable, map, startWith } from 'rxjs';

export interface IDialogData {
  supplierList: ISupplier[];
}

@Component({
  selector: 'app-step-dialog',
  templateUrl: './step-dialog.component.html',
  styleUrls: ['./step-dialog.component.scss'],
  providers: [ProductChainGroup],
})
export class StepDialogComponent {
  form: UntypedFormGroup;
  supplierList?: ISupplier[];
  filteredOptions$: Observable<ISupplier[]> = new Observable<ISupplier[]>();

  constructor(
    private _group: ProductChainGroup,
    public dialogRef: MatDialogRef<StepDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData
  ) {
    this.form = this._group.addStep;
    this.supplierList = data.supplierList?.filter(supplier => supplier.is_active);

    this.filteredOptions$ =
      this.form.get('supplier')?.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      ) || new Observable<ISupplier[]>();
  }

  getSupplierName(supplier: ISupplier): string {
    return supplier ? supplier.name : '';
  }

  private _filter(value: string | ISupplier): ISupplier[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
    return this.supplierList?.filter(supplier => supplier.name.toLowerCase().includes(filterValue)) || [];
  }

  addStep(): void {
    const changedFormValues = GetDirtyValues(this.form);
    if (changedFormValues['supplier']) {
      changedFormValues['supplier'] = this.form.controls['supplier'].value.uuid;
    }
    if (this.form.valid) {
      this.dialogRef.close({ ...changedFormValues });
    }
  }

  displayFn(subject: ISupplier | null): string {
    return subject?.name ?? '';
  }
}
