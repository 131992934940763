import { ComponentFactoryResolver, Injectable, ViewContainerRef } from '@angular/core';
import { INode, INodeConnection } from '../interface/productchain.interface';

import { BehaviorSubject } from 'rxjs';
import { ChainNodeComponent } from '@app/shared/components/chain-node/chain-node.component';
import { jsPlumb } from 'jsplumb';

@Injectable()
export class NodeService {
  private rootViewContainer!: ViewContainerRef;
  public selectedStepData: BehaviorSubject<INode | null> = new BehaviorSubject<INode | null>(null);
  public selectedStepData$ = this.selectedStepData.asObservable();
  public completeStepData: BehaviorSubject<INode | null> = new BehaviorSubject<INode | null>(null);
  public completeStepData$ = this.completeStepData.asObservable();
  public selectedPhase: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public selectedPhase$ = this.selectedPhase.asObservable();
  public connectionDeleted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public connectionDeleted$ = this.connectionDeleted.asObservable();
  public stepDeleted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public stepDeleted$ = this.stepDeleted.asObservable();
  public stepMoved: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public stepMoved$ = this.stepMoved.asObservable();
  public updateListView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public updateListView$ = this.updateListView.asObservable();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jsPlumbInstance: any = jsPlumb.getInstance();

  constructor(private factoryResolver: ComponentFactoryResolver) {}

  setRootViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }

  addDynamicNode(node: INode, direction: string) {
    const factory = this.factoryResolver.resolveComponentFactory(ChainNodeComponent);
    const component = factory.create(this.rootViewContainer.parentInjector);
    component.instance.node = node;
    component.instance.jsPlumbInstance = this.jsPlumbInstance;
    component.instance.direction = direction;
    this.rootViewContainer.insert(component.hostView);
  }

  changeDirection(direction: string) {
    const factory = this.factoryResolver.resolveComponentFactory(ChainNodeComponent);
    const component = factory.create(this.rootViewContainer.parentInjector);
    component.instance.jsPlumbInstance = this.jsPlumbInstance;
    component.instance.direction = direction;
    this.rootViewContainer.insert(component.hostView);
  }

  addConnection(connection: INodeConnection) {
    this.jsPlumbInstance.connect({ uuids: connection.uuids });

    // this.jsPlumbInstance.getAllConnections().forEach((conn: any) => {
    //   conn.bind('click', () => {
    //     this.jsPlumbInstance.deleteConnection(conn);
    //     this.connectionDeleted.next(true);
    //   })
    // });
  }

  zoom(zoom: number) {
    const transformOrigin = [0, 0.5];
    const container = document.querySelector('#canvasContainer');
    if (container) {
      this.jsPlumbInstance.setContainer(container);
    }
    const el = this.jsPlumbInstance.getContainer();
    const p = ['webkit', 'moz', 'ms', 'o'],
      s = 'scale(' + zoom + ')',
      oString = transformOrigin[0] * 100 + '% ' + transformOrigin[1] * 100 + '%';

    for (let i = 0; i < p.length; i++) {
      el.style[p[i] + 'Transform'] = s;
      el.style[p[i] + 'TransformOrigin'] = oString;
    }

    el.style['transform'] = s;
    el.style['transformOrigin'] = oString;

    this.jsPlumbInstance.setZoom(zoom);
  }

  clear() {
    this.jsPlumbInstance.deleteEveryConnection();
    this.jsPlumbInstance.deleteEveryEndpoint();
    this.rootViewContainer.clear();
  }
}
