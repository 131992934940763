<div class="wrapper">
  <div class="header d-flex align-items-center justify-content-between">
    <div class="breadcrumb d-flex align-items-center">
      <app-custom-icon
        [icon_category]="'arrows'"
        [icon_name]="'arrow-circle-left'"
        class="back-arrow cursor-pointer"
        (click)="goBack()"
      ></app-custom-icon>
      <h6 class="cursor-pointer" (click)="goBack()">{{ 'Publication tool' | translate }}</h6>
      <app-custom-icon
        [icon_category]="'arrows'"
        [icon_name]="'arrow-right-short'"
        class="arrow-short"
      ></app-custom-icon>
      <h5>{{ 'Preview product view' | translate }}</h5>
    </div>
    <app-custom-icon
      [icon_category]="'essential'"
      [icon_name]="'close-circle'"
      class="close-header cursor-pointer"
      (click)="goBack()"
    ></app-custom-icon>
  </div>
  <div class="row row-container">
    <div class="col-6 info-display">
      <h5>{{ 'Product display' | translate }}</h5>
      <div class="box d-flex justify-content-between flex-column align-items-end" *ngIf="product && selectedBrand">
        <div class="carousel-wrap">
          <div class="left-section">
            <div class="carousel justify-content-center">
              <img [src]="images[currentIndex] ? images[currentIndex] : emptyImage" alt="slide" />

              <app-custom-icon
                class="left-arrow"
                [icon_category]="'arrows'"
                [icon_name]="'arrow-circle-left'"
              ></app-custom-icon>
              <app-custom-icon
                class="right-arrow"
                [icon_category]="'arrows'"
                [icon_name]="'arrow-circle-right'"
              ></app-custom-icon>
            </div>
          </div>
          <div class="right-section">
            <div class="wraper d-flex flex-column">
              <div class="logo-wraper">
                <img class="logo" src="{{ selectedBrand.logo ? selectedBrand.logo : emptyImage }}" />
                <h2>{{ selectedBrand.name }}</h2>
              </div>

              <h1 class="product-name">{{ product.name }}</h1>
            </div>
          </div>
        </div>

        <div class="certificates" *ngIf="activeCertificates?.length">
          <img
            *ngFor="let certificate of activeCertificates"
            src="{{ certificate.certificate?.image ? certificate.certificate.image : emptyImage }}"
          />
          <div class="tooltip">
            <div *ngIf="certicate_note" (click)="certicate_note = false" class="node-certificate-logos">
              <p class="m-0">
                {{
                  'Note that the certificate logos are displayed automatically for any certificate that is currently up do date for a supply chain, or up do date at the time the batch was produced for a batch chain.'
                    | translate
                }}
              </p>
              <app-custom-icon
                [icon_category]="'essential'"
                [icon_name]="'close-circle'"
                class="close-header cursor-pointer"
              ></app-custom-icon>
            </div>
          </div>
        </div>

        <div class="respect-code">
          <div class="bottom-wrap">
            <h3>{{ 'Respect Code' | translate }}</h3>
            <div class="d-flex align-items-center mt-1 reference-wrap">
              <img src="../../../../assets/images/symbol-blue.svg" />
              <h5>{{ publicationData.publication_reference ?? '' }}</h5>
            </div>
          </div>
          <app-rounded-button [disabled]="true" color="primary" [button_text]="'Product info'" [size]="'sm'">
          </app-rounded-button>
        </div>
      </div>
    </div>

    <div class="col-6 learn-more">
      <h5>{{ 'Product info display' | translate }}</h5>
      <div class="box" *ngIf="product && selectedBrand" [ngClass]="{ hidden: hideLearnMore }">
        <app-custom-icon
          class="close-icon"
          [icon_category]="'essential'"
          [icon_name]="'close-circle'"
        ></app-custom-icon>
        <div class="row">
          <div class="col-11 prod-info-wrap d-flex flex-column">
            <div>
              <h6>{{ product.name }}</h6>
              <p class="product-description">
                <app-view-rich-text [content]="product.description"></app-view-rich-text>
              </p>
            </div>

            <div *ngIf="product.reference">
              <h4 class="reference">{{ 'Product reference number' | translate }}</h4>
              <p>{{ product.reference }}</p>
            </div>
            <div *ngIf="product.composition">
              <h4 class="reference">{{ 'Composition' | translate }}</h4>
              <p class="product-description">{{ product.composition }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
