<div class="publication-setup-wrapper d-flex flex-column">
  <div class="header d-flex justify-content-between align-items-center">
    <h6 class="header-text">
      {{ 'Configure what you want to display for the Publication' | translate }}
    </h6>
    <div class="custom-button-wrapper">
      <app-rounded-button
        color="primary"
        [icon_category]="'essential'"
        [icon_name]="'publication'"
        [icon_right]="true"
        [button_text]="'Save publication setup'"
        [size]="'mb'"
        matTooltip="{{ 'All publications will be updated' | translate }}"
        matTooltipClass="custom-tooltip-center centering"
        matTooltipPosition="above"
        (click)="onSubmit()"
      >
      </app-rounded-button>
    </div>
  </div>
  <div class="body-wrapper d-flex">
    <!-- Toggle fields -->
    <div class="form-wrapper flex-grow-1 p-0">
      <div class="select-label">{{ ' Setup which fields will be visible for this supplier' | translate }}</div>
      <!-- FORM -->
      <form [formGroup]="form">
        <div class="toggle-wrapper d-flex align-items-center">
          <mat-checkbox formControlName="is_hidden" class="checkbox-primary">
            {{ 'Confidential Supplier' | translate }}
          </mat-checkbox>
        </div>

        <div *ngIf="form.get('is_hidden')?.value" class="row">
          <div class="icon-w-sp">
            <app-custom-icon [icon_category]="'security'" [icon_name]="'hidden'"></app-custom-icon>
          </div>
          <div class="col-11 hidden-s-t">
            {{
              'This supplier is marked as confidential. No supplier details will be displayed in Respect Code.'
                | translate
            }}
          </div>
        </div>

        <div class="toggle-wrapper d-flex align-items-center">
          <div class="label" [class.disabled]="form.get('is_name_private')?.disabled">
            {{ 'Supplier name' | translate }}
          </div>
          <mat-slide-toggle
            [checked]="!form.get('is_name_private')?.value"
            [disabled]="form.get('is_name_private')?.disabled"
            (change)="onToggleChange('is_name_private', $event)"
          ></mat-slide-toggle>
        </div>
        <div class="toggle-wrapper d-flex align-items-center">
          <div class="label" [class.disabled]="form.get('is_description_private')?.disabled">
            {{ 'Supplier description' | translate }}
          </div>
          <mat-slide-toggle
            [checked]="!form.get('is_description_private')?.value"
            [disabled]="form.get('is_description_private')?.disabled"
            (change)="onToggleChange('is_description_private', $event)"
          ></mat-slide-toggle>
        </div>
        <div class="horizontal-line"></div>
        <div class="toggle-wrapper d-flex align-items-center">
          <mat-checkbox
            class="checkbox-primary"
            [class.disabled]="form.get('is_location_private')?.disabled"
            formControlName="is_location_private"
          >
            {{ 'Hide Location' | translate }}
          </mat-checkbox>
        </div>

        <div *ngIf="form.get('is_location_private')?.value && !form.get('is_hidden')?.value" class="row lp">
          <div class="icon-w-sp">
            <app-custom-icon [icon_category]="'location'" [icon_name]="'location-cross'"></app-custom-icon>
          </div>
          <div class="col-11 hidden-s-t">
            {{ 'There won’t be any location displayed' | translate }}
          </div>
        </div>

        <div class="select-label" [class.fade-t]="form.get('is_location_private')?.disabled">
          {{ 'Geopositioning Display:' | translate }}
        </div>
        <div class="select-description" [class.fade-t]="form.get('is_location_private')?.disabled">
          {{ 'Please select below how your location will be displayed on a map.' | translate }}
        </div>
        <div class="row">
          <mat-radio-group formControlName="selectInput">
            <mat-radio-button *ngFor="let option of selectOptions" [value]="option" class="opt">
              {{ option.viewValue }} - {{ option.expVal }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </form>
    </div>

    <div class="card-wrapper flex-grow-1">
      <mat-card>
        <div class="inner-card-wrapper">
          <div class="image">
            <img src="{{ '../../../../assets/images/brand-avatar.png' }}" alt="" />
          </div>
          <div class="content">
            <h6>{{ 'Check useful data tab' | translate }}</h6>
            <p class="p2-normal">
              {{ 'to manage the media that will be displayed in publication.' | translate }}
            </p>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
