import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '@app/core/service/navigation.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrl: './terms-and-conditions.component.scss',
})
export class TermsAndConditionsComponent {
  activeTab = '';
  previousUrl = '';

  constructor(
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.activeTab = params.get('tab') || 'default';
    });

    this.previousUrl = this.navigationService.previousUrl;
  }

  getTabIndex(tab: string): number {
    switch (tab) {
      case 'Service agreement':
        return 0;
      case 'Privacy policy':
        return 1;
      case 'Acceptable use policy':
        return 2;
      case 'Data protection policy':
        return 3;
      case 'Cookies policy':
        return 4;
      default:
        return 0;
    }
  }

  navigateBack(): void {
    this.navigationService.isTermsAndConditions.set(false);
    this.router.navigateByUrl(this.previousUrl);
  }
}
