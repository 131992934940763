import { Component, Input, SecurityContext, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-view-rich-text',
  template: `
    <div [ngClass]="inheritStyles ? 'read-only-rte-inh' : 'read-only-rte'" [innerHTML]="sanitizedContent"></div>
  `,
  styleUrls: ['./view-rich-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewRichTextComponent {
  @Input() content = '';
  @Input() inheritStyles = false;

  sanitizedContent: SafeHtml = '';

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(): void {
    this.sanitizeContent();
  }

  private sanitizeContent(): void {
    this.sanitizedContent = this.sanitizer.sanitize(SecurityContext.HTML, this.content) || '';
  }
}
