<div *ngIf="!isChainSetup && !isPublish" class="row header">
  <div class="col-lg-9 col-md-8 col-12 d-flex align-items-center">
    <div *ngIf="showWelcomeMessage" class="welcome-user">
      <p class="welcome">{{ 'supplier-menu.Welcome' | translate }} {{ user?.first_name }}</p>
      <mat-divider [vertical]="true"></mat-divider>
      <p class="your-dashboard">{{ 'supplier-menu.Your Dashboard' | translate }}</p>
    </div>
    <app-breadcrumb-header *ngIf="!showWelcomeMessage"></app-breadcrumb-header>
  </div>
  <div class="col-lg-3 col-md-4 col-12 d-flex justify-end align-items-center">
    <!-- <i class="material-icons-outlined notifications">notifications</i> -->
    <div *ngIf="isSupplierUser" class="language-holder d-flex" [matMenuTriggerFor]="languageMenu">
      <p class="m-0">{{ currentLang === 'en' ? 'EN' : ('FR' | translate) }}</p>
      <app-custom-icon id="arrow-down" [icon_category]="'arrows'" [icon_name]="'arrow-down-2'"></app-custom-icon>
    </div>
    <mat-menu class="mat-menu-primary language-mat-menu" #languageMenu="matMenu" xPosition="before">
      <button class="custom-item" mat-menu-item disabled>
        <p class="name select-lang m-0">{{ 'supplier-menu.Select your preferred language' | translate }}</p>
      </button>
      <button
        (click)="setLangueage('en')"
        class="custom-item d-flex justify-content-between align-items-center"
        mat-menu-item
      >
        <p class="name m-0" [ngClass]="currentLang === 'en' ? 'currentLang' : ''">
          {{ 'English' | translate }}
        </p>
        <app-custom-icon
          *ngIf="currentLang === 'en'"
          [icon_category]="'essential'"
          [icon_name]="'tick-circle'"
        ></app-custom-icon>
      </button>
      <button
        (click)="setLangueage('fr')"
        class="custom-item d-flex justify-content-between align-items-center"
        mat-menu-item
      >
        <p class="name m-0" [ngClass]="currentLang === 'fr' ? 'currentLang' : ''">
          {{ 'Français' | translate }}
        </p>
        <app-custom-icon
          *ngIf="currentLang === 'fr'"
          [icon_category]="'essential'"
          [icon_name]="'tick-circle'"
        ></app-custom-icon>
      </button>
    </mat-menu>
    <div class="user">
      <div class="user align-items-center">
        <div
          class="avatar"
          [style.background-image]="
            user?.avatar
              ? 'url(' + user?.avatar + ')'
              : 'url(../../../../assets/images/upload-your-avatar-lightblue.png)'
          "
          [matMenuTriggerFor]="admin"
        ></div>
        <div class="user-info">
          <p class="user-name" [matMenuTriggerFor]="admin">{{ user?.username }}</p>
          <p class="user-role">{{ user?.function }}</p>
        </div>
      </div>
    </div>
  </div>

  <mat-menu #admin="matMenu" class="user-mat-menu">
    <!-- <h4 mat-menu-item disabled>{{ 'Account' | translate }}</h4> -->

    <div class="user align-items-center">
      <div
        class="avatar"
        [style.background-image]="
          user?.avatar ? 'url(' + user?.avatar + ')' : 'url(../../../../assets/images/upload-your-avatar-lightblue.png)'
        "
      ></div>
      <div class="user-info">
        <p class="name">{{ user?.username }}</p>
        <p class="email">{{ user?.email }}</p>
      </div>
    </div>

    <div class="menu-items">
      <button mat-menu-item (click)="manageProfile(user)">
        <app-custom-icon [icon_category]="'user'" [icon_name]="'user-edit'"></app-custom-icon>
        {{ 'supplier-menu.Manage Profile' | translate }}
      </button>
      <button mat-menu-item [routerLink]="'data-privacy'">
        <app-custom-icon [icon_category]="'security'" [icon_name]="'shield-security'"></app-custom-icon>
        {{ 'supplier-menu.Data Privacy' | translate }}
      </button>
      <div class="h-line" style="margin: 16px">
        <div class="horizontal-line"></div>
      </div>

      <button
        class="switch"
        mat-menu-item
        [routerLink]="[user?.is_supplier ? 'select-brand' : '/settings/brand-management/switch-brand']"
        *ngIf="numberOfBrands > 1"
      >
        <app-custom-icon [icon_category]="'grid'" [icon_name]="'convertshape'"></app-custom-icon>
        {{ 'supplier-menu.Switch Brand' | translate }}
      </button>

      <button mat-menu-item class="sidenav-logout" (click)="logout()">
        <app-custom-icon [icon_category]="'arrows'" [icon_name]="'logout-2'"></app-custom-icon>
        {{ 'supplier-menu.Log out' | translate }}
      </button>
    </div>
  </mat-menu>
</div>
