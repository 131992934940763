import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductsFacade } from '@app/core/facade/products.facade';
import {
  IProductChain,
  IProductChainRequest,
  IProductChainUpdate,
  IProductChainUpdateResponse,
} from '@app/core/interface/products.interface';
import { ProductDashboardGroup } from '../../product-dashboard.group';
import { Router } from '@angular/router';
import { IBrandFeaturesResponse } from '@app/core/interface/brands.interface';
import { NotificationType, PROJECT_MANAGEMENT } from '@app/core/constants';
import { BrandsFacade } from '@app/core/facade/brands.facade';
import { ChainStatus } from '@app/core/interface/productchain.interface';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-product-supply-chain-form',
  templateUrl: './product-supply-chain-form.component.html',
  styleUrls: ['./product-supply-chain-form.component.scss'],
  providers: [ProductsFacade, ProductDashboardGroup],
})
export class ProductSupplyChainFormComponent {
  productChain!: IProductChain;
  product!: string;
  productChainTagsString!: string;
  form!: UntypedFormGroup;
  show_project!: boolean;
  isChainComplete = false;

  constructor(
    public _matDialogRef: MatDialogRef<ProductSupplyChainFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IProductChainUpdate,
    private _facade: ProductsFacade,
    private _group: ProductDashboardGroup,
    private _snackbarService: SnackbarService,
    private _router: Router,
    private _brandsFacade: BrandsFacade
  ) {
    this.productChain = data.productChain;
    this.product = data.product;
    this.isChainComplete = this.productChain.status === ChainStatus.complete;
    this.form = this._group.editProductChain;
    this.patchValues();
    this.getBrandFeatures();
  }

  patchValues() {
    this.form.patchValue({
      name: this.productChain.name,
      tags: this.productChain.tags,
      status: this.productChain.status,
      external_reference: this.productChain.external_reference,
    });
  }

  updateData() {
    const splittedTags = this.form.get('tags')?.value.length ? this.form.get('tags')?.value.toString().split(',') : [];

    const objectToApi: IProductChainRequest = {
      product: this.product,
      name: this.form.get('name')?.value,
      status: this.form.get('status')?.value,
      tags: splittedTags,
      external_reference: this.form.get('external_reference')?.value,
    };

    this._facade.updateProductChain$(objectToApi, this.productChain.uuid).subscribe({
      next: this._success.bind(this),
      error: this._error.bind(this),
    });
  }

  private _success(data: IProductChainUpdateResponse): void {
    this._matDialogRef.close(data);
    this._router.navigate([`/chain-setup/chain-management/${this.data.productChain.uuid}`]);
    this._snackbarService.openTypeSnackbar(`Chain data was updated successfully`, NotificationType.success);
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  getBrandFeatures(): void {
    this._brandsFacade.getBrandFeatures$().subscribe({
      next: this._successBrandFeatures.bind(this),
      error: this._error.bind(this),
    });
  }

  private _successBrandFeatures(data: IBrandFeaturesResponse) {
    if (data.results) {
      const projectManagement = data.results.find(project => project.name === PROJECT_MANAGEMENT);
      if (projectManagement) {
        this.show_project = projectManagement.is_enabled;
      }
    }
  }
}
