import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Injectable } from '@angular/core';

@Injectable()
export class ProductChainGroup {
  addChain: UntypedFormGroup;
  addStep: UntypedFormGroup;
  updateStep: UntypedFormGroup;
  addStepLocation: UntypedFormGroup;
  createCluster: UntypedFormGroup;
  addStepToPhaseForm: UntypedFormGroup;
  completeStepForm: UntypedFormGroup;
  editSupplierStep: UntypedFormGroup;
  selectSiteForm: UntypedFormGroup;
  batchStepForm: UntypedFormGroup;
  updatePhaseStep: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.addChain = this._fb.group({
      name: [null, [Validators.required]],
      product: [null, [Validators.required]],
      direction: ['topBottom'],
    });

    this.selectSiteForm = this._fb.group({
      manufactory: [null, [Validators.required]],
    });

    this.addStep = this._fb.group({
      name: [null, [Validators.required]],
      supplier: [null],
    });

    this.updatePhaseStep = this._fb.group({
      type_step: '',
    });

    this.updateStep = this._fb.group({
      name: [null, [Validators.required]],
      description: [null],
      ingredient: [],
      co2_value: [null],
      co2_unit: [null],
      co2_unit2: [null],
      supplier: [null],
      manufactories: [],
      units: [null],
      position_x: [null],
      position_y: [null],
      product_chain: [null],
      type_step: [null],
      address: [null],
      city: [null],
      country: [''],
      longitude: [''],
      latitude: [''],
      supplier_batch_number: [''],
      brand_status: [''],
    });

    this.addStepLocation = this._fb.group({
      name: ['', [Validators.required]],
      step: [],
      address: [],
      city: [],
      country: ['', Validators.required],
      longitude: [],
      latitude: [],
    });

    this.createCluster = this._fb.group({
      name: ['', Validators.required],
      color: [''],
      chain: ['', Validators.required],
      steps: [],
    });

    this.addStepToPhaseForm = this._fb.group({
      product_chain: ['', Validators.required],
      steps: [''],
      name: [''],
    });

    this.completeStepForm = this._fb.group({
      quantity: [''],
      quantity_unit: [''],
      reference: [''],
      status: '',
      date_completion: '',
      step_operator_name: '',
    });

    this.batchStepForm = this._fb.group({
      name: [null],
      description: [''],
      ingredient: [],
      co2_value: [null],
      co2_unit: [null],
      co2_unit2: [null],
      supplier: [null],
      manufactories: [],
      units: [null],
      position_x: [null],
      position_y: [null],
      product_chain: [null],
      phase: [null],
      address: [null],
      city: [null],
      country: [''],
      label: [''],
      quantity: [''],
      reference: [''],
      batch: [''],
      location: [''],
      certification_step: [null],
      documents: [null],
      quantity_unit: [''],
      proofs: [null],
    });

    this.editSupplierStep = this._fb.group({
      name: [null, [Validators.required]],
      description: [null],
      ingredient: [],
      co2_value: [null],
      co2_unit: [null],
      co2_unit2: [null],
      supplier: [null],
      manufactories: [],
      units: [null],
      position_x: [null],
      position_y: [null],
      product_chain: [null],
      phase: [null],
      address: [null],
      city: [null],
      country: [''],
      label: [''],
      quantity: [''],
      reference: [''],
      batch: [''],
      location: [''],
    });
  }
}
