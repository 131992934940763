<div class="wrapper">
  <div class="header">
    <h1>{{ data.classification ? ('Edit phase' | translate) : ('Create phase' | translate) }}</h1>
  </div>

  <div class="content">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-5">
          <h5>{{ 'Phase Name' | translate }}</h5>
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'Phase Name' | translate }}</mat-label>
            <input matInput formControlName="name" placeholder="{{ 'Enter Name' | translate }}" required />
          </mat-form-field>
        </div>
        <div class="col-12 margin-top">
          <h5>{{ 'Phase Color' | translate }}</h5>
          <span class="note">{{
            'We suggest to use a color that is significantly different from other phase color.' | translate
          }}</span>
          <div class="color-wraper" (click)="colorPicker.click()">
            <p>{{ 'Change color here' | translate }}</p>
            <div class="cluster-color" [style.background]="form.get('color')?.value"></div>
          </div>
          <input
            class="color-picker-input"
            #colorPicker
            [colorPicker]="form.get('color')?.value"
            (colorPickerChange)="colorPickerChange($event)"
          />
        </div>
      </div>
    </form>
    <div class="margin-top">
      <app-rounded-button
        class="underline-button"
        color="tetriary-link"
        [button_text]="'Cancel'"
        [size]="'md'"
        (click)="cancel()"
      >
      </app-rounded-button>
      <app-rounded-button
        color="success"
        [button_text]="data.classification ? 'Update Phase' : 'Create Phase'"
        [size]="'md'"
        (click)="!data.classification ? saveChanges() : updateClassification()"
        [disabled]="form && !form.valid"
      >
      </app-rounded-button>
    </div>
  </div>
</div>
