import {
  IActivateDeactivateProductResponse,
  IAddComponentProduct,
  IDeleteCertification,
  IDeleteProduct,
  IProduct,
  IProductActiveCertificatesResponse,
  IProductCertificatesRespone,
  IProductChainRequest,
  IProductChainUpdateResponse,
  IProductComponentsResponse,
  IProductGroup,
  IProductGroupResponse,
  IProductResponse,
  IProductsListResponse,
  IsDocMediaForPublicationResponse,
  IsLinkForPublicationResponse,
} from './../interface/products.interface';
import { ICreateProductForm, ICreateProductResponse } from '../interface/register.interface';
import { IDashboardBrandInfo, IDashboardDefaultInfo } from '../interface/dashboard.interface';
import { Subject, forkJoin } from 'rxjs';

import { ISupplier } from '../interface/suppliers.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ObservableInput } from 'rxjs/internal/types';
import { ProductsService } from '@service/products.service';
import { SuppliersService } from '../service/suppliers.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { generateFormData } from '../utils/generate-formData';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { take } from 'rxjs/internal/operators/take';
import { throwError } from 'rxjs/internal/observable/throwError';
import { IPublicationProductChain, SetupPublicationProductRequest } from '../interface/publication.interface';

@Injectable()
export class ProductsFacade {
  updateSupplierList = new Subject<boolean>();
  constructor(
    private _productsService: ProductsService,
    private _suppliersService: SuppliersService
  ) {}

  getProducts$(ordering?: string): Observable<IProductsListResponse> {
    return this._productsService.getProducts$(ordering).pipe(
      take(1),
      catchError(({ error }: IProductsListResponse): ObservableInput<IProductsListResponse> => throwError(() => error))
    );
  }

  getActiveProducts$(): Observable<IProductsListResponse> {
    return this._productsService.getActiveProducts$().pipe(
      take(1),
      catchError(({ error }: IProductsListResponse): ObservableInput<IProductsListResponse> => throwError(() => error))
    );
  }

  deleteProduct$(uuid: string): Observable<IDeleteProduct> {
    return this._productsService.deleteProduct$(uuid).pipe(
      take(1),
      catchError(({ error }: IDeleteProduct): ObservableInput<IDeleteProduct> => throwError(() => error))
    );
  }
  cloneProduct$(uuid: string | null): Observable<ICreateProductResponse> {
    return this._productsService.cloneProduct$(uuid).pipe(
      take(1),
      catchError(
        ({ error }: ICreateProductResponse): ObservableInput<ICreateProductResponse> => throwError(() => error)
      )
    );
  }

  updateProduct$(data: Partial<ICreateProductForm>, uuid: string): Observable<ICreateProductResponse> {
    return this._productsService.updateProduct$(data, uuid).pipe(
      take(1),
      catchError(
        ({ error }: ICreateProductResponse): ObservableInput<ICreateProductResponse> => throwError(() => error)
      )
    );
  }

  getProduct$(uuid: string | null): Observable<IProductResponse> {
    return this._productsService.getProduct$(uuid).pipe(
      take(1),
      catchError(({ error }: IProductResponse): ObservableInput<IProductResponse> => throwError(() => error))
    );
  }

  getProductForPublication$(uuid: string | null): Observable<IPublicationProductChain> {
    return this._productsService.getProductForPublication$(uuid).pipe(
      take(1),
      catchError(
        ({ error }: IPublicationProductChain): ObservableInput<IPublicationProductChain> => throwError(() => error)
      )
    );
  }

  cloneAndUpdateProduct$(data: Partial<ICreateProductForm>, uuid: string): Observable<ICreateProductResponse> {
    return this.cloneProduct$(uuid).pipe(
      take(1),
      switchMap((res: ICreateProductResponse): Observable<ICreateProductResponse> => {
        const clonedUuid = res.uuid;
        return this.updateProduct$(data, clonedUuid).pipe(
          take(1),
          catchError(
            ({ error }: ICreateProductResponse): ObservableInput<ICreateProductResponse> => throwError(() => error)
          )
        );
      }),
      catchError(
        ({ error }: ICreateProductResponse): ObservableInput<ICreateProductResponse> => throwError(() => error)
      )
    );
  }

  getProductCertificates$(uuid: string): Observable<IProductCertificatesRespone> {
    return this._productsService.getProductCertificates$(uuid).pipe(
      take(1),
      catchError(
        ({ error }: IProductCertificatesRespone): ObservableInput<IProductCertificatesRespone> =>
          throwError(() => error)
      )
    );
  }

  deleteCertification$(product_uuid: string, uuid: string): Observable<IDeleteCertification> {
    return this._productsService.deleteCertification$(product_uuid, uuid).pipe(
      take(1),
      catchError(({ error }: IDeleteCertification): ObservableInput<IDeleteCertification> => throwError(() => error))
    );
  }
  removeSupplier$(productUuid: string, supplierUuid: string): Observable<IDeleteProduct> {
    return this._productsService.removeSupplier$(productUuid, supplierUuid).pipe(
      take(1),
      catchError(({ error }: IDeleteProduct): ObservableInput<IDeleteProduct> => throwError(() => error))
    );
  }

  addSupplierToProduct$(productUuid: string, supplierUuid: string): Observable<ISupplier> {
    const formData = generateFormData({ supplier: supplierUuid });
    return this._productsService.addSupplierToProduct$(formData, productUuid).pipe(
      take(1),
      catchError(({ error }: ISupplier): ObservableInput<ISupplier> => throwError(() => error))
    );
  }

  createAndAddSupplierToProduct$(data: Partial<ISupplier>, productUuid: string): Observable<ISupplier> {
    const formData = generateFormData(data);
    return this._suppliersService.createSupplier$(formData).pipe(
      take(1),
      switchMap((res: ISupplier): Observable<ISupplier> => {
        const newSupplieId = res.uuid;
        return this.addSupplierToProduct$(productUuid, newSupplieId).pipe(
          take(1),
          catchError(({ error }: ISupplier): ObservableInput<ISupplier> => throwError(() => error))
        );
      }),
      catchError(({ error }: ISupplier): ObservableInput<ISupplier> => throwError(() => error))
    );
  }

  updateProductChain$(data: IProductChainRequest, uuid: string): Observable<IProductChainUpdateResponse> {
    return this._productsService.updateProductChain$(data, uuid).pipe(
      take(1),
      catchError(
        ({ error }: IProductChainUpdateResponse): ObservableInput<IProductChainUpdateResponse> =>
          throwError(() => error)
      )
    );
  }

  updateProductsOrder$(data: string[]): Observable<IProductsListResponse> {
    return this._productsService.updateProductsOrder$(data).pipe(
      take(1),
      catchError(({ error }: ICreateProductResponse): ObservableInput<IProductsListResponse> => throwError(() => error))
    );
  }

  getDashboardDefault$(): Observable<IDashboardDefaultInfo> {
    return this._productsService.getDashboardDefault$().pipe(
      take(1),
      catchError(({ error }: ICreateProductResponse): ObservableInput<IDashboardDefaultInfo> => throwError(() => error))
    );
  }

  getProductsDashboard$(): Observable<IDashboardBrandInfo> {
    return this._productsService.getProductsDashboard$().pipe(
      take(1),
      catchError(({ error }: ICreateProductResponse): ObservableInput<IDashboardBrandInfo> => throwError(() => error))
    );
  }

  getProductsAndDashboardInfo$(): Observable<[IProductsListResponse, IDashboardBrandInfo]> {
    return forkJoin([this.getProducts$(), this.getProductsDashboard$()]);
  }

  addComponentToProduct$(productUuid: string, componentUuid: string): Observable<IAddComponentProduct> {
    const formData = generateFormData({ ingredient: componentUuid });
    return this._productsService.addComponentToProduct$(productUuid, formData).pipe(
      take(1),
      catchError(({ error }: IAddComponentProduct): ObservableInput<IAddComponentProduct> => throwError(() => error))
    );
  }

  getProductComponents$(uuid: string, ordering?: string): Observable<IProductComponentsResponse> {
    return this._productsService.getProductComponents$(uuid, ordering).pipe(
      take(1),
      catchError(
        ({ error }: IProductComponentsResponse): ObservableInput<IProductComponentsResponse> => throwError(() => error)
      )
    );
  }

  activateDeactivateProduct$(
    uuid: string,
    data: Partial<IActivateDeactivateProductResponse>
  ): Observable<IActivateDeactivateProductResponse> {
    return this._productsService.activateDeactivateProduct$(uuid, data).pipe(
      take(1),
      catchError(
        ({ error }: IActivateDeactivateProductResponse): ObservableInput<IActivateDeactivateProductResponse> =>
          throwError(() => error)
      )
    );
  }

  getPublicProducts$(): Observable<IProduct[]> {
    return this._productsService.getPublicProducts$().pipe(
      take(1),
      catchError(({ error }: IProductsListResponse): ObservableInput<IProduct[]> => throwError(() => error))
    );
  }

  getProductGroups$(): Observable<IProductGroupResponse> {
    return this._productsService.getProductGroups$().pipe(
      take(1),
      catchError(({ error }: IProductGroupResponse): ObservableInput<IProductGroupResponse> => throwError(() => error))
    );
  }

  updateProductDocuments$(
    productUuid: string,
    documentUuid: string,
    document: FormData
  ): Observable<IsDocMediaForPublicationResponse> {
    return this._productsService.updateProductDocuments$(productUuid, documentUuid, document).pipe(
      take(1),
      catchError(
        ({ error }: IsDocMediaForPublicationResponse): ObservableInput<IsDocMediaForPublicationResponse> =>
          throwError(() => error)
      )
    );
  }

  updateProductMedias$(
    productUuid: string,
    mediaUuid: string,
    media: FormData
  ): Observable<IsDocMediaForPublicationResponse> {
    return this._productsService.updateProductMedias$(productUuid, mediaUuid, media).pipe(
      take(1),
      catchError(
        ({ error }: IsDocMediaForPublicationResponse): ObservableInput<IsDocMediaForPublicationResponse> =>
          throwError(() => error)
      )
    );
  }

  updateProductLinks$(productUuid: string, linkUuid: string, link: FormData): Observable<IsLinkForPublicationResponse> {
    return this._productsService.updateProductLinks$(productUuid, linkUuid, link).pipe(
      take(1),
      catchError(
        ({ error }: IsLinkForPublicationResponse): ObservableInput<IsLinkForPublicationResponse> =>
          throwError(() => error)
      )
    );
  }

  // TEMPORALLY Disable any as a Back-End issue.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveSetup$(uuid: string, data: SetupPublicationProductRequest): Observable<any> {
    // TEMPORALLY SEND JSON AS A PAYLOAD INSTEAD OF FormData. Back-End issue.
    // const formData = generateFormData(data);
    return this._productsService.savePublicationSetup(uuid, data).pipe(
      take(1),
      catchError(({ error }: IProductGroup): ObservableInput<IProduct> => throwError(() => error))
    );
  }

  getProductActiveCertificates$(uuid: string): Observable<IProductActiveCertificatesResponse> {
    return this._productsService.getProductActiveCertificates$(uuid).pipe(
      take(1),
      catchError(
        ({ error }: IProductActiveCertificatesResponse): ObservableInput<IProductActiveCertificatesResponse> =>
          throwError(() => error)
      )
    );
  }
}
