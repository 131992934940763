import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IFactory, ISupplier, ISupplierIngredient, ISuppliersResponse } from '@app/core/interface/suppliers.interface';
import { ILanguage, ILanguagesResponse } from '@app/core/interface/languages.interface';
import { INode, IProductChainLink, IProductChainResult } from '@app/core/interface/productchain.interface';
import { IProductChainStep, IProductChainStepErrorResponse } from '@app/core/interface/steps.interface';
import { Observable, forkJoin, map, of, startWith } from 'rxjs';

import { IEmailVarValues } from '@app/core/interface/email.interface';
import { IIngredient } from '@app/core/interface/ingredient.interface';
import { IMedia } from '@app/core/interface/medias.interface';
import { IPhase } from '@app/core/interface/phase-management.interface';
import { ITypeStepErrorResponse } from '@app/core/interface/steps.interface';
import { IngredientDetailsFormComponent } from './ingredient-details-form/ingredient-details-form.component';
import { InviteUsersDialogComponent } from '@app/shared/components/invite-users-dialog/invite-users-dialog.component';
import { LanguagesFacade } from '@app/core/facade/languages.facade';
import { MatDialog } from '@angular/material/dialog';
import { NodeService } from '@app/core/service/node.service';
import { NotificationType } from '@app/core/constants';
import { NotifyDialogComponent } from '../notify-dialog/notify-dialog.component';
import { ProductChainGroup } from '../product-chain.group';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { StepDetailsDialogComponent } from '../step-details-dialog/step-details-dialog.component';
import { StepsFacade } from '@app/core/facade/steps.facade';
import { StepsService } from '@app/core/service/steps.service';
import { SupplierIdentificationDialogComponent } from '../supplier-identification-dialog/supplier-identification-dialog.component';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-step-update',
  templateUrl: './step-update.component.html',
  styleUrls: ['./step-update.component.scss'],
  providers: [ProductChainGroup, StepsFacade],
})
export class StepUpdateComponent implements OnInit {
  @ViewChild('ingredientDetailsForm') ingredientDetailsForm!: IngredientDetailsFormComponent;
  @Input() productChain?: IProductChainResult;
  @Input() phases?: IPhase[];
  @Input() variables!: IEmailVarValues;
  @Input() selectedChain?: IProductChainResult;

  @Output() closeDialog = new EventEmitter<boolean>();
  form: UntypedFormGroup;
  @Input() supplierList?: ISupplier[];
  filteredSuppliers!: Observable<ISupplier[] | undefined>;
  manufactories!: IFactory[];
  ingredients!: ISupplierIngredient[];
  selectedStepUuid: string | undefined;
  selectedSupplier?: ISupplier;
  selectedManufactories!: string[];
  stepData!: IProductChainStep;
  newStepData = false;
  units: Array<{ value: string; name: string }> = [
    { value: 'GRAM', name: 'gr' },
    { value: 'KG', name: 'kg' },
  ];
  units2: Array<{ value: string; name: string }> = [
    { value: 'KG EXTRACTED', name: 'kg extracted' },
    { value: 'KWH', name: 'kwh' },
    { value: 'KM', name: 'km' },
  ];
  photos?: IMedia[];
  videos?: IMedia[];
  languages: ILanguage[] = [];

  sipApprovedStatus?: number;
  showReadMore = false;

  nodeObj?: INode | null;

  constructor(
    private _group: ProductChainGroup,
    private _stepsFacade: StepsFacade,
    private nodeService: NodeService,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog,
    private languagesFacade: LanguagesFacade,
    private _stepService: StepsService,
    private _supplierFacade: SuppliersFacade
  ) {
    this.form = this._group.updateStep;
  }

  ngOnInit() {
    this.supplierList?.push({
      name: '',
      description: '',
      uuid: '',
      count_unique_product_chains: null,
      manufactories: [] as IFactory[],
      ingredients: [] as ISupplierIngredient[],
      product_chain: [],
      medias: [],
      action: '',
    });

    this.nodeService.selectedStepData$.subscribe(node => {
      this.nodeObj = node;

      if (node) {
        this.form.patchValue({
          name: node.name,
          description: node.description ?? '',
          manufactories: node.manufactories?.uuid ?? '',
          supplier: node.supplier ?? '',
          position_x: node.position_x,
          position_y: node.position_y,
          product_chain: typeof node.product_chain === 'string' ? node.product_chain : node.product_chain?.uuid,
          type_step: node.type_step?.uuid ?? '',
          ingredient: node.ingredient?.uuid ?? '',
          supplier_batch_number: this.stepData?.supplier_batch_number ?? '',
          brand_status: node?.brand_status ?? '',
        });

        this.selectedStepUuid = node.uuid ? node.uuid : node.id;
        if (this.selectedStepUuid) {
          this.getStep(this.selectedStepUuid);
        }
        this.selectedSupplier = this.supplierList?.find(supplier => supplier.uuid === node.supplier?.uuid);
        this.manufactories = this.selectedSupplier?.manufactories ? this.selectedSupplier?.manufactories : [];
        this.ingredients = this.selectedSupplier?.ingredients
          ? this.selectedSupplier?.ingredients.filter(
              ingredient => ingredient.manufactory?.uuid === this.form.controls['manufactories'].value
            )
          : [];

        if (!this.manufactories.find(x => x.name === '')) {
          this.manufactories.unshift({
            uuid: '',
            country: '',
            city: '',
            is_main_location: false,
            address: '',
            name: '',
            logo: '',
            latitude: 0,
            longitude: 0,
          });
        }
      }
    });

    this.getLanguages();

    this.filteredSuppliers = this.form.controls['supplier'].valueChanges.pipe(
      startWith(''),
      map(value => {
        if (typeof value == 'string') {
          return value
            ? this._filter(value as string)?.filter(supplier => supplier.is_active)
            : this.supplierList?.slice().filter(supplier => supplier.is_active);
        }
        return this.supplierList?.slice().filter(supplier => supplier.is_active);
      })
    );

    this._stepService.matchingSupplierVerification?.subscribe(res => {
      // The result of step maping dialog.
      if (res) {
        this._snackbarService.openTypeSnackbar(`The supplier is validated successfully`, NotificationType.success);
      } else if (res != null && res == false) {
        this._snackbarService.openTypeSnackbar(`The supplier is not validated`, NotificationType.warning);
        this._dialog.open(NotifyDialogComponent, {
          width: '580px',
          height: '360px',
          autoFocus: false,
          panelClass: 'notify-dialog-container',
        });
      }

      if (this.selectedStepUuid) {
        // The property for not changing the manufactory after the SIP is approved.
        this.newStepData = true;
        this.ngOnInit();
        // Get only active suppliers.
        this._supplierFacade
          .getSuppliers$(true)
          .subscribe({ next: this._setSupplierList.bind(this), error: this._error.bind(this) });
      }
    });

    this._stepService.notMatchingSupplierSubject?.subscribe(res => {
      if (res) {
        this._snackbarService.openTypeSnackbar(`The supplier is unapproved`, NotificationType.warning);
      }
      if (this.selectedStepUuid) this.getStep(this.selectedStepUuid);
    });
  }

  _setSupplierList(data: ISuppliersResponse) {
    this.supplierList = data.results;
  }

  getLanguages(): void {
    this.languagesFacade.getLanguages$().subscribe({
      next: this._setLanguagesResponse.bind(this),
      error: this._error.bind(this),
    });
  }

  _setLanguagesResponse(languageResponse: ILanguagesResponse) {
    this.languages = languageResponse.results;
  }

  getStep(uuid: string): void {
    this._stepsFacade.getStep$(uuid).subscribe({
      next: this.getStepSuccess.bind(this),
      error: this.errorResponse.bind(this),
    });
  }

  getStepSuccess(data: IProductChainStep): void {
    this.stepData = data;
    if (data.sip_supplier_identified_approved != null) {
      this.sipApprovedStatus = data.sip_supplier_identified_approved;
    }

    // If the status is not approved get only active suppliers.
    if (this.sipApprovedStatus != 0) {
      this._supplierFacade
        .getSuppliers$(true)
        .subscribe({ next: this._setSupplierList.bind(this), error: this._error.bind(this) });
    }

    this.photos = data.medias?.filter((media: IMedia) => media.mimetype.indexOf('image') > -1);
    this.videos = data.medias?.filter((media: IMedia) => media.mimetype.indexOf('video') > -1);
    this.form.patchValue({
      co2_value: data.co2_impacts && data.co2_impacts[0] ? data.co2_impacts[0].value : '',
      co2_unit: data.co2_impacts && data.co2_impacts[0] ? data.co2_impacts[0].unit : this.units[0].value,
      co2_unit2: data.co2_impacts && data.co2_impacts[0] ? data.co2_impacts[0].unit2 : this.units2[0].value,
      supplier_batch_number: this.stepData?.supplier_batch_number ?? '',
      brand_status: data?.brand_status ?? '',
      supplier: this.stepData.supplier ?? '',
      manufactories: this.stepData.manufactories?.uuid ?? '',
    });

    if (this.nodeObj?.supplier) {
      this.nodeObj.supplier = this.stepData.supplier;
    }

    if (this.newStepData) {
      this.newStepData = false;
      this.selectedSupplier = data.supplier as ISupplier;
      this.manufactories = [data.manufactories] as IFactory[];
    }
  }

  onSupplierChange(uuid: string | undefined): void {
    this.selectedSupplier = this.supplierList?.find(supplier => supplier.uuid === uuid);
    this.manufactories = this.selectedSupplier?.manufactories ? this.selectedSupplier?.manufactories : [];
    this.ingredients = this.selectedSupplier?.ingredients ? this.selectedSupplier?.ingredients : [];
    if (!this.manufactories.find(x => x.name === '')) {
      this.manufactories.unshift({
        uuid: '',
        country: '',
        city: '',
        is_main_location: false,
        address: '',
        name: '',
        logo: '',
        latitude: 0,
        longitude: 0,
      });
    }

    this.form.controls['manufactories'].reset('', false);
    this.form.controls['ingredient'].reset('', false);
    this.form.controls['manufactories'].markAsPristine();
    this.form.controls['ingredient'].markAsPristine();
  }

  onSupplierSiteChange(): void {
    this.ingredients = this.selectedSupplier?.ingredients
      ? this.selectedSupplier?.ingredients.filter(
          ingredient => ingredient.manufactory?.uuid === this.form.controls['manufactories'].value
        )
      : [];
    this.form.controls['ingredient'].reset('', false);
    this.form.controls['ingredient'].markAsPristine();
  }

  errorResponse(error: ITypeStepErrorResponse): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  updateStep(): void {
    if (this.form.valid && this.selectedStepUuid) {
      const supplierValue = this.form.value.supplier['uuid'];
      if (supplierValue === '') {
        delete this.form.value.manufactories;
      }

      // Do not update the form data directly. Please use spread operator.
      const formData = {
        ...this.form.value,
        supplier: this.selectedSupplier?.uuid ?? '',
      };
      // Not OK: this.form.controls['supplier'].setValue(this.selectedSupplier ? this.selectedSupplier.uuid : '');
      if (!this.selectedSupplier?.uuid) {
        delete formData.manufactories;
      }

      const stepUpdate = this._stepsFacade.updateStep$(formData, this.selectedStepUuid);
      const ingredientDetailsUpdate = this.ingredientDetailsForm?.updateIngredientDetails() ?? of(null);

      forkJoin({
        stepUpdate,
        ingredientDetailsUpdate,
      }).subscribe({
        next: this._updateStepSuccess.bind(this),
        error: this._updateStepError.bind(this),
      });
    }
  }

  openEmailDialog(): void {
    const supplierNameValue = this.getConnectedSupplierNames();
    const dialogRef = this._dialog.open(InviteUsersDialogComponent, {
      width: '1160px',
      height: '768px',
      autoFocus: false,
      panelClass: ['email-dialog-container', 'padding-0'],
      data: {
        uuid: this.selectedSupplier?.uuid,
        variables: { ...this.variables, 'Supplier name': supplierNameValue, 'Step name': this.stepData.name },
        languages: this.languages,
        supplier: this.selectedSupplier,
        step: this.selectedStepUuid,
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.updateStep();
      }
    });
  }

  getConnectedSupplierNames(): string | null {
    const connectedStepUuids = this.selectedChain?.links_steps
      .filter((step: IProductChainLink) => step.step_from === this.selectedStepUuid)
      .map((step: IProductChainLink) => step.step_to);

    if (!connectedStepUuids || connectedStepUuids.length === 0) {
      return null;
    }
    const supplierNames = this.selectedChain?.steps
      .filter((step: IProductChainStep) => {
        return step.supplier !== undefined && connectedStepUuids.includes(step.uuid);
      })
      .map((step: IProductChainStep) => step.supplier && step.supplier.name);

    if (!supplierNames || supplierNames.length === 0) {
      return null;
    }
    if (supplierNames[0] && supplierNames.length === 1) {
      return supplierNames[0];
    }
    const lastSupplierName = supplierNames.pop();
    return `${supplierNames.join(', ')} & ${lastSupplierName}`;
  }

  onCheckDetails() {
    const ref = this._dialog.open(StepDetailsDialogComponent, {
      // data: element,
      width: '1160px',
      height: 'calc(100% - 100px)',
      autoFocus: false,
      panelClass: 'step-detail-dialog-container',
      data: this.stepData,
    });

    ref.afterClosed().subscribe((res: string) => {
      if (res) this._snackbarService.openTypeSnackbar(res, NotificationType.success);
    });
  }

  onVerifySupplier() {
    this._dialog.open(SupplierIdentificationDialogComponent, {
      // data: element,
      width: '1160px',
      height: 'calc(100% - 80px)',
      autoFocus: false,
      panelClass: 'step-detail-dialog-container',
      data: this.stepData,
    });
  }

  private _updateStepSuccess(data: {
    stepUpdate: IProductChainStep;
    ingredientDetailsUpdate: IIngredient | null;
  }): void {
    this.nodeService.selectedStepData.next(null);
    this.nodeService.updateListView.next(true);
    const stepUpdate = data.stepUpdate;
    if (stepUpdate) {
      this._snackbarService.openTypeSnackbar(`Step ${stepUpdate.name} updated`, NotificationType.success);
    }
  }
  cancel() {
    this.nodeService.selectedStepData.next(null);
  }
  displayFn(supplier: ISupplier | null): string {
    return supplier?.name ?? '';
  }

  private _filter(value: string): ISupplier[] | undefined {
    const filterValue = value.toLowerCase();

    return this.supplierList?.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private _updateStepError(error: IProductChainStepErrorResponse): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  toggleText() {
    this.showReadMore = !this.showReadMore;
  }
}
