export const requestTypes = [
  { label: 'Correction request', value: 1 },
  { label: 'Deletion request', value: 2 },
  { label: 'Data portability request', value: 3 },
  { label: 'Data breach form', value: 4 },
];

export const successDialogText =
  'Your request has been received. We will review your request and respond within the timeframe specified by GDPR. You will receive an email confirmation once your request has been processed. For questions, contact us at support@productdna.com';

export const helperText =
  'Here you can view and manage your data privacy settings, including requests to access, update, or delete your anonymized or pseudonymized data.';

export const requestDialogTooltip =
  'Manage Your Data Access Requests If you wish to access, correct, or delete your anonymized or pseudonymized data, please use the form below. We will process your request in accordance with GDPR regulations.';

export const emailSent = 'Your request was processed and securely sent to your email';
