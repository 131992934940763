import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { IProductGroupResponse } from '@interface/products.interface';
import { Observable } from 'rxjs';

import { AmwayCreateGroupComponent } from '@module/publication/custom-publications/amway/amway-create-group/amway-create-group.component';
import { AmwayCreateStepComponent } from '@module/publication/custom-publications/amway/amway-create-step/amway-create-step.component';
import { AmwayEditClassificationComponent } from '@module/publication/custom-publications/amway/amway-edit-classification/amway-edit-classification.component';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IProduct } from '@app/core/interface/products.interface';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { PublicationFacade } from '@app/core/facade/publication.facade';
import { environment } from '@env/environment';
import { CustomPublicationsFacade } from '@module/publication/custom-publications/custom-publications.facade';
import {
  ICustomPublication,
  ICustomPublicationCustomField,
  ICustomPublicationStep,
  ICustomPublicationTypeGroup,
  ICustomPublicationTypeStep,
} from '@module/publication/custom-publications/custom-publications.models';

@Component({
  selector: 'app-amway-publication-page',
  templateUrl: './amway-publication-page.component.html',
  styleUrls: ['./amway-publication-page.component.scss'],
})
export class AmwayPublicationPageComponent {
  productChainUuid!: string;
  showVideoMsg = true;
  classifications!: ICustomPublicationTypeStep[];
  unclassifiedSteps?: ICustomPublicationStep[] = [];
  url!: string;
  itemCtrl: FormControl;
  productGroups!: IProductGroupResponse;
  product!: IProduct;
  productUid!: string;
  delayedRequests: Observable<unknown>[] = [];
  groupSelected? = '';
  customPublication!: ICustomPublication;
  groups: ICustomPublicationTypeGroup[] = [];

  constructor(
    private _facade: PublicationFacade,
    private _prodcutsFacade: ProductsFacade,
    private _route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _router: Router,
    private _customPublicationFacade: CustomPublicationsFacade
  ) {
    this.itemCtrl = new FormControl();
  }

  ngOnInit(): void {
    this.url = `${environment.respectCodeUrl}/brand/amway`;
    this.getProductGroups();
    const productChainUid = this._route.snapshot.paramMap.get('id');
    if (productChainUid) {
      this.productChainUuid = productChainUid;
      this._customPublicationFacade.getPublication$(this.productChainUuid).subscribe({
        next: this._getPublicationSuccess.bind(this),
        error: this._error.bind(this),
      });
    }
    this._route.queryParams.subscribe(params => {
      this.productUid = params['product'];
      this._prodcutsFacade.getProduct$(this.productUid).subscribe(product => {
        this.product = product;
        this.groupSelected = this.product.product_group?.uuid;
      });
    });
  }

  public _getPublicationSuccess(data: ICustomPublication) {
    this.customPublication = data;
    this.classifications = data.type_steps;
    this.unclassifiedSteps = data.unclassified_steps;
    const activeCustomFields: ICustomPublicationCustomField[] = data.unclassified_custom_fields.filter(
      customField => customField.field_options.length > 0
    );

    data.type_steps.forEach((typeStep: ICustomPublicationTypeStep) => {
      typeStep.groups.forEach((stepGroup: ICustomPublicationTypeGroup) => {
        stepGroup.custom_fields.forEach(customField => {
          const index = this.classifications.indexOf(typeStep);
          const indexOfGroup = this.classifications[index].groups.indexOf(stepGroup);
          this.classifications[index]?.groups[indexOfGroup].steps?.push(
            this.getEmptyStep(customField.field_name, customField.uuid, stepGroup.uuid, stepGroup.name)
          );
        });
      });
    });

    activeCustomFields.forEach(x => {
      this.unclassifiedSteps?.push(this.getEmptyStep(x.field_name, x.uuid));
    });
  }

  dropClassification(event: CdkDragDrop<ICustomPublicationTypeStep[]>) {
    moveItemInArray(this.classifications, event.previousIndex, event.currentIndex);
    this.updateClassificationPostions();
  }

  dropGroup(event: CdkDragDrop<ICustomPublicationTypeGroup[]>, classification: ICustomPublicationTypeStep) {
    if (classification.groups) {
      this.groups = classification.groups;
      moveItemInArray(classification.groups, event.previousIndex, event.currentIndex);
      this.updateGroupPositions();
    }
  }

  dropItem(event: CdkDragDrop<ICustomPublicationStep[]>, classificationUuid?: string) {
    if (event.container.data && event.previousContainer.data) {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.updateStepPostions(event.container.data);
      } else {
        transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        const keys = Object.keys(event.container.data[event.currentIndex]);
        if (keys && !keys.includes('custom_fields')) {
          classificationUuid
            ? this.addStep(event.container.data[event.currentIndex], classificationUuid)
            : this.removeStep(event.container.data[event.currentIndex]);
        } else {
          transferArrayItem(
            event.container.data,
            event.previousContainer.data,
            event.currentIndex,
            event.previousIndex
          );
        }
      }
    }
  }

  dropGroupItem(event: CdkDragDrop<ICustomPublicationStep[]>, classificationUuid: string, groupUuid: string) {
    if (event.container.data && event.previousContainer.data) {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.updateStepPostions(event.container.data);
      } else {
        transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        this.addStep(event.container.data[event.currentIndex], classificationUuid, groupUuid);
      }
    }
  }

  getConnectedListAll(): string[] {
    const ids: string[] = [];

    this.classifications.map(x => {
      ids.push(`${x.uuid}`);
      x.groups?.map((group: ICustomPublicationTypeGroup) => {
        ids.push(group.uuid);
      });
    });
    ids.push('unclassified');

    return ids;
  }

  updateClassificationPostions(): void {
    const positions: string[] = this.classifications.map(classification => classification.uuid);
    this._customPublicationFacade.updateClassificationsPosition$(positions).subscribe({
      error: this._error.bind(this),
    });
  }

  editClassification(classification?: ICustomPublicationTypeStep, classificationIndex: number = -1): void {
    const dialogRef = this._dialog.open(AmwayEditClassificationComponent, {
      width: '1160px',
      height: '660px',
      panelClass: 'padding-0',
      data: { classification, product_chain: this.productChainUuid },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && classificationIndex > -1) {
        const keys = Object.keys(result.changedFormValues);
        keys.forEach(key => {
          // @ts-expect-error Must
          this.classifications[classificationIndex][key] = result.changedFormValues[key];
        });
      } else {
        this.classifications.push({ ...result, steps: new Array(0) });
      }
    });
  }

  removeClassification(uuid: string, indexOfClassification: number): void {
    if (this.productChainUuid) {
      if (indexOfClassification > -1) {
        // only splice array when item is found
        this.classifications.splice(indexOfClassification, 1); // 2nd parameter means remove one item only
      }
      this._customPublicationFacade.deleteClassification(this.productChainUuid, uuid).subscribe({
        error: this._error.bind(this),
      });
    }
  }

  addStep(step: ICustomPublicationStep, classificationUuid: string, groupUuid?: string): void {
    const updatedStep = {
      type_step: classificationUuid,
      group: groupUuid ? groupUuid : '',
    };
    if (step.product_chain !== '') {
      this._customPublicationFacade.addStepToClassification(step.uuid, classificationUuid, groupUuid).subscribe({
        error: this._error.bind(this),
      });
    } else {
      this._customPublicationFacade.addCustomFieldToGroup(step.uuid, updatedStep.group).subscribe({
        error: this._error.bind(this),
      });
    }
  }

  removeStep(step: ICustomPublicationStep): void {
    if (step.product_chain !== '') {
      this._customPublicationFacade.addStepToClassification(step.uuid, '', '', true).subscribe({
        error: this._error.bind(this),
      });
    } else {
      this._facade
        .deletePublicationStepCustomFields$(step.group!.uuid, step.uuid)
        .subscribe({ error: this._error.bind(this) });
    }
  }

  updateStepPostions(stepsList: ICustomPublicationStep[]): void {
    const positions: string[] = stepsList?.map(step => step.uuid);
    if ((this, this.productChainUuid)) {
      this._facade.updateStepsPosition$(positions).subscribe({
        error: this._error.bind(this),
      });
    }
  }

  createNewStep(): void {
    const dialogRef = this._dialog.open(AmwayCreateStepComponent, {
      width: '1160px',
      height: '768px',
      panelClass: ['padding-0', 'overflow-hidden-dialog'],
      data: { productChainUid: this.productChainUuid, step: undefined },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ngOnInit();
        this.ngOnInit();
      }
    });
  }

  addEditGroup(
    uuid: string,
    group?: ICustomPublicationTypeGroup,
    classificationIndex: number = -1,
    indexOfGroup: number = -1
  ): void {
    const dialogRef = this._dialog.open(AmwayCreateGroupComponent, {
      width: '1160px',
      height: '660px',
      panelClass: 'padding-0',
      data: { product_chain_uid: this.productChainUuid, type_step: uuid, group },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result && classificationIndex > -1 && indexOfGroup > -1) {
          const keys = Object.keys(result);
          keys.forEach(key => {
            if (this.classifications[classificationIndex]) {
              // @ts-expect-error Must
              this.classifications[classificationIndex].groups[indexOfGroup][key] = result[key];
            }
          });
        } else {
          this.classifications[classificationIndex].groups.push({ ...result, steps: new Array(0) });
        }
      }
    });
  }

  editStep(
    step: ICustomPublicationStep,
    indexOfStep: number,
    indexOfGroup: number,
    indexOfClassification: number
  ): void {
    const dialogRef = this._dialog.open(AmwayCreateStepComponent, {
      width: '1160px',
      height: '860px',
      panelClass: ['padding-0', 'overflow-hidden-dialog'],
      data: { productChainUid: this.productChainUuid, step },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let keys: string[] = [];
        if (result.updates.changedStepFormValues) {
          keys = Object.keys(result.updates.changedStepFormValues);
          keys.forEach(key => {
            // @ts-expect-error Must
            step[key] = result.updates.changedStepFormValues[key];
          });
        }
        if (result.updates.changedSupplierFormValues) {
          keys = Object.keys(result.updates.changedSupplierFormValues);
          keys.forEach(key => {
            if (step.supplier != null) {
              // @ts-expect-error Must
              step.supplier[key] = result.updates.changedSupplierFormValues[key];
            } else {
              step.supplier = result.updates.changedSupplierFormValues;
            }
          });
        }
        if (result.updates.changedManufactoryFormValues) {
          keys = Object.keys(result.updates.changedManufactoryFormValues);
          keys.forEach(key => {
            // @ts-expect-error Must
            step.manufactories[key] = result.updates.changedManufactoryFormValues[key];
          });
        }
        if (indexOfStep > -1) {
          if (this.unclassifiedSteps && indexOfGroup === -1 && indexOfClassification === -1) {
            const keys = Object.keys(step);
            keys.forEach(key => {
              // @ts-expect-error Must
              this.unclassifiedSteps[indexOfStep][key] = step[key];
            });
          } else if (indexOfGroup === -1 && indexOfClassification > -1) {
            if (this.classifications[indexOfClassification]) {
              const keys = Object.keys(step);
              keys.forEach(key => {
                // @ts-expect-error Must
                this.classifications[indexOfClassification].steps[indexOfStep][key] = step[key];
              });
            }
          } else if (indexOfGroup > -1) {
            if (this.classifications[indexOfClassification]) {
              keys.forEach(key => {
                // @ts-expect-error Must
                this.classifications[indexOfClassification].groups[indexOfGroup].steps[indexOfStep][key] = step[key];
              });
            }
          }
        }
      }
    });
  }

  hideStep(
    step: ICustomPublicationStep,
    indexOfStep: number,
    indexOfGroup: number,
    indexOfClassification: number
  ): void {
    step.is_hidden = !step.is_hidden;
    if (indexOfStep) {
      if (this.unclassifiedSteps && indexOfGroup === -1 && indexOfClassification === -1) {
        this.unclassifiedSteps[indexOfStep] = step;
      } else if (indexOfClassification === -1 && indexOfGroup === -1 && indexOfClassification) {
        if (this.classifications[indexOfClassification]) {
          this.classifications[indexOfClassification].steps[indexOfStep] = step;
        }
      }
    }
    if (this.productChainUuid) {
      this._customPublicationFacade.updateStep$({ is_hidden: step.is_hidden }, step.uuid).subscribe({
        error: this._error.bind(this),
      });
    }
  }

  deleteGroup(uuid: string, indexOfClassification: number, indexOfGroup: number): void {
    if (indexOfClassification > -1 && indexOfGroup > -1) {
      this.classifications[indexOfClassification].groups[indexOfGroup].steps.forEach(x => {
        if (x.product_chain === '') {
          this.unclassifiedSteps?.push(this.getEmptyStep(x.name, x.uuid));
        } else {
          this.unclassifiedSteps?.push(x);
        }
      });
      this.classifications[indexOfClassification].groups.splice(indexOfGroup, 1);
    }
    this._customPublicationFacade.deletePublicationGroup(uuid).subscribe({
      error: this._error.bind(this),
    });
  }

  updateGroupPositions(): void {
    const groupUpdates: string[] = this.groups.map(group => group.uuid);
    this._customPublicationFacade.updateGroupPositions$(groupUpdates).subscribe({
      error: this._error.bind(this),
    });
  }

  goBack(): void {
    if (this.productUid) {
      this._router.navigate([`/dashboard/product/${this.productUid}`]).then(() => window.location.reload());
    } else {
      this._router.navigate([
        `/chain-setup/chain-management/${this.productChainUuid}`,
        { currentUrl: this._router.url, tab: 'chains' },
      ]);
    }
  }

  publish(): void {
    this._customPublicationFacade.publish(this.productChainUuid).subscribe({
      next: this.publishSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  publishSuccess() {
    this._router.navigate([`/publish/${this.productChainUuid}/custom-publications/amway/finished`], {
      state: { product: this.product },
    });
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackBar.open(err[0], 'OK'));
  }

  private getEmptyStep(name: string, uuid: string, groupUid = '', groupName = ''): ICustomPublicationStep {
    return {
      name: name,
      uuid: uuid,
      is_hidden: false,
      description: '',
      manufactories: [],
      medias: [],
      documents: [],
      supplier_batch_number: '',
      group: {
        uuid: groupUid,
        name: groupName,
      },
      position_x: 0,
      product_chain: '',
      quantity: 0,
      reference: '',
      supplier: {
        name: '',
        uuid: '',
        logo: '',
        description: '',
        country: '',
        is_address_private: false,
        is_description_private: false,
        is_name_private: false,
        is_tags_private: false,
        is_country_private: false,
        is_logo_private: false,
      },
      type_step: {
        name: '',
        uuid: '',
        color: '',
        position: 0,
      },
    };
  }

  shouldDisableFinish() {
    if (this.unclassifiedSteps) {
      return (
        this.unclassifiedSteps.filter((step: ICustomPublicationStep) => !step.is_hidden && step.product_chain !== '')
          .length > 0
      );
    }
    return true;
  }

  getProductGroups() {
    this._prodcutsFacade.getProductGroups$().subscribe({
      next: this.getProductGroupsSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  getProductGroupsSuccess(data: IProductGroupResponse) {
    this.productGroups = data;
  }

  productGroupChanged($event: string) {
    this._customPublicationFacade
      .attachProductGroup(this.productUid, $event)
      .subscribe({ error: this._error.bind(this) });
  }
}
