import { Component, Input, OnInit } from '@angular/core';
import { IProduct, IProductGroup } from '@app/core/interface/products.interface';

import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { Storage } from '@app/core/constants';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-create-product-form',
  templateUrl: './create-product-form.component.html',
  styleUrls: ['./create-product-form.component.scss'],
})
export class CreateProductFormComponent implements OnInit {
  @Input() mode: 'duplicate' | 'edit' | undefined;
  @Input() form!: UntypedFormGroup;
  isAmway: boolean = false;
  productGroups?: IProductGroup[];

  constructor(
    private _dataStorageService: DataStorageService,
    private _productsFacade: ProductsFacade
  ) {}

  get imageUrl(): string {
    return this.form.controls['image'].value;
  }

  ngOnInit(): void {
    const brandName = this._dataStorageService.get('brand_name', Storage.local);
    this.isAmway = brandName?.toLowerCase() === 'amway';

    if (this.isAmway) {
      this.form.controls['product_group'].enable();
      this._productsFacade.getProductGroups$().subscribe(data => {
        this.productGroups = data.results;
      });
    }
  }

  /**
   * On fileData trigered we are putting the file in the image formcontrol
   * @param file image that needs to be put in form
   */
  saveImageData(file: File): void {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = evt => {
      const base64 = evt.target?.result;
      this.form.get('image')?.setValue(base64);
    };
    this.form.get('image')?.markAsDirty();
  }

  setFormData(data: IProduct) {
    this.form.patchValue({
      name: this.mode === 'duplicate' ? `${data.name} COPY` : data.name,
      description: data.description,
      summary: data.summary,
      reference: data.reference,
      image: data.image,
      product_group: data.product_group?.uuid,
    });
    this.mode === 'duplicate' && this.form.get('name')?.markAsDirty();
  }
}
