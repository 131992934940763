import { Chart, ChartOptions, ChartType } from 'chart.js';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { NodeService } from '@app/core/service/node.service';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut.component.html',
  styleUrls: ['./doughnut.component.scss'],
})
export class DoughnutComponent implements OnChanges {
  @Input() selectedPhase = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() doughnutChartData!: any;

  labelIndex: number = 0;
  contrastColor = '';

  chart!: Chart;
  options?: ChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: data => {
            this.labelIndex = data.dataIndex;
            if (data.label.length > 16 && data.label.length < 32) {
              return [data.label.slice(0, 16), data.label.slice(16, 32)];
            } else if (data.label.length > 32) {
              return [data.label.slice(0, 16), data.label.slice(16, 30) + '...'];
            } else {
              return data.label;
            }
          },
          labelTextColor: () => this.contrastColor,
        },
        bodyFont: {
          size: 12,
        },
        padding: 3,
        displayColors: false,

        backgroundColor: () => {
          const backgroundColor = this.doughnutChartData.datasets[0].backgroundColor[this.labelIndex];

          const hex = backgroundColor.replace('#', '');
          const r = parseInt(hex.substring(0, 2), 16);
          const g = parseInt(hex.substring(2, 4), 16);
          const b = parseInt(hex.substring(4, 6), 16);
          const yiq = (r * 299 + g * 587 + b * 114) / 1000;
          this.contrastColor = yiq >= 128 ? '#292d32' : '#e9e9e9'; //if the color is bright, use secondary black, else use secondary white

          return backgroundColor;
        },
        borderColor: () => this.contrastColor,
        borderWidth: 0.5,
      },
    },
    events: ['mousemove', 'click'],
    layout: {
      padding: {
        right: 20,
      },
    },
  };
  doughnutChartType: ChartType = 'doughnut';

  constructor(private nodeService: NodeService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartClicked(event: any): void {
    const index = event.active[0].index;
    const newSelectedPhase = this.doughnutChartData.labels?.[index];

    this.doughnutChartData.datasets[0].backgroundColor?.forEach((_element: string, i: number) => {
      this.doughnutChartData.datasets[0].backgroundColor[i] = this.doughnutChartData.datasets[0].hoverBorderColor[i];
      if (i !== index && newSelectedPhase !== this.selectedPhase) {
        this.doughnutChartData.datasets[0].backgroundColor[i] = '#EBECED';
      }
    });

    if (newSelectedPhase !== this.selectedPhase) {
      this.nodeService.selectedPhase.next(newSelectedPhase);
      this.selectedPhase = this.doughnutChartData.labels?.[index];
    } else {
      this.nodeService.selectedPhase.next(null);
      this.selectedPhase = '';
    }

    this.chart = event.event.chart;

    event.event.chart.update();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedPhase']?.currentValue === 'all') {
      this.doughnutChartData?.datasets[0].backgroundColor?.forEach((_element: string, i: number) => {
        this.doughnutChartData.datasets[0].backgroundColor[i] = this.doughnutChartData.datasets[0].hoverBorderColor[i];
      });

      this.chart?.update();
      this.selectedPhase = '';
    }
  }
}
