import { AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ProductsFacade } from '@app/core/facade/products.facade';
import {
  ICertification,
  ICertificationObjectSelected,
  ICertificatsContentType,
} from '@app/core/interface/certificates.interface';
import { IProduct } from '@app/core/interface/products.interface';
import { BrandProductFormComponent } from './brand-product-form/brand-product-form.component';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-create-certificate-dialog',
  templateUrl: './create-certificate-dialog.component.html',
  styleUrls: ['./create-certificate-dialog.component.scss'],
  providers: [ProductsFacade],
})
export class CreateCertificateDialogComponent implements AfterViewInit {
  @ViewChild(BrandProductFormComponent)
  brandProductFormComponent!: BrandProductFormComponent;
  showChooseObjectNote = true;
  selectedObject: ICertificationObjectSelected | null = null;
  objectType: ICertificatsContentType = 'Brand';
  selectedTabIndex = 0;
  mode = 'select-object';
  productsList: IProduct[] = [];
  productsDataSource = new MatTableDataSource<IProduct>();
  productsObservable$!: Observable<IProduct[]>;
  showTabSelector = false;
  createCertificate!: boolean;
  objectSelected!: ICertificationObjectSelected | null;
  submitClicked = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ICertification,
    private _dialogRef: MatDialogRef<CreateCertificateDialogComponent>,
    private cdRef: ChangeDetectorRef
  ) {
    this.objectSelected = this.selectedObject;
    if (data) {
      this.mode = 'forms';
      this.selectedObject = {
        name: data.certificate_subject?.name,
        image: data.certificate_subject?.image,
        uuid: data.certificate_subject?.uuid,
      };
      this.objectType = <ICertificatsContentType>data.certificate_subject?.type;
    }
  }

  continueToCreate() {
    this.submitClicked = this.showTabSelector;
    this.mode = 'forms';
    this.cdRef.detectChanges();
  }

  onTabChange(event: MatTabChangeEvent) {
    const objectType = <ICertificatsContentType>event.tab.textLabel;
    this.objectType = objectType;
    this.selectedObject = null;
    this.setBrandAndMode();
  }

  closeDialog() {
    this._dialogRef.close(true);
  }

  onObjectSelected(object: ICertificationObjectSelected) {
    if (!this.data) {
      this.selectedObject = object;
    }
    this.setBrandAndMode();
  }

  setBrandAndMode() {
    if (!this.data) {
      if (this.objectType === 'Brand') {
        this.mode = 'forms';
        this.showTabSelector = true;
      } else {
        this.showTabSelector = false;
        this.mode = 'select-object';
      }
    }
  }

  ngAfterViewInit(): void {
    this.setBrandAndMode();
  }

  resetSubmit() {
    this.submitClicked = false;
  }
}
