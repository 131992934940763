import { Component, inject } from '@angular/core';

import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { IProduct } from '@interface/products.interface';
import { IProductChainResult } from '@app/core/interface/productchain.interface';
import { NotificationType } from '@app/core/constants';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { Storage } from '@app/core/constants';
import { environment } from '@env/environment';

@Component({
  selector: 'app-amway-finished-publication',
  templateUrl: './amway-finished-publication.component.html',
  styleUrl: './amway-finished-publication.component.scss',
})
export class AmwayFinishedPublicationComponent {
  readonly _snackbarService = inject(SnackbarService);
  product!: IProduct;
  isAmway: boolean = false;
  url!: string;
  respectCodeUrl = environment.respectCodeUrl;
  published_refernce!: string;
  response!: IProductChainResult;

  constructor(
    private _router: Router,
    private _dataStorageService: DataStorageService
  ) {
    const brandName = this._dataStorageService.get('brand_name', Storage.local);
    this.isAmway = brandName?.toLowerCase() === 'amway';
    const navigation = this._router.getCurrentNavigation();
    if (navigation?.extras.state) {
      this.product = navigation.extras.state['product'];
      this.published_refernce = navigation.extras.state['reference'];
    }
  }

  ngOnInit(): void {
    if (this.isAmway) {
      this.url = `${environment.amwayUIUrl}${this.product.product_group?.name}/${this.product.name.replaceAll(' ', '-')}-${this.product.external_reference_id}`;
    } else {
      this.url = `${this.respectCodeUrl}/product/${this.published_refernce}`;
    }
  }

  goToProduct(): void {
    this._router.navigate(['/dashboard/product', this.product.uuid]);
  }

  goToExternalLink(): void {
    window.open(this.url, '_blank');
  }

  copyUrlLink(): void {
    if (this.url) {
      // Use Clipboard API to copy the URL
      navigator.clipboard
        .writeText(this.url)
        .then(() => {
          this._snackbarService.openTypeSnackbar('Link successfully copied.', NotificationType.success);
        })
        .catch(() => {
          this._snackbarService.openTypeSnackbar('Failed to copy the link.', NotificationType.error);
        });
    }
  }
}
