import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AddFieldsFacade } from '@facade/add-fields.facade';
import { AngularMaterialModule } from '@app/material.module';
import { BrandManagementGroup } from '@app/module/brand-management/brand-management.group';
import { BrandPreviewComponent } from './brand-preview/brand-preview.component';
import { BrandsFacade } from '@app/core/facade/brands.facade';
import { ChainOverviewComponent } from './chain-overview/chain-overview.component';
import { ChainOverviewGroup } from './chain-overview/chain-overview.group';
import { ColorPickerModule } from 'ngx-color-picker';
import { CommonModule } from '@angular/common';
import { CreateGroupComponent } from './chain-overview/setup-chain/create-group/create-group.component';
import { CreateStepComponent } from './chain-overview/setup-chain/create-step/create-step.component';
import { CustomPublicationsModule } from './custom-publications/custom-publications.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EditClassificationComponent } from './chain-overview/setup-chain/edit-classification/edit-classification.component';
import { FilesMeadiasLinksFacade } from '@app/core/facade/files-medias-links.facade';
import { NgModule } from '@angular/core';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { ProductChainFacade } from '@app/core/facade/productchain.facade';
import { ProductChainGroup } from '@module/product-chain/product-chain.group';
import { ProductPreviewComponent } from './product-preview/product-preview.component';
import { ProductPreviewGroup } from './product-preview/product-preview.group';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { PublicationComponent } from './publication.component';
import { PublicationFacade } from '@app/core/facade/publication.facade';
import { PublicationRouterModule } from './publication-router.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SetupChainComponent } from './chain-overview/setup-chain/setup-chain.component';
import { SharedModule } from '@app/shared/shared.module';
import { StepDetailsComponent } from './chain-overview/setup-chain/create-step/step-details/step-details.component';
import { StepItemComponent } from '@module/publication/chain-overview/setup-chain/step-item/step-item.component';
import { SupplierDetailsComponent } from './chain-overview/setup-chain/create-step/supplier-details/supplier-details.component';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';

@NgModule({
  declarations: [
    PublicationComponent,
    BrandPreviewComponent,
    ProductPreviewComponent,
    ChainOverviewComponent,
    SetupChainComponent,
    EditClassificationComponent,
    CreateStepComponent,
    StepDetailsComponent,
    SupplierDetailsComponent,
    CreateGroupComponent,
    StepItemComponent,
  ],
  exports: [PublicationRouterModule, SetupChainComponent],
  imports: [
    CommonModule,
    PublicationRouterModule,
    SharedModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    DragDropModule,
    NgxMapboxGLModule,
    CustomPublicationsModule,
    ColorPickerModule,
  ],
  providers: [
    BrandManagementGroup,
    BrandsFacade,
    ProductsFacade,
    ProductChainFacade,
    ProductChainGroup,
    PublicationFacade,
    ChainOverviewGroup,
    FilesMeadiasLinksFacade,
    SuppliersFacade,
    ProductPreviewGroup,
    provideHttpClient(withInterceptorsFromDi()),
    AddFieldsFacade,
  ],
})
export class PublicationModule {}
