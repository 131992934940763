import { Component, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CountryList } from 'src/app/core/utils/country-list';
import { Certification, FilePreview, Step } from '@app/core/interface/preview_publicaton.interface';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';
import { CertificateInfoDialogComponent } from '../certificate-info-dialog/certificate-info-dialog.component';
import { VideoDialogComponent } from '../video-dialog/video-dialog.component';

@Component({
  standalone: true,
  imports: [CommonModule, SharedModule],
  selector: 'app-supplier-info',
  templateUrl: './supplier-info.component.html',
  styleUrls: ['./supplier-info.component.scss'],
})
export class SupplierInfoComponent implements OnChanges {
  getCountryName = CountryList.getCountryName;
  @Input() data?: Step;
  images?: FilePreview[];
  videos?: FilePreview[];

  constructor(private dialog: MatDialog) {}

  slideConfig = { slidesToShow: 3, slidesToScroll: 2 };
  slideConfigVideo = { slidesToShow: this.videos?.length && this.videos?.length == 1 ? 1 : 3, slidesToScroll: 2 };

  ngOnChanges(): void {
    this.videos = this.data?.supplier?.medias?.filter(el => el.mimetype?.includes('video'));
    this.images = this.data?.supplier?.medias?.filter(el => el.mimetype?.includes('image'));
  }
  onImageDialog(imgUrl: FilePreview[], slide: FilePreview) {
    const sortedImages = this.sortImgs([...imgUrl], slide);

    const innerWidth = window.innerWidth;

    if (innerWidth <= 450) {
      this.dialog.open(ImageDialogComponent, {
        data: sortedImages,
        width: 'max-content',
        height: 'fit-content',
        panelClass: 'step-info-dialog',
        maxWidth: innerWidth - 20 + 'px',
      });
    } else {
      this.dialog.open(ImageDialogComponent, {
        data: sortedImages,
        width: 'fit-content',
        height: 'fit-content',
        panelClass: 'step-info-dialog',
      });
    }
  }

  sortImgs(items: FilePreview[], slide: FilePreview) {
    const indexToMove = items.findIndex(item => item.file === slide.file);
    if (indexToMove !== -1 && indexToMove !== 0) {
      const itemToMove = items.splice(indexToMove, 1)[0]; // Remove the item from the array
      items.unshift(itemToMove);
    }

    return items;
  }

  onCertificateDialog(certificate: Certification) {
    this.dialog.open(CertificateInfoDialogComponent, {
      data: certificate,
      width: '1160px',
      height: '660px',
      panelClass: 'step-info-dialog',
    });
  }

  onVideoDialog(videoUrl: string) {
    this.dialog.open(VideoDialogComponent, {
      data: videoUrl,
      width: 'fit-content',
      height: 'fit-content',
      panelClass: 'step-info-dialog',
    });
  }
}
