import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NotificationType, SELECTED_LANGUAGE, Storage, USER } from '@app/core/constants';
import { Observable, Subject, take, takeUntil } from 'rxjs';

import { CookiesManagementComponent } from './shared/components/cookies-management/cookies-management.component';
import { CustomSnackbarComponent } from './shared/components/custom-snackbar/custom-snackbar.component';
import { DataAuthService } from '@app/core/service/data-auth.service';
import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { IPageTranslationResponse } from './core/interface/translation.interface';
import { IUser } from './core/interface/login.interface';
import { MatDialog } from '@angular/material/dialog';
import { SidenavService } from './core/service/sidenav.service';
import { SnackbarService } from './core/service/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationFacade } from './core/facade/translation.facade';
import { TranslationService } from './core/service/translation.service';
import { UrlStorageService } from './core/service/url-storage.service';
import { NavigationService } from './core/service/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [TranslationFacade],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild(CustomSnackbarComponent) snackbarComponent!: CustomSnackbarComponent;

  private _unsubscribe$: Subject<void> = new Subject();
  storedUser: IUser = this._dataStorageService.get(USER, Storage.local)
    ? this._dataStorageService.get(USER, Storage.local)
    : this._dataStorageService.get(USER, Storage.session);
  user$: Observable<IUser | null> = this._dataAuthService.user$;
  registerUrl = false;
  landingUrl = false;
  purchaseUrl = false;
  loginUrl = false;
  chainSetup = false;
  chainSetupManagement = false;
  isPublish = false;
  isSwitchBrand = false;
  isSelectBrand = false;
  isCreatePassword = false;
  isUserEmpty = false;
  isSupplier = false;
  showWelcomeMessage = true;
  currentLang = '';
  isSidenavChainManagement = false;
  isCollapsedSideNav = false;
  isUserLoggedIn = false;
  isSidenavVisible = false;
  isHeaderVisible = false;
  isContentVisible = false;
  contentClass = 'content h-100';
  sidenavContentClass = 'mat-sidenav-content';

  constructor(
    private _dataStorageService: DataStorageService,
    private _dataAuthService: DataAuthService,
    public translations: TranslateService,
    private router: Router,
    private _translationFacade: TranslationFacade,
    private _snackbarService: SnackbarService,
    private _sidenavService: SidenavService,
    private _dialog: MatDialog,
    public translationService: TranslationService,
    public urlStorageService: UrlStorageService,
    public navigationService: NavigationService
  ) {
    this.translations.addLangs(['en', 'fr']);
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        const finalUrl = e.urlAfterRedirects;
        this.registerUrl = e.url.includes('/register');
        this.landingUrl = e.url === '/landing';
        this.chainSetup = e.url.includes('/chain-setup');
        this.chainSetupManagement = e.url.includes('/chain-setup/chain-management');
        this.purchaseUrl = e.url === '/purchase';
        this.loginUrl = e.url.includes('/login');
        this.isPublish = e.url.includes('/publish');
        this.isSwitchBrand = e.url.includes('/switch-brand');
        this.isSelectBrand = e.url.includes('/select-brand');
        this.isCreatePassword = e.url.includes('/create-password');
        this.navigationService.isTermsAndConditions.set(e.url.includes('/terms-and-conditions'));

        this.showWelcomeMessage =
          e.url === '/dashboard' || e.url === '/landing' || e.url === '/login' || e.url === '/overview';
        this.landingUrl = finalUrl.startsWith('/landing');

        const isSidenavOpen = this._sidenavService.hasSidenav(e.urlAfterRedirects);
        this._sidenavService.setSidenavOpen(isSidenavOpen);
        this.checkVisibilityConditions();
      }
    });
  }

  ngOnInit(): void {
    this._dataAuthService.user = this.storedUser;
    this.setInitialLanguage();
    this.subscibeToLocalStorageUser();
    this.subscribeToLang();
    this._updateSnackbarClass();
    this._dataAuthService.user$.pipe(takeUntil(this._unsubscribe$)).subscribe(user => {
      this.isUserLoggedIn = !!user; // Set true if user is present, otherwise false
    });
  }

  checkVisibilityConditions() {
    // Basic condition
    const isBasicConditionMet =
      this.isUserLoggedIn &&
      !this.registerUrl &&
      !this.landingUrl &&
      !this.purchaseUrl &&
      !this.isSwitchBrand &&
      !this.isSelectBrand &&
      !this.isCreatePassword;

    // Extended condition that includes Terms and Conditions, chain setup and publish check
    const isExtendedConditionMet =
      isBasicConditionMet && !this.navigationService.isTermsAndConditions() && !this.chainSetup && !this.isPublish;

    // Conditions for different sections
    this.isSidenavVisible = isExtendedConditionMet;
    this.isHeaderVisible = isBasicConditionMet && !this.navigationService.isTermsAndConditions();
    this.isContentVisible = isBasicConditionMet && !this.chainSetup && !this.isPublish;

    // Manage CSS classes based on conditions
    this.contentClass = this.isContentVisible ? 'content background-color' : 'content h-100';
    this.sidenavContentClass =
      this.landingUrl || this.purchaseUrl || this.chainSetup || this.isPublish ? 'landing' : 'mat-sidenav-content';
  }

  setInitialLanguage() {
    this.currentLang = this._dataStorageService.get(SELECTED_LANGUAGE, Storage.local);
    if (!this.currentLang) {
      this.currentLang = 'en';
    }
    this._dataStorageService.set(SELECTED_LANGUAGE, this.currentLang, Storage.local);
    this.translations.use(this.currentLang);
  }

  subscibeToLocalStorageUser(): void {
    this.user$.pipe(takeUntil(this._unsubscribe$)).subscribe(async user => {
      if (!user) {
        this.isUserEmpty = true;
        if (this.currentLang) {
          this.setLocalTranslation(this.currentLang && this.currentLang !== 'null' ? this.currentLang : 'en');
          this.translations.use(this.currentLang && this.currentLang !== 'null' ? this.currentLang : 'en');
        }
      } else {
        const userString = typeof user === 'string' ? user : JSON.stringify(user);
        const userObj = JSON.parse(userString);
        if (userObj && userObj.is_supplier) {
          this.isSupplier = true;
          setTimeout(() => {
            this.getTranslation(this.currentLang && this.currentLang !== 'null' ? this.currentLang : 'en');
          }, 0);
        }
        this.isUserEmpty = false;
      }
    });
  }

  async setLocalTranslation(lang: string): Promise<void> {
    const localTranslations = await this.loadLocalTranslations(lang);
    this.translations.setTranslation(lang, localTranslations);
  }

  async loadLocalTranslations(lang: string): Promise<object> {
    const translations = await import(`../assets/i18n/${lang}.json`);
    const enTranslations = translations.default || translations;
    this.translations.use(lang);
    return enTranslations;
  }

  subscribeToLang(): void {
    this.translationService.language.pipe(takeUntil(this._unsubscribe$)).subscribe((data: string) => {
      if (this.currentLang === data || !data) return;
      this._dataStorageService.set(SELECTED_LANGUAGE, data, Storage.local);
      if (this.isUserEmpty) {
        this.isSupplier = false;
        this.setLocalTranslation(data);
      } else if (this.isSupplier) {
        this.getTranslation(data);
      }
      this.translations.use(data);
      this.currentLang = data;
    });
  }

  changeLang(lang: string): void {
    this.translations.use(lang);
  }

  ngOnDestroy(): void {
    this.urlStorageService.cleanUp();
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getTranslation(lang: string) {
    this._translationFacade.getPageTranslation$(lang).subscribe({
      next: this._success.bind(this),
      error: this._error.bind(this),
    });
  }

  _success(data: IPageTranslationResponse) {
    this.translationService
      .getAllKeysAndValues(this.currentLang)
      .pipe(take(1))
      .subscribe((translations: Record<string, string>) => {
        const mergerdTranslation = this.translationService.getDeepMergeTranslation(
          translations,
          this.translationService.extractTranslationFromResoponse(data.results)
        );
        this.translations.setTranslation(this.currentLang, mergerdTranslation);
        this.translations.use(this.currentLang);
        this.translationService.setSelectedLanguage(this.currentLang);
      });
  }

  ///////////////////////////////////////////PRIVATE METHODS///////////////////////////////////////////

  private _updateSnackbarClass() {
    const snack = document.querySelector('.mat-mdc-snack-bar-container');
    if (this._sidenavService.getIsSidenavOpen() && this._sidenavService.getIsSnackbarOpen()) {
      snack?.classList.add('hasSidenav');
    } else {
      snack?.classList.remove('hasSidenav');
    }
  }

  openCookiesManagement(): void {
    this._dialog.open(CookiesManagementComponent, {
      autoFocus: false,
      width: '600px',
    });
  }

  private _error(error: Record<string, string[]>): void {
    this.setLocalTranslation(this.currentLang);
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
