import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TermsAndConditionsRoutingModule } from './terms-and-conditions-routing.module';
import { TermsAndConditionsComponent } from './terms-and-conditions.component';
import { SharedModule } from '../../shared/shared.module';
import { ServiceAgreementComponent } from './service-agreement/service-agreement.component';
import { CookiesPolicyComponent } from './cookies-policy/cookies-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AcceptablePolicyComponent } from './acceptable-policy/acceptable-policy.component';
import { DataProtectionPolicyComponent } from './data-protection-policy/data-protection-policy.component';

@NgModule({
  declarations: [
    TermsAndConditionsComponent,
    ServiceAgreementComponent,
    PrivacyPolicyComponent,
    AcceptablePolicyComponent,
    DataProtectionPolicyComponent,
  ],
  imports: [CommonModule, TermsAndConditionsRoutingModule, SharedModule, CookiesPolicyComponent],
})
export class TermsAndConditionsModule {}
