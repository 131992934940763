import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  ICustomPublication,
  ICustomPublicationCustomField,
  ICustomPublicationStep,
  ICustomPublicationTypeGroup,
  ICustomPublicationTypeStep,
} from '../../custom-publications/custom-publications.models';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { ActivatedRoute } from '@angular/router';
import { ClusterFacade } from '@app/core/facade/cluster.facade';
import { CountryList } from '@app/core/utils/country-list';
import { CreateGroupComponent } from './create-group/create-group.component';
import { CustomPublicationsFacade } from '../../custom-publications/custom-publications.facade';
import { EditClassificationComponent } from './edit-classification/edit-classification.component';
import { INode } from '@app/core/interface/productchain.interface';
import { IProductChainStep } from '@app/core/interface/steps.interface';
import { ISupplier } from '@app/core/interface/suppliers.interface';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NodeService } from '@app/core/service/node.service';
import { PublicationFacade } from '@app/core/facade/publication.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { finalize, Subscription } from 'rxjs';

@Component({
  selector: 'app-setup-chain',
  templateUrl: './setup-chain.component.html',
  styleUrls: ['./setup-chain.component.scss'],
  providers: [ClusterFacade],
})
export class SetupChainComponent implements OnInit, AfterViewInit, OnDestroy {
  productChainUuid?: string | null;
  getCountryName = CountryList.getCountryName;
  showVideoMsg = true;
  classifications!: ICustomPublicationTypeStep[];
  unclassifiedSteps?: ICustomPublicationStep[] = [];
  productChain!: ICustomPublication;
  @Input() supplierList?: ISupplier[];
  @Input() isChainComplete!: boolean;
  @Output() createStep = new EventEmitter<boolean>();
  private isFetching!: boolean;
  private listViewUpdatedSubscription!: Subscription;

  constructor(
    private _facade: PublicationFacade,
    private _customPublicationFacade: CustomPublicationsFacade,
    private _route: ActivatedRoute,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog,
    private nodeService: NodeService,
    private _clusterFacade: ClusterFacade
  ) {}

  ngOnInit(): void {
    this.getPublication();
  }

  getPublication(): void {
    if (this.isFetching) {
      return; // Exit if already fetching to avoid duplicate calls
    }
    this.isFetching = true;
    this.productChainUuid = this._route.snapshot.paramMap.get('id');
    if (this.productChainUuid) {
      this._customPublicationFacade
        .getPublication$(this.productChainUuid)
        .pipe(
          finalize(() => (this.isFetching = false)) // Reset flag when fetch completes
        )
        .subscribe({
          next: data => this.successGetPublication(data),
          error: this._error.bind(this),
        });
    }
  }

  successGetPublication(data: ICustomPublication): void {
    this.nodeService.updateListView.next(false);
    this.classifications = data.type_steps;
    this.unclassifiedSteps = data.unclassified_steps;
    this.productChain = data;
    this.setupCustomFields(data);
  }

  ngAfterViewInit(): void {
    this.listViewUpdatedSubscription = this.nodeService.updateListView$.subscribe((data: boolean) => {
      if (data) {
        this.getPublication();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.listViewUpdatedSubscription) {
      this.listViewUpdatedSubscription.unsubscribe();
    }
  }

  setupCustomFields(data: ICustomPublication): void {
    const activeCustomFields: ICustomPublicationCustomField[] = data.unclassified_custom_fields.filter(
      customField => customField.field_options.length
    );

    data.type_steps.forEach((typeStep: ICustomPublicationTypeStep, indexTypeStep: number) => {
      typeStep.groups.forEach((stepGroup: ICustomPublicationTypeGroup, indexGroup: number) => {
        stepGroup.custom_fields.forEach(customField => {
          this.classifications[indexTypeStep]?.groups[indexGroup].steps?.push(
            this.getEmptyStep(customField.field_name, customField.uuid, stepGroup.uuid, stepGroup.name)
          );
        });
      });
    });

    activeCustomFields.forEach(customField => {
      this.unclassifiedSteps?.push(this.getEmptyStep(customField.field_name, customField.uuid));
    });
  }

  dropClassification(event: CdkDragDrop<ICustomPublicationTypeStep[]>) {
    moveItemInArray(this.classifications, event.previousIndex, event.currentIndex);
    this.updateClassificationPostions();
  }

  dropGroup(event: CdkDragDrop<ICustomPublicationTypeGroup[]>, classification: ICustomPublicationTypeStep) {
    if (classification.groups) {
      moveItemInArray(classification.groups, event.previousIndex, event.currentIndex);
      this.updateGroupPositions(classification.groups);
    }
  }

  updateGroupPositions(groups: ICustomPublicationTypeGroup[]): void {
    const groupUpdates: string[] = groups.map(group => group.uuid);
    this._customPublicationFacade.updateGroupPositions$(groupUpdates).subscribe({
      error: this._error.bind(this),
    });
  }

  dropItem(event: CdkDragDrop<ICustomPublicationStep[]>, classificationUuid?: string) {
    const containerData = event.container.data;
    const previousContainerData = event.previousContainer.data;

    if (containerData && previousContainerData && !event.item.data.steps) {
      if (event.previousContainer === event.container) {
        moveItemInArray(containerData, event.previousIndex, event.currentIndex);
        this.updateStepPostions(containerData);
      } else {
        transferArrayItem(previousContainerData, containerData, event.previousIndex, event.currentIndex);
        const movedItem = containerData[event.currentIndex];

        if (classificationUuid) {
          this.addStep(movedItem, classificationUuid);
        } else {
          this.removeStep(movedItem);
        }
      }
      setTimeout(() => {
        this.ngOnInit();
      }, 300);
    }
  }

  dropGroupItem(event: CdkDragDrop<ICustomPublicationStep[]>, classificationUuid: string, groupUuid: string) {
    if (!event.item.data.steps && event.container.data && event.previousContainer.data) {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.updateStepPostions(event.container.data);
      } else {
        transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        this.addStep(event.container.data[event.currentIndex], classificationUuid, groupUuid);
      }
    }
  }

  getConnectedListAll(): string[] {
    const ids: string[] = [];
    this.classifications?.map((x: ICustomPublicationTypeStep) => {
      ids.push(`${x.uuid}`);
      x.groups?.map((group: ICustomPublicationTypeGroup) => {
        ids.push(`${x.uuid}-${group.uuid}`);
      });
    });
    ids.push('unclassified');

    return ids;
  }

  updateClassificationPostions(): void {
    const positions: string[] = this.classifications.map(classification => classification.uuid);
    if (this.productChainUuid) {
      this._facade.updateChainClassificationsPosition$(positions).subscribe({
        error: this._error.bind(this),
      });
    }
  }

  editClassification(classification?: ICustomPublicationTypeStep): void {
    const dialogRef = this._dialog.open(EditClassificationComponent, {
      width: '1160px',
      height: '660px',
      panelClass: 'padding-0',
      data: { classification, product_chain: this.productChainUuid },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.data) {
        if (result.mode === 'create') {
          const newArray = [result.data].concat(this.classifications);
          this.classifications = newArray;
          this.updateClassificationPostions();
        }
        setTimeout(() => {
          this.nodeService.selectedStepData.next(null);
          this.ngOnInit();
        }, 300);
      }
    });
  }

  removeClassification(uuid: string): void {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText:
          'You are about to delete the phase. Please note that this action won’t delete the steps, it will just remove them from the phase.',
        confirmationText: 'Are you sure you want to continue?',
        btnText: 'Yes, Delete',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result && this.productChainUuid) {
        this._customPublicationFacade.deleteClassification(this.productChainUuid, uuid).subscribe({
          next: () => {
            setTimeout(() => {
              this.nodeService.selectedStepData.next(null);
              this.ngOnInit();
            }, 300);
          },
          error: this._error.bind(this),
        });
      }
    });
  }

  addStep(step: ICustomPublicationStep | IProductChainStep, classificationUuid: string, groupUuid?: string): void {
    const updatedStep: {
      type_step?: string;
      group: string;
    } = {
      type_step: classificationUuid,
      group: groupUuid ? groupUuid : '',
    };

    if (this.productChainUuid && !step.isCustomField) {
      this._facade.updatePublicationChainStep$({ ...updatedStep }, step.uuid).subscribe({
        error: this._error.bind(this),
      });
    } else {
      this._facade.addCustomFieldToGroup(step.uuid, updatedStep.group).subscribe({
        error: this._error.bind(this),
      });
    }
  }

  removeStep(step: ICustomPublicationStep | IProductChainStep): void {
    if (this.productChainUuid && !step.isCustomField) {
      this._facade.updatePublicationChainStep$({ type_step: '', group: '' }, step.uuid).subscribe({
        error: this._error.bind(this),
      });
    } else {
      this._facade
        .deletePublicationStepCustomFields$(step.group!.uuid, step.uuid)
        .subscribe({ error: this._error.bind(this) });
    }
  }

  updateStepPostions(stepsList: ICustomPublicationStep[]): void {
    const positions: string[] = stepsList.filter(step => !step.isCustomField).map(step => step.uuid);
    if ((this, this.productChainUuid)) {
      this._facade.updateStepsPosition$(positions).subscribe({
        error: this._error.bind(this),
      });
    }
  }

  createNewStep(): void {
    this.createStep.emit(true);
  }

  addEditGroup(uuid: string, group?: ICustomPublicationTypeGroup): void {
    const dialogRef = this._dialog.open(CreateGroupComponent, {
      width: '1160px',
      height: '660px',
      panelClass: 'top-padding-0',
      data: { productChainUuid: this.productChainUuid, type_step: uuid, group },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  editStep(step: ICustomPublicationStep | IProductChainStep | INode): void {
    const nodeData = step as INode;
    this.nodeService.selectedStepData.next(nodeData);
  }

  hideStep(step: ICustomPublicationStep | IProductChainStep): void {
    step.is_hidden = !step.is_hidden;
    if (this.productChainUuid) {
      this._customPublicationFacade.updateStep$({ is_hidden: step.is_hidden }, step.uuid).subscribe({
        next: () => this.ngOnInit(),
        error: this._error.bind(this),
      });
    }
  }
  deleteGroup(uuid: string): void {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText:
          "Please note that this action will just remove the group, and the steps in the group won't be deleted.",
        confirmationText: 'Are you sure you want to continue?',
        btnText: 'Yes, Delete',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._clusterFacade.deleteCluster$(uuid).subscribe({
          next: () => this.ngOnInit(),
          error: this._error.bind(this),
        });
      }
    });
  }

  private getEmptyStep(name: string, uuid: string, groupUid = '', groupName = ''): ICustomPublicationStep {
    return {
      name: name,
      uuid: uuid,
      is_hidden: false,
      description: '',
      manufactories: [],
      medias: [],
      documents: [],
      supplier_batch_number: '',
      group: {
        uuid: groupUid,
        name: groupName,
      },
      position_x: 0,
      product_chain: '',
      quantity: 0,
      reference: '',
      supplier: {
        name: '',
        uuid: '',
        logo: '',
        description: '',
        country: '',
        is_address_private: false,
        is_description_private: false,
        is_name_private: false,
        is_tags_private: false,
        is_country_private: false,
        is_logo_private: false,
      },
      type_step: {
        name: '',
        uuid: '',
        color: '',
        position: 0,
      },
      isCustomField: true,
    };
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
