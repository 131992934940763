export const environment = {
  production: false,
  qa: true,
  api: 'https://backsass.respect-code.org/en/api/v3',
  baseApiUrl: 'https://backsass.respect-code.org/',
  assetPath: '../assets/',
  mapBoxAccessToken: 'pk.eyJ1IjoicHJvZHVjdGRuYSIsImEiOiJja2huaWpjMWIwMjFyMzBwZW1wbnU4MzRuIn0.OC1GTcD3Qj3b70yKiv1l6w',
  respectCodeUrl: 'https://qa.respect-code.org/',
  amwayUIUrl: 'https://amway-prod.productdna.ch/',
  enableSentry: false,
};
