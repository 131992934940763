import { AddDirectSupplierComponent } from './edit-step/add-direct-supplier/add-direct-supplier.component';
import { CdkColumnDef } from '@angular/cdk/table';
import { ChainStepsInfoComponent } from './chain-steps-info.component';
import { ChainStepsInfoRoutingModule } from './chain-steps-info-routing.module';
import { CommonModule } from '@angular/common';
import { EditStepComponent } from './edit-step/edit-step.component';
import { MatTableModule } from '@angular/material/table';
import { NgModule } from '@angular/core';
import { ProductChainGroup } from '../product-chain/product-chain.group';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { StepDirectSupplierComponent } from './edit-step/step-direct-supplier/step-direct-supplier.component';
import { StepMainInfoComponent } from './edit-step/step-main-info/step-main-info.component';
import { SuppSuppliersFacade } from '@app/core/facade/supp-suppliers.facade';
import { BatchMainInfoComponent } from './edit-step/batch-main-info/batch-main-info.component';
import { FilesMeadiasLinksFacade } from '@app/core/facade/files-medias-links.facade';
import { CompleteDirectSupplierDialogComponent } from './edit-step/complete-direct-supplier-dialog/complete-direct-supplier-dialog.component';
import { CompleteDirectSupplierGroup } from './edit-step/complete-direct-supplier-dialog/complete-direct-supplier-dialog.group';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { UpdateStepAlertComponent } from './edit-step/update-step-alert/update-step-alert.component';
import { DirectSupplierModalComponent } from './edit-step/step-direct-supplier/direct-supplier-modal/direct-supplier-modal.component';

@NgModule({
  declarations: [
    ChainStepsInfoComponent,
    EditStepComponent,
    StepMainInfoComponent,
    StepDirectSupplierComponent,
    UpdateStepAlertComponent,
    AddDirectSupplierComponent,
    BatchMainInfoComponent,
    CompleteDirectSupplierDialogComponent,
    DirectSupplierModalComponent,
  ],
  imports: [CommonModule, SharedModule, ChainStepsInfoRoutingModule, ReactiveFormsModule, MatTableModule],
  providers: [
    SuppliersFacade,
    ProductChainGroup,
    SuppSuppliersFacade,
    FilesMeadiasLinksFacade,
    CompleteDirectSupplierGroup,
    CdkColumnDef,
  ],
})
export class ChainStepsInfoModule {}
