<div class="create-cluster">
  <div class="header align-items-center">
    <div class="title d-flex align-items-center justify-content-start">
      <h2>
        {{ data.cluster ? 'Edit ' : ('Create group' | translate) }}
      </h2>
      <p class="cluster-name" *ngIf="data.cluster">{{ data.cluster?.name }}</p>
    </div>

    <div *ngIf="data.cluster">
      <app-rounded-button
        size="md"
        color="secondary"
        class="link"
        [button_text]="'Remove Group'"
        [icon_category]="'essential'"
        [icon_name]="'minus-circle'"
        [icon_right]="true"
        (click)="deleteCluster(data.cluster.uuid)"
      >
      </app-rounded-button>
    </div>
  </div>

  <mat-dialog-content class="mat-typography">
    <div class="full-height d-flex flex-column align-items-center">
      <div class="content-holder">
        <form [formGroup]="form">
          <div [ngClass]="data.cluster?.uuid ? 'form-holder-edit' : 'form-holder-create'">
            <div class="input">
              <mat-form-field class="input-primary" appearance="outline">
                <mat-label>{{ 'Group of steps name' | translate }}</mat-label>
                <input matInput formControlName="name" placeholder="Group of steps name" required />
              </mat-form-field>
            </div>
            <div class="cluster-color">
              <div class="cluster-color-select" (click)="colorPicker.click()">
                <div class="col-10">
                  <h3>{{ data.cluster ? 'Edit group color' : 'Choose group color' }}</h3>
                </div>
                <div class="col-2">
                  <div class="cluster-color" [style.background]="form.get('color')?.value"></div>
                </div>

                <input
                  #colorPicker
                  [colorPicker]="form.get('color')?.value"
                  (colorPickerChange)="colorPickerChange($event)"
                />
              </div>
            </div>
          </div>
        </form>

        <div class="steps">
          <div class="row">
            <p>{{ 'Choose which steps to include in this group' | translate }}</p>
            <div class="col-4 step-box" *ngFor="let step of data.product_chain.steps; let i = index">
              <mat-slide-toggle
                class="custom-slide-toggle reverse-label-toggle"
                (change)="updateStepList(step.uuid ? step.uuid : '')"
                [color]="'primary'"
                [checked]="data.selectedSteps.includes(step.uuid ? step.uuid : '')"
              >
                {{ step.name }}
              </mat-slide-toggle>
              <div class="divider" *ngIf="i % 3 !== 2"></div>
            </div>
          </div>
        </div>
      </div>
      <mat-dialog-actions align="end">
        <div class="buttons-holder">
          <app-rounded-button color="tetriary-link" [button_text]="'Cancel'" [size]="'md'" mat-dialog-close>
          </app-rounded-button>
          <app-rounded-button
            color="primary"
            [button_text]="data.cluster ? 'Save Changes' : 'Create Group'"
            [size]="'md'"
            [disabled]="form && !form.valid"
            (click)="data.cluster ? updateCluster() : createCluster()"
            [icon_category]="'grid'"
            [icon_name]="'format-square'"
            [icon_right]="true"
          >
          </app-rounded-button>
        </div>
      </mat-dialog-actions>
    </div>
  </mat-dialog-content>
  <!-- <mat-dialog-actions align="end">

  </mat-dialog-actions> -->
</div>
