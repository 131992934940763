<div class="preview-publication">
  <header>
    <img src="../../../../assets/logos/RespectCodeByProductDna.svg" alt="" />
  </header>
  <div class="main-map-container">
    <div class="map-holder">
      <div class="map-panel">
        <div class="map" id="map"></div>
        <div class="map-panel-inner">
          @if (previewPublication !== null) {
            <app-product-info
              [product]="previewPublication?.product"
              [reference]="previewPublication?.reference"
              [publication_reference]="previewPublication?.publication_reference"
              [brand]="previewPublication?.brand"
              [batches]="previewPublication?.batches"
              [productCertificates]="productCertificates"
            ></app-product-info>
            <app-brand-info [productChain]="previewPublication"></app-brand-info>
          }
        </div>
      </div>
    </div>
  </div>
</div>
