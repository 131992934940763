<div class="wraper">
  <p class="note">
    {{ 'All personal data is protected. Learn more in our ' | translate }}
    <span [routerLink]="'/terms-and-conditions/Privacy policy'">{{ 'Privacy Policy' | translate }}</span>
  </p>

  <mat-card class="requests-list-wraper">
    <div class="header-wraper">
      <div class="options-header">
        <div class="requests-heading">
          <p>
            {{ 'Here you can manage all of your requests' | translate }}
          </p>
          <app-custom-icon
            [icon_category]="'essential'"
            [icon_name]="'info-circle'"
            [matTooltip]="helperText"
            matTooltipClass="custom-tooltip-center"
            matTooltipPosition="above"
          ></app-custom-icon>
        </div>
        <div class="actions">
          <app-rounded-button
            [button_text]="'Create request'"
            [icon_category]="'location'"
            [icon_name]="'direct-right'"
            [icon_right]="true"
            [size]="'md'"
            color="primary"
            (click)="openRequestCreation()"
          >
          </app-rounded-button>
          <app-rounded-button
            [button_text]="'Download your data'"
            [icon_category]="'messages'"
            [icon_name]="'direct-inbox'"
            [icon_right]="true"
            [size]="'md'"
            color="turquoise"
            (click)="downloadBrandUserData()"
          >
          </app-rounded-button>
        </div>
      </div>
    </div>

    <div class="table-wraper">
      <table class="custom-table" mat-table matSort [dataSource]="tableDataSource">
        <ng-container matColumnDef="request_type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Request Type ' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ requestType(element.request_type) }}</td>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Sent on' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.created_at | date: 'dd.MM.yyyy' }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Request Status' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <div
              class="status"
              [ngClass]="{ processing: element.status === 'Processing', sent: element.status === 'Sent on email' }"
            ></div>
            {{ element.status }}
            <app-custom-icon
              *ngIf="element.status === 'Sent on email'"
              [icon_category]="'essential'"
              [icon_name]="'info-circle'"
              [matTooltip]="emailSentText"
              matTooltipClass="custom-tooltip-center"
              matTooltipPosition="above"
            ></app-custom-icon>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell no-data" colspan="3">
            <app-custom-icon [icon_category]="'location'" [icon_name]="'direct-right'"></app-custom-icon>
            <span>There are no requests created yet.</span>
          </td>
        </tr>
      </table>
    </div>
    <div class="card-footer">
      <mat-paginator showFirstLastButtons aria-label="Select page of requests"> </mat-paginator>
    </div>
  </mat-card>
</div>
