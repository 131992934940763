<mat-card class="holder">
  <div class="header-details d-flex justify-content-between align-items-center">
    <h6 class="m-0">{{ 'Supplier Details' | translate }}</h6>
    <div class="buttons d-flex" *ngIf="showButtons || form.dirty">
      <app-rounded-button
        [color]="'turquoise'"
        [button_text]="supplierData?.uuid ? 'Update Supplier' : 'Create Supplier'"
        [size]="'md'"
        [icon_category]="'product'"
        [icon_name]="'truck-tick'"
        [icon_right]="true"
        class="create-btn"
        (click)="
          supplierData?.uuid ? updateSupplier() : form.valid ? createSupplier() : form.controls['name'].markAsTouched()
        "
      >
      </app-rounded-button>

      <app-rounded-button
        [color]="'tetriary-link'"
        [size]="'sm'"
        [button_text]="'Cancel'"
        class="cancel"
        (click)="supplierData?.uuid ? clearChanges() : closeDialog()"
      >
      </app-rounded-button>
    </div>
  </div>
  <form [formGroup]="form">
    <div class="supp-det">
      <!-- <div class="row"> -->
      <div class="file-upload">
        <div class="upload-dialog">
          <app-file-upload
            [placeholder]="'Logo'"
            [iconType]="'add_photo_alternate'"
            (fileData)="saveImageData($event)"
            [status]="form.controls['logo'].status"
            [imageUrl]="form.get('logo')?.value"
            [fileDataInput]="initialFileData"
            [isCancelable]="true"
            [uploadImageText]="'Upload logo'"
          >
          </app-file-upload>
        </div>
      </div>

      <div class="input-holder">
        <div class="top row">
          <div class="name-holder col-6">
            <mat-form-field class="input-primary" appearance="outline">
              <mat-label>{{ 'Supplier Name' | translate }}</mat-label>
              <input matInput formControlName="name" placeholder="Supplier Name" required />
              <mat-error *ngIf="form.controls['name'].hasError('required')">
                {{ 'Please enter supplier name in order to proceed' | translate }}</mat-error
              >
            </mat-form-field>
          </div>
          <div class="identifier col-6">
            <mat-form-field class="input-primary" appearance="outline">
              <mat-label>{{ 'Supplier Unique Identifier' | translate }}</mat-label>
              <input matInput formControlName="supplier_unique_identifier" placeholder="Supplier Unique Identifier" />
            </mat-form-field>
            <div
              class="identifier-info-holder d-flex align-items-center justify-content-center cursor-pointer"
              (mouseenter)="uniqueIdentifierInfo = true"
              (mouseleave)="uniqueIdentifierInfo = false"
            >
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'info-circle'"></app-custom-icon>
              <div class="tooltip" *ngIf="uniqueIdentifierInfo">
                <p>
                  {{ 'Here you can add the unique identifier of your company.' | translate }}
                </p>
                <p class="example">{{ ' Example: CHE-112.674.306.' | translate }}</p>
                <p>{{ ' Please follow the official format of the number.' | translate }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="bottom row">
          <div class="desc-holder col-6">
            <div class="input-primary textarea" appearance="outline">
              <mat-label>{{ 'Supplier Description' | translate }}</mat-label>
              <div class="wrp">
                <app-rs-rte
                  formControlName="description"
                  [c]="$any(form.controls['description'])"
                  [placeholder]="'Supplier Description' | translate"
                ></app-rs-rte>
              </div>
            </div>
          </div>
          <div class="identifier-type col-6">
            <mat-form-field class="input-primary" appearance="outline">
              <mat-label>{{ 'Supplier Identifier Type' | translate }}</mat-label>
              <input matInput formControlName="unique_identifier_type" placeholder="Supplier Identifier Type" />
            </mat-form-field>
            <div
              class="identifier-info-holder d-flex align-items-center justify-content-center cursor-pointer"
              (mouseenter)="identifierTypeInfo = true"
              (mouseleave)="identifierTypeInfo = false"
            >
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'info-circle'"></app-custom-icon>
              <div class="tooltip" *ngIf="identifierTypeInfo">
                <p>
                  {{
                    'Here you specify if the unique identifier of your company is a VAT number, Duns number, E.I.N number or other type.'
                      | translate
                  }}
                </p>
                <p class="example">{{ 'Example: VAT number' | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->

      <div *ngIf="show_buttons">
        <div class="create-supplier-actions" *ngIf="showButtons || form.dirty">
          <app-rounded-button
            color="primary"
            [button_text]="supplierData?.uuid ? 'Update Supplier' : 'Create Supplier'"
            [size]="'md'"
            [disabled]="supplierData?.uuid ? form && form.pristine : form && !form.valid && form.pristine"
            (click)="supplierData?.uuid ? updateSupplier() : createSupplier()"
          >
          </app-rounded-button>
          <app-rounded-button mat-dialog-close color="tetriary-link" [size]="'md'" [button_text]="'Discard'">
          </app-rounded-button>
        </div>
      </div>
    </div>
  </form>
</mat-card>
