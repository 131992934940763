import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '@env/environment';

@Component({
  selector: 'app-access-publication-dialog',
  templateUrl: './access-publication-dialog.component.html',
  styleUrl: './access-publication-dialog.component.scss',
})
export class AccessPublicationDialogComponent implements OnInit {
  protected url!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    public dialogRef: MatDialogRef<AccessPublicationDialogComponent>
  ) {}

  ngOnInit(): void {
    this.url = `${environment.respectCodeUrl}/product/${this.data}`;
  }

  goToLink() {
    window.open(this.url, '_blank');
  }
}
