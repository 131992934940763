<div class="header">
  <img
    (click)="navigateBack()"
    class="left-image cursor-pointer"
    src="../../../../assets/logos/backToSaaSWhite.svg"
    alt="Image"
  />
  <img class="right-image" src="../../../../assets/logos/byProductDNAWhite.svg" alt="Image" />
</div>
<div class="content">
  <mat-tab-group [selectedIndex]="getTabIndex(activeTab)">
    <!-- Service agreement tab -->
    <mat-tab [label]="'Service agreement' | translate">
      <app-service-agreement></app-service-agreement>
    </mat-tab>
    <!-- Privacy policy tab -->
    <mat-tab [label]="'Privacy policy' | translate">
      <app-privacy-policy></app-privacy-policy>
    </mat-tab>
    <!-- Acceptable use policy tab -->
    <mat-tab [label]="'Acceptable use policy' | translate">
      <app-acceptable-policy></app-acceptable-policy>
    </mat-tab>
    <!-- Data protection policy tab -->
    <mat-tab [label]="'Data protection policy' | translate">
      <app-data-protection-policy></app-data-protection-policy>
    </mat-tab>
    <!-- Cookies policy tab -->
    <mat-tab [label]="'Cookies policy' | translate">
      <app-cookies-policy></app-cookies-policy>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="footer">
  <div class="logo">
    <img src="../../../../assets/logos/LogoProductDNA.svg" alt="" />
  </div>
  <div class="text">
    <div class="info">
      <p class="">
        {{ 'Product DNA ' | translate }} <br />
        {{ '(Switzerland / HeadQuarters)' | translate }}
      </p>
      <p class="light">
        {{ 'Avenue from Lake Geneva 10 1005 Lausanne • Swiss +41 21 311 04 32' | translate }} <br />
        {{ 'info@productdna.com ' | translate }}
      </p>
    </div>

    <div class="us-number">
      <p>
        {{ 'Product DNA' }} <br />
        {{ '(USA / Canada)' }}
      </p>
      <p class="light">
        {{ '+1-305-850-3097' }} <br />
        {{ '+1-581-994-6900' }}
      </p>
    </div>

    <div class="europe-number">
      <p>
        {{ 'Product DNA' }} <br />
        {{ '(North Europe)' }}
      </p>
      <p class="light">{{ '+31 62 955 33 93' }}</p>
    </div>
  </div>

  <div class="horizontal-line"></div>

  <div class="links-solution-service">
    <div class="useful-links">
      <p>{{ 'Useful links' | translate }}</p>
      <div class="social">
        <img src="../../../../assets/images/linkedin.svg" alt="" />
        <img src="../../../../assets/images/facebook.svg" alt="" />
        <img src="../../../../assets/images/instagram.svg" alt="" />
      </div>
    </div>

    <div class="solutions">
      <p>{{ 'Solutions' | translate }}</p>
      <p class="light">
        {{ 'Product traceability Supply chain optimization Supplier' | translate }} <br />
        {{ 'communication' | translate }}
      </p>
    </div>

    <div class="services">
      <p>{{ 'Services' | translate }}</p>
      <p class="light">
        {{ 'Brand transparency Training' | translate }} <br />
        {{ 'and awareness Corporate social' | translate }} <br />
        {{ 'responsibility' | translate }}
      </p>
    </div>
  </div>

  <div class="horizontal-line m-24"></div>

  <div class="rights-reserved">
    <p class="light">{{ '@2020-2023 Product DNA ALL RIGHTS RESERVED' }}</p>
  </div>
</div>
