<h2 mat-dialog-title>{{ data.uuid ? 'Edit Supplier Site' : ('Create Supplier Site' | translate) }}</h2>
<div class="col-12 d-flex">
  <h6 class="mat-dialog-subtitle">
    {{ data.uuid ? 'Supplier site owned by: ' : ('Creating supplier site for:' | translate) }}
  </h6>
  <div class="ss-img-logo">
    <!-- <img *ngIf="!supplierLogo" class="avatar" src="{{ selectedSupplier?.logo }}" />
    <img *ngIf="supplierLogo" class="avatar" src="{{ supplierLogo }}" /> -->

    <!-- <div class="suplier-logo"> -->
    <app-file-upload
      [placeholder]="'Logo' | translate"
      [iconType]="'add_photo_alternate'"
      [imageUrl]="supplierLogo ? supplierLogo : selectedSupplier?.logo"
      [readonly]="true"
    ></app-file-upload>
    <!-- </div> -->
  </div>
  <div class="text">
    <h5 class="supplier">{{ selectedSupplier?.name }}</h5>
    <p class="l1">
      {{ selectedSupplier?.address ? selectedSupplier?.address + ',' : '' }}
      {{ selectedSupplier?.city ? selectedSupplier?.city + ',' : '' }} {{ selectedSupplier?.country }}
    </p>
  </div>
</div>
<div class="form-actions-holder">
  <mat-dialog-content class="mat-typography">
    <div class="create-factory-wraper">
      <form [formGroup]="form">
        <div class="input-holder">
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'Site Name' | translate }}</mat-label>
            <input matInput formControlName="name" placeholder="Site Name" required />
          </mat-form-field>
          <div class="paragraph">
            <p>
              {{
                'Site name could be any facility of the supplier: headquarters, factory, farm, field etc.' | translate
              }}
            </p>
          </div>
        </div>

        <div class="input-holder">
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'Country' | translate }}</mat-label>
            <input type="text" matInput formControlName="country" [matAutocomplete]="auto" required />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
                {{ country.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'Region' | translate }}</mat-label>
            <input matInput formControlName="region" placeholder="Region" />
          </mat-form-field>
        </div>

        <div class="input-holder">
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'City' | translate }}</mat-label>
            <input matInput formControlName="city" placeholder="City" />
          </mat-form-field>

          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'Address' | translate }}</mat-label>
            <input matInput formControlName="address" placeholder="Address" />
          </mat-form-field>
        </div>

        <div class="input-holder">
          <mat-radio-group formControlName="is_main_location">
            <mat-radio-button [value]="true" color="primary">{{
              'make this the main site' | translate
            }}</mat-radio-button>
          </mat-radio-group>
        </div>
      </form>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <app-rounded-button size="md" mat-dialog-close color="tetriary-link" button_text="{{ 'Cancel' | translate }}">
    </app-rounded-button>

    <app-rounded-button
      color="primary"
      mat-button
      mat-raised-button
      size="md"
      [disabled]="form && !form.valid"
      color="primary"
      (click)="addFactory()"
      button_text="{{ data.uuid ? 'Save Changes' : ('Create Supplier Site' | translate) }}"
    >
    </app-rounded-button>
  </mat-dialog-actions>
</div>
