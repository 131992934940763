import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { NotificationType } from '@app/core/constants';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { ISupplier, ISupplierResponse } from '@app/core/interface/suppliers.interface';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SuppliersService } from '@app/core/service/suppliers.service';
import { SharedModule } from '@app/shared/shared.module';
import { SupplierPublicationGroup } from './supplier-publication-setup.group';

@Component({
  selector: 'app-supplier-publication-setup',
  standalone: true,
  imports: [SharedModule, CommonModule],
  providers: [SupplierPublicationGroup],
  templateUrl: './supplier-publication-setup.component.html',
  styleUrl: './supplier-publication-setup.component.scss',
})
export class SupplierPublicationSetupComponent implements OnInit, OnChanges, OnDestroy {
  @Input() supplier!: ISupplierResponse | ISupplier;
  @Output() refreshSupplier = new EventEmitter<boolean>();

  form!: UntypedFormGroup;
  alreadySubmitted = false;
  readonly #supplierService = inject(SuppliersService);
  readonly #supplierFacade = inject(SuppliersFacade);
  readonly #snackBarService = inject(SnackbarService);
  initial = true;
  timeoutId?: NodeJS.Timeout;

  initialFormValue?: {
    selectInput: string;
    is_name_private: boolean;
    is_description_private: boolean;
    is_location_private: boolean;
    is_hidden: boolean;
  };
  private initialInputValue!: number;

  selectOptions = [
    { value: 4, expVal: 'street name, city name, region name, country name', viewValue: 'Address' },
    { value: 3, expVal: 'city name', viewValue: 'City' },
    { value: 2, expVal: 'region name', viewValue: 'Region' },
    { value: 1, expVal: 'country name', viewValue: 'Country' },
  ];

  selectedOption?: string;

  constructor(private _group: SupplierPublicationGroup) {
    this.form = this._group.publicationForm;
    this.isLocationPrivateSub();
    this.isConfidentionalSub();
  }
  ngOnDestroy(): void {
    this.timeoutId && clearTimeout(this.timeoutId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['supplier']) {
      this.initial = true;
      this.patchForm();
      this.initialFormValue = this.form.value;
      this.initialInputValue = this.form.controls['selectInput'].value?.value ?? '';
      this.timeoutId && clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.initial = false;
      }, 0);
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: BeforeUnloadEvent): void {
    if (this.form.dirty && !this.isFormUnchanged()) {
      $event.returnValue = true;
    }
  }

  isLocationPrivateSub(): void {
    this.form.get('is_location_private')?.valueChanges.subscribe((isHidden: boolean) => {
      const selectInputControl = this.form.get('selectInput');
      if (isHidden) {
        selectInputControl?.disable();
        selectInputControl?.setValue('');
      } else {
        selectInputControl?.enable();
        if (this.form.get('selectInput')?.value === '' && this.supplier?.geopositioning) {
          const val = this.selectOptions.find(option => option.value === this.supplier.geopositioning);
          selectInputControl?.setValue(val ?? this.selectOptions[4]);
        }
      }
    });
  }

  isConfidentionalSub(): void {
    this.form.get('is_hidden')?.valueChanges.subscribe((isConfidential: boolean) => {
      const controls = ['is_name_private', 'is_description_private', 'is_location_private'].map(control =>
        this.form.get(control)
      );
      if (isConfidential) {
        controls.forEach(control => {
          control?.setValue(true);
          control?.disable();
        });
      } else if (!this.initial) {
        controls.forEach(control => {
          control?.enable();
          control?.setValue(false);
        });
      }
    });
  }

  ngOnInit(): void {
    this.patchForm();
    this.initialFormValue = this.form.value;
    this.form.valueChanges.subscribe(() => {
      if (this.form.dirty && !this.isFormUnchanged() && !this.alreadySubmitted) {
        this.#supplierService.changesBehaviour.next(true);
      } else this.#supplierService.changesBehaviour.next(false);
    });
  }

  patchForm(): void {
    this.form.patchValue({
      is_name_private: this.supplier.is_name_private,
      is_hidden: this.supplier.is_hidden,
      is_location_private: this.supplier.is_location_private,
      is_description_private: this.supplier.is_description_private,
      selectInput: this.supplier.is_location_private
        ? null
        : this.selectOptions.find(row => row.value == this.supplier.geopositioning),
    });
  }

  onSubmit(): void {
    const payload = this.form.controls['selectInput'].value
      ? { ...this.form.getRawValue(), geopositioning: this.form.controls['selectInput'].value.value }
      : { ...this.form.getRawValue(), geopositioning: '' };
    this.#supplierFacade.updateSupplier$(this.supplier.uuid, payload).subscribe({
      next: () => {
        this.#supplierService.changesBehaviour.next(false);
        this.alreadySubmitted = true;
        this.refreshSupplier.emit(true);

        this.#snackBarService.openTypeSnackbar('Publication setup updated successfully', NotificationType.success);
      },
      error: () => {
        this.#snackBarService.openTypeSnackbar(
          'Error, please contact our support for further details',
          NotificationType.error
        );
      },
    });
  }

  isFormUnchanged(): boolean {
    return JSON.stringify(this.initialFormValue) === JSON.stringify(this.form.value);
  }

  onToggleChange(field: 'is_name_private' | 'is_description_private', event: MatSlideToggleChange): void {
    this.form.get(field)?.setValue(!event.checked);
    this.#supplierService.changesBehaviour.next(true);
  }
}
