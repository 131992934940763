import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Injectable } from '@angular/core';
import { MustMatch } from '@app/core/utils/must-match.validator';

@Injectable()
export class RegisterGroup {
  createAccountForm: UntypedFormGroup;
  createSuppAccountForm: UntypedFormGroup;
  completeProfileForm: UntypedFormGroup;
  createBrandForm: UntypedFormGroup;
  createProductForm: UntypedFormGroup;
  manageProfileForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.createAccountForm = this._fb.group(
      {
        email: [
          null,
          [
            Validators.required,
            Validators.email,
            Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$'),
          ],
        ],
        username: [null, [Validators.required]],
        password1: [null, [Validators.required, Validators.minLength(8)]],
        password2: [null, [Validators.required]],
        is_consent_apporved: [false, [Validators.requiredTrue]],
        chargebee_subscription_id: [null],
      },
      { validator: MustMatch('password1', 'password2') }
    );

    this.completeProfileForm = this._fb.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      function: [null],
      is_brand_admin: [true],
      phone: [null],
      avatar: [null],
    });

    this.createBrandForm = this._fb.group({
      name: [null, [Validators.required]],
      logo: [null],
      domain_activity: [null, [Validators.required]],
      // languages: [null, [Validators.required]],
      description: [null],
      traceability_commitment: [null],
    });

    this.createProductForm = this._fb.group({
      name: [null, [Validators.required]],
      image: [null],
      summary: [null],
      description: [null],
      reference: [null],
      product_group: [{ value: '', disabled: true }],
      custom_fields: this._fb.array([]),
    });

    this.createSuppAccountForm = this._fb.group(
      {
        email: [
          null,
          [
            Validators.required,
            Validators.email,
            Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$'),
          ],
        ],
        username: [null, [Validators.required]],
        password1: [null, [Validators.required, Validators.minLength(8)]],
        password2: [null, [Validators.required]],
        is_consent_apporved: [false, [Validators.requiredTrue]],
        uid: [null, [Validators.required]],
        token: [null, [Validators.required]],
      },
      { validator: MustMatch('password1', 'password2') }
    );

    this.manageProfileForm = this._fb.group({
      username: [null, [Validators.required]],
      first_name: [null],
      last_name: [null],
      avatar: [null],
    });
  }
}
