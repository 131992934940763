import { AfterViewInit, Component, ElementRef, OnDestroy, QueryList, signal, ViewChildren } from '@angular/core';
import { ITableOfContents } from './service-agreemant-interfaces';
import {
  DEFINITION_ARRAY,
  TABLE_OF_CONTENTS,
  PURPOSE_ARRAY,
  EFFECTIVE_DATE_ARRAY,
  SCOPE_OF_SERVICE_ARRAY,
  SERVICE_ACTIVATION_ARRAY,
  RIGHT_OF_ACCESS_ARRAY,
  CONDITIONS_OF_EXEC_ARRAY,
  CLIENT_DATA_ARRAY,
  SECURITY_OF_SERVICE_ARRAY,
  COMBATING_FRAUD_ARRAY,
  PRICES_INVOICES_ARRAY,
  PAYMENT_ARRAY,
  COOPERATION_ARRAY,
  INDEMNITY_ARRAY,
  TERMINATION_ARRAY,
  FORCE_MAJEURE_ARRAY,
  CONFIDENTIALITY_ARRAY,
  SUBCONTRACTING_ARRAY,
  ASSIGNMENT_ARRAY,
  REGULATIONS_ARRAY,
  MISCELLANEOUS_ARRAY,
  APPLICABLE_ARRAY,
} from './service-agreemant-constants';

@Component({
  selector: 'app-service-agreement',
  templateUrl: './service-agreement.component.html',
  styleUrl: './service-agreement.component.scss',
})
export class ServiceAgreementComponent implements AfterViewInit, OnDestroy {
  @ViewChildren('contentTable') contentItems!: QueryList<ElementRef>;

  protected tableOfContents: ITableOfContents[] = TABLE_OF_CONTENTS;

  protected definitionArray = DEFINITION_ARRAY;
  protected purposeArray = PURPOSE_ARRAY;
  protected effectiveDateArray = EFFECTIVE_DATE_ARRAY;
  protected scopeOfServiceArray = SCOPE_OF_SERVICE_ARRAY;
  protected serviceActivationArray = SERVICE_ACTIVATION_ARRAY;
  protected rightOfAccess = RIGHT_OF_ACCESS_ARRAY;
  protected conditionsOfExecutionArray = CONDITIONS_OF_EXEC_ARRAY;
  protected clientDataArray = CLIENT_DATA_ARRAY;
  protected securityOfServiceArray = SECURITY_OF_SERVICE_ARRAY;
  protected combatingFraudArray = COMBATING_FRAUD_ARRAY;
  protected pricesInvoicesArray = PRICES_INVOICES_ARRAY;
  protected paymentArray = PAYMENT_ARRAY;
  protected cooperationArray = COOPERATION_ARRAY;
  protected indemnityArray = INDEMNITY_ARRAY;
  protected terminationArray = TERMINATION_ARRAY;
  protected forceMajeureArray = FORCE_MAJEURE_ARRAY;
  protected confidentialityArray = CONFIDENTIALITY_ARRAY;
  protected subcontractingArray = SUBCONTRACTING_ARRAY;
  protected assignmentArray = ASSIGNMENT_ARRAY;
  protected regulationsArray = REGULATIONS_ARRAY;
  protected miscellaneousArray = MISCELLANEOUS_ARRAY;
  protected applicableArray = APPLICABLE_ARRAY;

  protected currentLazyLoadingTrigger = signal<string>('');
  protected isVisibleBackToTableButton = signal<boolean>(false);

  private observer!: IntersectionObserver;

  ngAfterViewInit(): void {
    this._initIntersectionObserver();
    this._observeElements();
  }

  ngOnDestroy(): void {
    this._disconnectObserver();
  }

  lazyLoadAndGoToViewPort(idVariable: string): void {
    this.currentLazyLoadingTrigger.set(idVariable);
    setTimeout(() => {
      const element = document.getElementById(idVariable);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 300);
  }

  hoverItem(index: number): void {
    this.tableOfContents[index].hovered = true;
  }

  unhoverItem(index: number): void {
    this.tableOfContents[index].hovered = false;
  }

  backToTable(): void {
    const element = document.getElementById('idContentTable');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  ////////////////////////////// PRIVATE METHODS //////////////////////////////

  private _disconnectObserver(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private _initIntersectionObserver(): void {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: 0.1, // Trigger callback when 10% of the element is in view
    };

    this.observer = new IntersectionObserver(this._handleIntersection.bind(this), options);
  }

  private _observeElements(): void {
    this.contentItems.forEach(item => {
      this.observer.observe(item.nativeElement);
    });
  }

  private _handleIntersection(entries: IntersectionObserverEntry[]): void {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.isVisibleBackToTableButton.set(false);
      } else {
        this.isVisibleBackToTableButton.set(true);
      }
    });
  }
}
