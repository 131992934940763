<div class="steps-phase">
  <h2 mat-dialog-title>{{ 'Manage phases' | translate }}</h2>

  <mat-dialog-content class="mat-typography">
    <div class="dialog-content-header">
      <div class="title d-flex">
        <p class="p1-strong">{{ 'Your phases' | translate }}</p>
        <app-custom-icon
          class="info-icon"
          icon_category="essential"
          icon_name="info-circle"
          [matTooltip]="
            'Here you can edit your default phases or create new, and also by selecting one of the phases you can then add some of your created steps into the phase.'
              | translate
          "
          matTooltipPosition="above"
          matTooltipClass="custom-tooltip-center"
          matTooltipVisibility="visible"
        ></app-custom-icon>
      </div>
      <app-rounded-button
        color="secondary"
        [button_text]="'Create New Phase'"
        [size]="'md'"
        [disabled]="!!selectedPhase"
        (click)="createNewEditPhase()"
      >
      </app-rounded-button>
    </div>
    <div class="phases-legend row">
      @for (phase of data.product_chain.type_steps; track $index) {
        <div class="phase col-3" [ngClass]="{ selected: phase.uuid === selectedPhase?.uuid }">
          @if (phase.uuid !== selectedPhase?.uuid && selectedPhase) {
            <div
              class="phase-click-container"
              [matTooltip]="tooltipDisabledPhase"
              matTooltipClass="custom-tooltip-center centering"
              [matTooltipPosition]="'above'"
            >
              <div class="phase-color disabled" [style.background]="phase.color">
                <app-custom-icon
                  *ngIf="phase.uuid === selectedPhase?.uuid"
                  [icon_category]="'essential'"
                  [icon_name]="'tick-circle'"
                ></app-custom-icon>
              </div>
              <h5 [ngClass]="{ disabled: phase.uuid !== selectedPhase?.uuid && selectedPhase }">
                {{ phase.name }}
              </h5>
            </div>
          } @else {
            <div
              class="phase-click-container cursor-pointer"
              matTooltip="Manage steps"
              matTooltipClass="custom-tooltip-center centering"
              [matTooltipPosition]="'above'"
              (click)="selectPhase(phase)"
            >
              <div class="phase-color" [style.background]="phase.color">
                <app-custom-icon
                  *ngIf="phase.uuid === selectedPhase?.uuid"
                  [icon_category]="'essential'"
                  [icon_name]="'tick-circle'"
                ></app-custom-icon>
              </div>
              <h5 [ngClass]="{ 'bold-text': phase.uuid === selectedPhase?.uuid && selectedPhase }">
                {{ phase.name.length > 16 ? (phase.name | slice: 0 : 16) + '...' : phase.name }}
              </h5>
            </div>
          }

          <div class="phase-icons-container">
            <app-custom-icon
              [icon_category]="'files'"
              [icon_name]="'edit'"
              (click)="createNewEditPhase(phase)"
              [disabled]="!!selectedPhase"
              [hidden]="phase.uuid === selectedPhase?.uuid"
              [matTooltip]="(!!selectedPhase ? tooltipDisabledPhase : 'Edit phase') | translate"
              matTooltipClass="custom-tooltip-center centering"
              [matTooltipPosition]="'above'"
              class="cursor-pointer"
            >
            </app-custom-icon>
            <app-custom-icon
              [icon_category]="'essential'"
              [icon_name]="'trash'"
              (click)="removeClassification(phase.uuid)"
              [disabled]="!!selectedPhase"
              [hidden]="phase.uuid === selectedPhase?.uuid"
              [matTooltip]="(!!selectedPhase ? tooltipDisabledPhase : 'Delete phase') | translate"
              matTooltipClass="custom-tooltip-center centering"
              [matTooltipPosition]="'above'"
              class="cursor-pointer"
            ></app-custom-icon>
          </div>
        </div>
      }
    </div>

    <div class="steps" *ngIf="selectedPhase">
      <div class="row">
        <p>{{ 'Choose which steps would you like to add to ' | translate }}{{ selectedPhase?.name }}</p>
        @for (step of data.product_chain.steps; track $index) {
          <div class="col-4 step-box">
            <mat-slide-toggle
              class="custom-slide-toggle reverse-label-toggle"
              (change)="updateStepList(step.uuid, $event.checked)"
              [color]="'primary'"
              [checked]="toggleHelper(step.uuid)"
            >
              {{ step.name }}
            </mat-slide-toggle>
            <div class="steps-divider" *ngIf="$index % 3 !== 2"></div>
          </div>
        }
      </div>
    </div>

    <mat-dialog-actions align="end">
      <div class="buttons-holder">
        <app-rounded-button color="primary" [button_text]="'Close Phases Management'" [size]="'md'" mat-dialog-close>
        </app-rounded-button>
      </div>
    </mat-dialog-actions>
  </mat-dialog-content>
</div>
