import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Injectable } from '@angular/core';

@Injectable()
export class DataPrivacyGroup {
  requestCreation: UntypedFormGroup;
  constructor(private _fb: UntypedFormBuilder) {
    this.requestCreation = this._fb.group({
      request_type: [null, [Validators.required]],
      additional_comment: [null, [Validators.required]],
    });
  }
}
