<div *ngIf="isVisibleBackToTopButton()" class="back-btn back-to-top-button cursor-pointer">
  <app-custom-icon
    id="back-btn"
    matTooltip="Back to top"
    [icon_category]="'arrows'"
    [icon_name]="'arrow-up-2'"
    (click)="backToTop()"
  >
  </app-custom-icon>
</div>

<div class="title-content" id="idAcceptablePolicy">
  <p class="title">Acceptable use policy</p>
</div>

<div class="content">
  <!-- Welcome to ProductDNA -->
  <p class="content-sub-title" #welcome>Welcome to ProductDNA</p>
  <div class="content-text">
    <p>
      PLEASE READ THE FOLLOWING ACCEPTABLE USE POLICY (“AUP”) CAREFULLY BEFORE ACCESSING, BROWSING OR USING PRODUCT
      DNA’S (hereinafter: “PDNA”)SERVICES.
    </p>
    <p>
      The AUP constitutes a legal contract between You and PDNA and governs Your use of PDNA’s Software-as-a-Service
      (SaaS), which interact with, or access, the Internet as well as of the related Websites controlled by PDNA (the
      “Services”). This Policy is in addition to any other terms and conditions under which PDNA provides Services to
      you.
    </p>
    <p>The AUP is effective at the earliest of:</p>
    <p>
      <b>(a) when you click to accept this AUP or</b>
    </p>
    <p>
      <b>(b) when you access or use PDNA’s Services.</b>
    </p>
    <p>If you do not accept this AUP, you may not use PDNA’s Services.</p>
  </div>
  <!-- Prohibited uses -->
  <p class="content-sub-title">Prohibited uses</p>
  <div class="content-text">
    <p>
      This Section describes ways that You are not authorized to use PDNA’s Services. The examples listed below are not
      exhaustive. Prohibited uses and activities include, without limitation, any use of the Services in a manner that,
      in PDNA’s reasonable judgment, involves, facilitates, or attempts any of the following:
    </p>
    <p>
      • Violates any local, state, national, foreign, or international statute, regulation, rule, order, treaty, or
      other law (each a "Law");
    </p>
    <p>
      • Is abusive, deceptive, pornographic, obscene, defamatory, slanderous, offensive, or otherwise inappropriate;
    </p>
    <p>
      • Violates or otherwise encroaches on the rights of others, including, but not limited to, infringing or
      misappropriating any intellectual property or proprietary right of another;
    </p>
    <p>• Advocates or induces illegal activity;</p>
    <p>• Stalks, harasses, or harms anyone;</p>
    <p>• Impersonates any person or entity or otherwise misrepresents Your affiliation with a person or entity;</p>
    <p>
      • Modifies, alters, tampers with, repairs, or otherwise creates derivative works of any software included in the
      Services (except to the extent software included in the Services is provided to Customer under a separate license
      that expressly permits the creation of derivative works);
    </p>
    <p>• Reverse engineers, disassembles, or decompiles the Services or software included in the Services;</p>
    <p>• Interferes with or disrupts any Services or servers or networks connected to the Services;</p>
    <p>
      • Uses any high-volume automated means (including robots, spiders, scripts or similar data gathering or extraction
      methods) to access any Services or any other accounts, computer systems, or networks connected to the Services
      (each a "System");
    </p>
    <p>• Attempts to gain unauthorized access to any portion of the Services or any System, whether through hacking;</p>
    <p>• Password mining, or any other means;</p>
    <p>• Violates the security or integrity of a System.</p>
  </div>
  <!-- Your Submission(s) -->
  <p class="content-sub-title">Your Submission(s)</p>
  <div class="content-text">
    <p>
      PDNA’s Services may permit You to submit information, data, text, software, music, sounds, photographs, graphics,
      videos or other materials (each, a "User Submission"). You agree that You are solely responsible for all of Your
      User Submissions and that any such User Submission is considered both non-confidential and non-proprietary.
      Further, PDNA does not guarantee that You will have any recourse through PDNA or any third party to edit or delete
      any User Submission You have submitted, nor is PDNA required to use Your User Submission.
    </p>
    <p>
      <b>BY SUBMITTING ANY USER SUBMISSION, YOU REPRESENT AND WARRANT THAT:</b>
    </p>
    <p>• You are at least 13 years old;</p>
    <P>
      • You own all rights in Your User Submissions (including, without limitation, all rights to the text, code, audio,
      video, or digital recording and the performance contained in Your User Submissions) or, alternatively, You have
      acquired all necessary rights in Your User Submissions to enable You to grant to PDNA the rights in Your User
      Submissions;
    </P>
    <P>
      • You have paid and will pay in full all license fees, clearance fees, and other financial obligations, of any
      kind, arising from any use or commercial exploitation of Your User Submissions;
    </P>
    <P>
      • You are the individual pictured and/or heard in Your User Submissions or, alternatively, You have obtained
      permission from each person (including consent from parents or guardians for any individual under the age of
      eighteen (18)) who appears and/or is heard in Your User Submissions to grant the rights to PDNA;
    </P>
    <P>
      • Your User Submissions do not infringe the copyright, trademark, patent, trade secret, or other intellectual
      property rights, privacy rights, rules of social media sites, or any other legal or moral rights of any third
      party;
    </P>
    <P> • You voluntarily agree to waive all "moral rights" that You may have in Your User Submission; </P>
    <P>
      • Any information contained in Your User Submission is not known by You to be false, inaccurate, or misleading;
    </P>
    <P>
      • Your User Submission does not violate any Law (including, but not limited to, those governing export control,
      consumer protection, unfair competition, anti-discrimination or false advertising);
    </P>
    <P>
      • Your User Submission is not, and may not reasonably be considered to be, defamatory, libelous, hateful,
      racially, ethnically, religiously or otherwise biased or offensive, threatening, or harassing to any individual,
      partnership or corporation, vulgar, pornographic, obscene or invasive of another's privacy;
    </P>
    <P>
      • You were not and will not be compensated or granted any consideration by any third party for Your User
      Submission;
    </P>
    <P>
      • Your User Submission does not incorporate materials from a third-party website, or addresses, email addresses,
      contact information or phone numbers (other than Your own);
    </P>
    <P>
      • Your User Submission does not contain any viruses, worms, spyware, adware, or other potentially damaging
      programs or files;
    </P>
    <P>
      • Your User Submission does not contain any information that You consider confidential, proprietary or personal;
    </P>
    <P>
      • Your User Submission does not contain or constitute any unsolicited or unauthorized advertising, promotional
      materials, junk mail, spam, chain letters, pyramid schemes, or any other form of solicitation.
    </P>
    <P>
      PDNA may, but is not obligated to, pre-screen User Submissions or monitor any area of PDNA Services through which
      User Submissions may be submitted. PDNA is not required to host, display or distribute any User Submissions on or
      through PDNA Services and may remove at any time or refuse any User Submissions for any reason. PDNA is not
      responsible for any loss, theft or damage of any kind to any User Submissions. Further, You agree that PDNA may
      freely disclose Your User Submission to any third party absent any obligation of confidence on the part of the
      recipient.
    </P>
    <P>
      Furthermore, by submitting a User Submission, You grant to PDNA an irrevocable, perpetual, transferable, non-
      exclusive, fully-paid, worldwide, royalty-free license (sublicensable through multiple tiers) to:
    </P>
    <P>
      • Use, distribute, reproduce, modify, adapt, make derivative works, publish, translate, publicly perform and
      publicly display Your User Submissions (or any modification thereto), in whole or in part, in any format or medium
      now known or later developed;
    </P>
    <P>
      • Make, have made, use, sell, offer for sale and import Your User Submissions (or any modification thereto), in
      whole or in part, for any purpose;
    </P>
    <P>
      • Use (and permit others to use) Your User Submission in any manner and for any purpose (including, without
      limitation, commercial purposes) that PDNA deems appropriate in its sole discretion (including, without
      limitation, to incorporate Your User Submission or any modification there to, in whole or in part, into any
      technology, product or service);
    </P>
    <P>
      • Display advertisements in connection with Your User Submissions and to use Your User Submissions for advertising
      and promotional purposes.
    </P>
  </div>
  <!-- Monitoring and Enforcement -->
  <p class="content-sub-title">Monitoring and Enforcement</p>
  <div class="content-text">
    <p>While not obligated to perform investigations, PDNA may:</p>
    <p>• Investigate violations of this AUP or misuse of any Services;</p>
    <p>
      • Investigate and help prevent security threats, fraud, or other illegal, malicious, or inappropriate activity;
    </p>
    <p>
      • Remove, disable access to, or modify any content or resource that PDNA believes violates this AUP or any other
      agreement PDNA has with You; or
    </p>
    <p>
      • Suspend or terminate provision of Services to You for uses that violate this AUP or any other agreement PDNA has
      with You.
    </p>
    <p>
      It is up to PDNA to decide whether any User Submission or Your use of the Services is prohibited. All User
      Submissions that are provided to PDNA or actions that are performed via Your account, whether provided or
      performed by Yourself or someone involved in Your business (such as Your employees, contractors or customers and
      end users) are Your sole responsibility.
    </p>
    <p>
      PDNA may report any activity that it suspects violates any law or regulation to appropriate law enforcement
      officials, regulators, or other appropriate third parties. PDNA's reporting may include disclosing appropriate
      information that You may have provided. PDNA also may cooperate with appropriate law enforcement agencies,
      regulators, or other appropriate third parties to help with the investigation and prosecution of illegal conduct
      by providing network and systems information related to alleged violations of this AUP.
    </p>
  </div>
  <!-- Report Violations -->
  <p class="content-sub-title">Report Violations</p>
  <div class="content-text">
    <p>You can report abuse of this AUP to {{ infoEmail }}</p>
  </div>
  <!-- Intellectual Property Rights -->
  <p class="content-sub-title">Intellectual Property Rights</p>
  <div class="content-text">
    <p>
      PDNA respects the intellectual property rights of others, and we ask You to do the same. PDNA may, in appropriate
      circumstances and at its discretion, terminate access to PDNA Services for users who infringe the intellectual
      property rights of PDNA or others.
    </p>
  </div>
  <!-- Indemnification -->
  <p class="content-sub-title">Indemnification</p>
  <div class="content-text">
    <p>
      You agree to indemnify, defend and hold harmless PDNA from and against any and all claims, liabilities, damages,
      losses, costs, expenses or fees (including reasonable legal fees) that such parties may incur as a result of or
      arising from Your (or anyone using Your account) violation of this AUP. PDNA reserves the right to assume the
      exclusive defense and control of any matter subject to indemnification by You and, in such case, You agree to
      cooperate with PDNA’s defense of such claim.
    </p>
  </div>
  <!-- Governing Law -->
  <p class="content-sub-title">Governing Law</p>
  <div class="content-text">
    <p>
      These AUP shall be governed and construed exclusively by the laws of Switzerland, excluding its conflict of laws
      provisions. The parties submit to the jurisdiction of the Courts of Lausanne, Switzerland for the resolution of
      any disputes that may arise out of this Agreement, an appeal before the Swiss Supreme Court being reserved.
    </p>
  </div>
  <!-- Modifications -->
  <p class="content-sub-title">Modifications</p>
  <div class="content-text">
    <p>
      PDNA may make reasonable modifications to this Policy from time to time by posting a new version of this document
      on the PDNA website(s) at the current URL. Revisions are effective immediately upon posting. Accordingly, we
      recommend that you visit PDNA’s website regularly to ensure that your activities conform with the most recent
      version. Continued use of PDNA Services following any such changes shall indicate Your acknowledgement of such
      changes and agreement to be bound by the terms and conditions of such changes.
    </p>
  </div>
  <!-- Contact Us -->
  <p class="content-sub-title">Contact Us</p>
  <div class="content-text">
    <p>
      Questions about this AUP (e.g., whether any contemplated use is permitted) should be directed to {{ infoEmail }}.
    </p>
  </div>
</div>
