<div class="wrapper">
  <h2 mat-dialog-title>{{ id ? 'Edit Certificate Type' : ('Create Certificate Type' | translate) }}</h2>
  <h6>{{ 'Certification Details' | translate }}</h6>

  <form [formGroup]="form">
    <div class="input-holder">
      <mat-form-field class="input-primary" appearance="outline">
        <mat-label>{{ 'Certificate Type Name' | translate }}</mat-label>
        <input matInput placeholder="Certificate Type Name" formControlName="name" [readonly]="readonly" />
        <mat-error *ngIf="form.controls['name'].errors?.['maxlength']">{{
          'Ensure this field has no more than 30 characters.' | translate
        }}</mat-error>
      </mat-form-field>

      <mat-form-field class="input-primary" appearance="outline">
        <mat-label>{{ 'Choose Category' | translate }}</mat-label>
        <mat-select
          panelClass="dropdown-primary"
          formControlName="category"
          [value]="form.controls['category'].value"
          *ngIf="!readonly"
        >
          <mat-option *ngFor="let category of categories" [value]="category.uuid" placeholder="category">
            {{ category.name }}
          </mat-option>
        </mat-select>
        <input matInput [value]="categoryName" *ngIf="readonly" readonly />
      </mat-form-field>
    </div>

    <div class="input-holder">
      <div class="wrap-field">
        <div class="input-primary textarea" appearance="outline">
          <mat-label>{{ 'Certificate Summary' | translate }}</mat-label>
          <div class="wrp">
            <app-rs-rte
              *ngIf="!readonly"
              formControlName="summary"
              [c]="$any(form.controls['summary'])"
              [placeholder]="'Certificate Summary' | translate"
            ></app-rs-rte>
            <app-view-rich-text *ngIf="readonly" [content]="form.controls['summary'].value"></app-view-rich-text>
          </div>
        </div>
      </div>
      <div class="wrap-field">
        <div class="input-primary textarea" appearance="outline">
          <mat-label>{{ 'Certificate Description' | translate }}</mat-label>
          <div class="wrp">
            <app-rs-rte
              *ngIf="!readonly"
              formControlName="description"
              [c]="$any(form.controls['description'])"
              [placeholder]="'Certificate Description' | translate"
            ></app-rs-rte>
            <app-view-rich-text *ngIf="readonly" [content]="form.controls['description'].value"></app-view-rich-text>
          </div>
        </div>
      </div>
    </div>

    <div class="input-holder">
      <mat-form-field class="input-primary" appearance="outline">
        <mat-label>{{ 'External link' | translate }}</mat-label>
        <input matInput formControlName="external_url" placeholder="Enter External Link" [readonly]="readonly" />
      </mat-form-field>
      <mat-form-field class="input-primary" appearance="outline">
        <mat-label>{{ 'Choose Area' | translate }}</mat-label>
        <mat-select
          panelClass="dropdown-primary"
          *ngIf="!readonly"
          formControlName="domain"
          [value]="form.controls['domain'].value"
        >
          <mat-option *ngFor="let domain of domains" [value]="domain.uuid" placeholder="domain">
            {{ domain.name }}
          </mat-option>
        </mat-select>
        <input matInput [value]="domainName" *ngIf="readonly" readonly />
      </mat-form-field>
    </div>
  </form>

  <div class="h-line">
    <div class="horizontal-line mb-2"></div>
  </div>

  <div class="certificate-logo">
    <h6>{{ 'Upload Certificate Logo' | translate }}</h6>
    <app-file-upload
      *ngIf="!readonly"
      class="app-file-upload"
      placeholder="{{ 'Upload Picture' | translate }}"
      [iconType]="'add_photo_alternate'"
      [uploadImageText]="'Please upload the logo in the required format' | translate"
      [title]="'Edit Certificate'"
      (fileData)="saveImageData($event)"
      [imageUrl]="form.controls['image'].value"
      [status]="form.controls['image'].status"
    >
    </app-file-upload>
    <img [src]="form.controls['image'].value" alt="Image" *ngIf="readonly" />
  </div>

  <mat-dialog-actions align="end">
    <app-rounded-button mat-dialog-close color="tetriary-link" [size]="'md'" [button_text]="'Cancel'">
    </app-rounded-button>
    <app-rounded-button
      color="primary"
      [size]="'md'"
      [button_text]="id ? 'Save Change' : ('Create Certificate' | translate)"
      *ngIf="!readonly"
      [disabled]="form && (form.invalid || form.pristine)"
      (click)="submit()"
    >
      {{ mode | translate }}>
    </app-rounded-button>
  </mat-dialog-actions>
</div>
