import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilePreview, Step } from '@app/core/interface/preview_publicaton.interface';
import { SharedModule } from '@app/shared/shared.module';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';
import { VideoDialogComponent } from '../video-dialog/video-dialog.component';

@Component({
  standalone: true,
  imports: [SharedModule, CommonModule],
  selector: 'app-step-info',
  templateUrl: './step-info.component.html',
  styleUrls: ['./step-info.component.scss'],
})
export class StepInfoComponent implements OnChanges {
  @Input() data!: Step;
  images?: FilePreview[];
  videos?: FilePreview[];

  constructor(private dialog: MatDialog) {}
  ngOnChanges(): void {
    this.images = this.data.medias?.filter(el => el.mimetype?.includes('image'));
    this.videos = this.data.medias?.filter(el => el.mimetype?.includes('video'));
  }

  slideConfig = { slidesToShow: 3, slidesToScroll: 2 };
  slideConfigVideo = { slidesToShow: this.videos?.length && this.videos?.length == 1 ? 1 : 3, slidesToScroll: 2 };

  onImageDialog(imgUrl: FilePreview[], slide: FilePreview) {
    const sortedImages = this.sortImgs([...imgUrl], slide);
    const innerWidth = window.innerWidth;

    if (innerWidth <= 450) {
      this.dialog.open(ImageDialogComponent, {
        data: sortedImages,
        width: 'max-content',
        height: 'fit-content',
        panelClass: 'step-info-dialog',
        maxWidth: innerWidth - 20 + 'px',
      });
    } else {
      this.dialog.open(ImageDialogComponent, {
        data: sortedImages,
        width: 'fit-content',
        height: 'fit-content',
        panelClass: 'step-info-dialog',
      });
    }
  }

  sortImgs(items: FilePreview[], slide: FilePreview) {
    const indexToMove = items.findIndex(item => item.file === slide.file);
    if (indexToMove !== -1 && indexToMove !== 0) {
      const itemToMove = items.splice(indexToMove, 1)[0]; // Remove the item from the array
      items.unshift(itemToMove);
    }

    return items;
  }

  onVideoDialog(videoUrl: string) {
    this.dialog.open(VideoDialogComponent, {
      data: videoUrl,
      width: 'fit-content',
      height: 'fit-content',
      panelClass: 'step-info-dialog',
    });
  }
}
