import { ITableOfContents } from './service-agreemant-interfaces';

const TABLE_OF_CONTENTS: ITableOfContents[] = [
  {
    order: 1,
    title: 'Definitions',
    id: 'idDefinition',
    hovered: false,
  },
  {
    order: 2,
    title: 'Purpose',
    id: 'idPurpose',
    hovered: false,
  },
  {
    order: 3,
    title: 'Effective date – term',
    id: 'idEffectiveDate',
    hovered: false,
  },
  {
    order: 4,
    title: 'Scope of the service',
    id: 'idScopeOfTheService',
    hovered: false,
  },
  {
    order: 5,
    title: 'Service activatione',
    id: 'idServiceActivatione',
    hovered: false,
  },
  {
    order: 6,
    title: 'Right of access to the service',
    id: 'idRightOfAccess',
    hovered: false,
  },
  {
    order: 7,
    title: 'Conditions of execution of service',
    id: 'idConditionsOfExecution',
    hovered: false,
  },
  {
    order: 8,
    title: 'Client data',
    id: 'idClientData',
    hovered: false,
  },
  {
    order: 9,
    title: 'Security of the service',
    id: 'idSecurityofservice',
    hovered: false,
  },
  {
    order: 10,
    title: 'Combating fraud',
    id: 'idCombatingfraud',
    hovered: false,
  },
  {
    order: 11,
    title: 'Prices and invoices',
    id: 'idPricesInvoices',
    hovered: false,
  },
  {
    order: 12,
    title: 'Payment',
    id: 'idPayment',
    hovered: false,
  },
  {
    order: 13,
    title: 'Cooperation',
    id: 'idCooperation',
    hovered: false,
  },
  {
    order: 14,
    title: 'Indemnity-liability-insurance',
    id: 'idIndemnity',
    hovered: false,
  },
  {
    order: 15,
    title: 'Termination',
    id: 'idTermination',
    hovered: false,
  },
  {
    order: 16,
    title: 'Force Majeure',
    id: 'idForce',
    hovered: false,
  },
  {
    order: 17,
    title: 'Confidentiality',
    id: 'idConfidentiality',
    hovered: false,
  },
  {
    order: 18,
    title: 'Subcontracting',
    id: 'idSubcontracting',
    hovered: false,
  },
  {
    order: 19,
    title: 'Assignment',
    id: 'idAssignment',
    hovered: false,
  },
  {
    order: 20,
    title: 'Regulations',
    id: 'idRegulations',
    hovered: false,
  },
  {
    order: 21,
    title: 'Miscellaneous provisions',
    id: 'idMiscellaneous',
    hovered: false,
  },
  {
    order: 22,
    title: 'Applicable law and jurisdiction',
    id: 'idApplicable',
    hovered: false,
  },
];
const DEFINITION_ARRAY: string[] = [
  'idDefinition',
  'idPurpose',
  'idEffectiveDate',
  'idScopeOfTheService',
  'idServiceActivatione',
  'idRightOfAccess',
  'idConditionsOfExecution',
  'idClientData',
  'idSecurityofservice',
  'idCombatingfraud',
  'idPricesInvoices',
  'idPayment',
  'idCooperation',
  'idIndemnity',
  'idTermination',
  'idForce',
  'idConfidentiality',
  'idSubcontracting',
  'idAssignment',
  'idRegulations',
  'idMiscellaneous',
  'idApplicable',
];
const PURPOSE_ARRAY: string[] = [
  'idPurpose',
  'idEffectiveDate',
  'idScopeOfTheService',
  'idServiceActivatione',
  'idRightOfAccess',
  'idConditionsOfExecution',
  'idClientData',
  'idSecurityofservice',
  'idCombatingfraud',
  'idPricesInvoices',
  'idPayment',
  'idCooperation',
  'idIndemnity',
  'idTermination',
  'idForce',
  'idConfidentiality',
  'idSubcontracting',
  'idAssignment',
  'idRegulations',
  'idMiscellaneous',
  'idApplicable',
];
const EFFECTIVE_DATE_ARRAY: string[] = [
  'idEffectiveDate',
  'idScopeOfTheService',
  'idServiceActivatione',
  'idRightOfAccess',
  'idConditionsOfExecution',
  'idClientData',
  'idSecurityofservice',
  'idCombatingfraud',
  'idPricesInvoices',
  'idPayment',
  'idCooperation',
  'idIndemnity',
  'idTermination',
  'idForce',
  'idConfidentiality',
  'idSubcontracting',
  'idAssignment',
  'idRegulations',
  'idMiscellaneous',
  'idApplicable',
];
const SCOPE_OF_SERVICE_ARRAY: string[] = [
  'idScopeOfTheService',
  'idServiceActivatione',
  'idRightOfAccess',
  'idConditionsOfExecution',
  'idClientData',
  'idSecurityofservice',
  'idCombatingfraud',
  'idPricesInvoices',
  'idPayment',
  'idCooperation',
  'idIndemnity',
  'idTermination',
  'idForce',
  'idConfidentiality',
  'idSubcontracting',
  'idAssignment',
  'idRegulations',
  'idMiscellaneous',
  'idApplicable',
];
const SERVICE_ACTIVATION_ARRAY: string[] = [
  'idServiceActivatione',
  'idRightOfAccess',
  'idConditionsOfExecution',
  'idClientData',
  'idSecurityofservice',
  'idCombatingfraud',
  'idPricesInvoices',
  'idPayment',
  'idCooperation',
  'idIndemnity',
  'idTermination',
  'idForce',
  'idConfidentiality',
  'idSubcontracting',
  'idAssignment',
  'idRegulations',
  'idMiscellaneous',
  'idApplicable',
];
const RIGHT_OF_ACCESS_ARRAY: string[] = [
  'idRightOfAccess',
  'idConditionsOfExecution',
  'idClientData',
  'idSecurityofservice',
  'idCombatingfraud',
  'idPricesInvoices',
  'idPayment',
  'idCooperation',
  'idIndemnity',
  'idTermination',
  'idForce',
  'idConfidentiality',
  'idSubcontracting',
  'idAssignment',
  'idRegulations',
  'idMiscellaneous',
  'idApplicable',
];
const CONDITIONS_OF_EXEC_ARRAY: string[] = [
  'idConditionsOfExecution',
  'idClientData',
  'idSecurityofservice',
  'idCombatingfraud',
  'idPricesInvoices',
  'idPayment',
  'idCooperation',
  'idIndemnity',
  'idTermination',
  'idForce',
  'idConfidentiality',
  'idSubcontracting',
  'idAssignment',
  'idRegulations',
  'idMiscellaneous',
  'idApplicable',
];
const CLIENT_DATA_ARRAY: string[] = [
  'idClientData',
  'idSecurityofservice',
  'idCombatingfraud',
  'idPricesInvoices',
  'idPayment',
  'idCooperation',
  'idIndemnity',
  'idTermination',
  'idForce',
  'idConfidentiality',
  'idSubcontracting',
  'idAssignment',
  'idRegulations',
  'idMiscellaneous',
  'idApplicable',
];
const SECURITY_OF_SERVICE_ARRAY: string[] = [
  'idSecurityofservice',
  'idCombatingfraud',
  'idPricesInvoices',
  'idPayment',
  'idCooperation',
  'idIndemnity',
  'idTermination',
  'idForce',
  'idConfidentiality',
  'idSubcontracting',
  'idAssignment',
  'idRegulations',
  'idMiscellaneous',
  'idApplicable',
];
const COMBATING_FRAUD_ARRAY: string[] = [
  'idCombatingfraud',
  'idPricesInvoices',
  'idPayment',
  'idCooperation',
  'idIndemnity',
  'idTermination',
  'idForce',
  'idConfidentiality',
  'idSubcontracting',
  'idAssignment',
  'idRegulations',
  'idMiscellaneous',
  'idApplicable',
];
const PRICES_INVOICES_ARRAY: string[] = [
  'idPricesInvoices',
  'idPayment',
  'idCooperation',
  'idIndemnity',
  'idTermination',
  'idForce',
  'idConfidentiality',
  'idSubcontracting',
  'idAssignment',
  'idRegulations',
  'idMiscellaneous',
  'idApplicable',
];
const PAYMENT_ARRAY: string[] = [
  'idPayment',
  'idCooperation',
  'idIndemnity',
  'idTermination',
  'idForce',
  'idConfidentiality',
  'idSubcontracting',
  'idAssignment',
  'idRegulations',
  'idMiscellaneous',
  'idApplicable',
];
const COOPERATION_ARRAY: string[] = [
  'idCooperation',
  'idIndemnity',
  'idTermination',
  'idForce',
  'idConfidentiality',
  'idSubcontracting',
  'idAssignment',
  'idRegulations',
  'idMiscellaneous',
  'idApplicable',
];
const INDEMNITY_ARRAY: string[] = [
  'idIndemnity',
  'idTermination',
  'idForce',
  'idConfidentiality',
  'idSubcontracting',
  'idAssignment',
  'idRegulations',
  'idMiscellaneous',
  'idApplicable',
];
const TERMINATION_ARRAY: string[] = [
  'idTermination',
  'idForce',
  'idConfidentiality',
  'idSubcontracting',
  'idAssignment',
  'idRegulations',
  'idMiscellaneous',
  'idApplicable',
];
const FORCE_MAJEURE_ARRAY: string[] = [
  'idForce',
  'idConfidentiality',
  'idSubcontracting',
  'idAssignment',
  'idRegulations',
  'idMiscellaneous',
  'idApplicable',
];
const CONFIDENTIALITY_ARRAY: string[] = [
  'idConfidentiality',
  'idSubcontracting',
  'idAssignment',
  'idRegulations',
  'idMiscellaneous',
  'idApplicable',
];
const SUBCONTRACTING_ARRAY: string[] = [
  'idSubcontracting',
  'idAssignment',
  'idRegulations',
  'idMiscellaneous',
  'idApplicable',
];
const ASSIGNMENT_ARRAY: string[] = ['idAssignment', 'idRegulations', 'idMiscellaneous', 'idApplicable'];
const REGULATIONS_ARRAY: string[] = ['idRegulations', 'idMiscellaneous', 'idApplicable'];
const MISCELLANEOUS_ARRAY: string[] = ['idMiscellaneous', 'idApplicable'];
const APPLICABLE_ARRAY: string[] = ['idApplicable'];

export {
  TABLE_OF_CONTENTS,
  DEFINITION_ARRAY,
  PURPOSE_ARRAY,
  EFFECTIVE_DATE_ARRAY,
  SCOPE_OF_SERVICE_ARRAY,
  SERVICE_ACTIVATION_ARRAY,
  RIGHT_OF_ACCESS_ARRAY,
  CONDITIONS_OF_EXEC_ARRAY,
  CLIENT_DATA_ARRAY,
  SECURITY_OF_SERVICE_ARRAY,
  COMBATING_FRAUD_ARRAY,
  PRICES_INVOICES_ARRAY,
  PAYMENT_ARRAY,
  COOPERATION_ARRAY,
  INDEMNITY_ARRAY,
  TERMINATION_ARRAY,
  FORCE_MAJEURE_ARRAY,
  CONFIDENTIALITY_ARRAY,
  SUBCONTRACTING_ARRAY,
  ASSIGNMENT_ARRAY,
  REGULATIONS_ARRAY,
  MISCELLANEOUS_ARRAY,
  APPLICABLE_ARRAY,
};
