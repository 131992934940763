<div class="wraper">
  <app-chain-setup-header [isPublication]="true" [icon]="'saved-changes'"></app-chain-setup-header>
  <div class="sub-header">
    <div class="icon">
      <app-custom-icon [icon_category]="'essential'" [icon_name]="'publication'"></app-custom-icon>
    </div>
    <h2>{{ 'Publication tool' | translate }}</h2>
  </div>
  <div class="content">
    <div class="preview-details">
      <div class="preview-card">
        <img
          class="logo"
          src="{{ selectedBrand?.logo ? selectedBrand.logo : '../../../assets/images/upload-placeholder-blue.svg' }}"
        />

        <app-rounded-button
          color="secondary"
          [button_text]="'Preview brand page'"
          [size]="'md'"
          (click)="previewBrand()"
        ></app-rounded-button>
      </div>
      <div class="preview-card">
        <img
          class="logo"
          src="{{ product?.image ? product.image : '../../../assets/images/upload-placeholder-blue.svg' }}"
        />

        <div>
          <h4>{{ product?.name }}</h4>
          <p class="product-description" *ngIf="product?.description">
            <app-view-rich-text [content]="product?.description ?? ''"></app-view-rich-text>
          </p>
        </div>
        <app-rounded-button
          color="secondary"
          (click)="previewProduct()"
          [button_text]="'Preview product view'"
          [size]="'md'"
        >
        </app-rounded-button>
      </div>
    </div>
    <div class="preview-chain">
      <h5>{{ 'Chain view' | translate }}</h5>
      <app-chain-overview [publicationData]="publicationData"></app-chain-overview>
    </div>
    <div class="modify-chain">
      <div class="modify-card">
        <div class="horizontal-line"></div>
        <div class="icon">
          <app-custom-icon [icon_category]="'settings'" [icon_name]="'setting-4'"></app-custom-icon>
        </div>
        <div>
          <h4>{{ 'Modify your supply chain' | translate }}</h4>
          <p>
            {{
              'If you wish to further modify your chain before publishing, we recommend you to continue with clicking on the “Setup your chain” button bellow.'
                | translate
            }}
          </p>
          <app-rounded-button color="success" [button_text]="'Setup Your Chain'" [size]="'md'" (click)="setupChain()">
          </app-rounded-button>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <app-rounded-button
      color="secondary"
      [button_text]="'Preview Publication'"
      [icon_category]="'security'"
      [icon_name]="'eye'"
      [size]="'md'"
      [icon_right]="true"
      (click)="previewPublication()"
    >
    </app-rounded-button>
    <app-rounded-button
      class="publish-button"
      color="primary"
      [button_text]="'Publish your chain'"
      [icon_category]="'location'"
      [icon_name]="'routing-2'"
      [size]="'md'"
      [icon_right]="true"
      (click)="publish()"
    >
    </app-rounded-button>
  </div>
</div>
