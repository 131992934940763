import { AddStepsPhaseComponent } from './add-steps-phase/add-steps-phase.component';
import { AngularMaterialModule } from '@app/material.module';
import { ChainSidenavComponent } from './chain-sidenav/chain-sidenav.component';
import { CommonModule, DatePipe } from '@angular/common';
import { CreateClusterComponent } from './create-cluster/create-cluster.component';
import { DocumentsCertificatesComponent } from './documents-certificates/documents-certificates.component';
import { DoughnutComponent } from './doughnut-chart/doughnut.component';
import { FilesMeadiasLinksFacade } from '@app/core/facade/files-medias-links.facade';
import { NgChartsModule } from 'ng2-charts';
import { NgModule } from '@angular/core';
import { NodeContainerComponent } from './node-container/node-container.component';
import { NodeService } from '@app/core/service/node.service';
import { PhasesFacade } from '@app/core/facade/phases.facade';
import { ProductChainComponent } from './product-chain.component';
import { ProductChainRouterModule } from './product-chain-router.module';
import { PublishTutorialComponent } from './publish-tutorial/publish-tutorial.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { StepCompleteComponent } from './step-batch/step-batch.component';
import { StepDialogComponent } from './step-dialog/step-dialog.component';
import { StepUpdateComponent } from './step-update/step-update.component';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { TranslateModule } from '@ngx-translate/core';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { IngredientDetailsFormComponent } from './step-update/ingredient-details-form/ingredient-details-form.component';
import { StepDetailsDialogComponent } from './step-details-dialog/step-details-dialog.component';
import { SupplierIdentificationDialogComponent } from './supplier-identification-dialog/supplier-identification-dialog.component';
import { SupplierSiteMappingDialogComponent } from './supplier-site-mapping-dialog/supplier-site-mapping-dialog.component';
import { NotifyDialogComponent } from './notify-dialog/notify-dialog.component';
import { PublicationModule } from '../publication/publication.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { CustomPublicationsFacade } from '../publication/custom-publications/custom-publications.facade';

@NgModule({
  declarations: [
    ProductChainComponent,
    NodeContainerComponent,
    StepDetailsDialogComponent,
    SupplierIdentificationDialogComponent,
    SupplierSiteMappingDialogComponent,
    StepDialogComponent,
    StepUpdateComponent,
    CreateClusterComponent,
    ChainSidenavComponent,
    DoughnutComponent,
    AddStepsPhaseComponent,
    StepCompleteComponent,
    DocumentsCertificatesComponent,
    PublishTutorialComponent,
    IngredientDetailsFormComponent,
    NotifyDialogComponent,
  ],
  imports: [
    CommonModule,
    NgChartsModule,
    ProductChainRouterModule,
    AngularMaterialModule,
    SharedModule,
    ReactiveFormsModule,
    TranslateModule.forChild({}),
    PublicationModule,
    ColorPickerModule,
  ],
  exports: [ProductChainComponent],
  providers: [
    PhasesFacade,
    NodeService,
    SuppliersFacade,
    FilesMeadiasLinksFacade,
    ProductsFacade,
    DatePipe,
    CustomPublicationsFacade,
  ],
})
export class ProductChainModule {}
