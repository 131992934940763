<div class="title-content">
  <p class="title">Cookies policy</p>
  <p class="title-description">
    This Cookies Policy explains what Cookies are and how We use them. You should read this policy so You can understand
    what type of cookies We use, or the information We collect using Cookies and how that information is used. Cookies
    do not typically contain any information that personally identifies a user, but personal information that we store
    about You may be linked to the information stored in and obtained from Cookies. For further information on how We
    use, store and keep your personal data secure, see our Privacy Policy. We do not store sensitive personal
    information, such as mailing addresses, account passwords, etc. in the Cookies We use.
  </p>
</div>
<div class="content">
  <p class="content-title">Interpretation and Definitions</p>
  <p class="content-sub-title">Interpretation</p>
  <p class="content-text">
    The words of which the initial letter is capitalized have meanings defined under the following conditions. The
    following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
  </p>
  <p class="content-sub-title">Definitions</p>
  <p class="content-text mb-0">For the purposes of this Cookies Policy:</p>
  <ul class="content-unorder-list mt-0">
    <li>
      Company (referred to as either "the Company", "We", "Us" or "Our" in this Cookies Policy) refers to my site.
    </li>
    <li>
      Cookies means small files that are placed on Your computer, mobile device or any other device by a website,
      containing details of your browsing history on that website among its many uses.
    </li>
    <li>Website refers to my site, accessible from [my site](my site)</li>
    <li>
      You means the individual accessing or using the Website, or a company, or any legal entity on behalf of which such
      individual is accessing or using the Website, as applicable.
    </li>
  </ul>
</div>
<div class="content">
  <p class="content-title">The use of the Cookies</p>
  <p class="content-sub-title">Type of Cookies We Use</p>
  <p class="content-text">
    Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile
    device when You go offline, while Session Cookies are deleted as soon as You close your web browser. We use both
    session and persistent Cookies for the purposes set out below:
  </p>
  <ul class="content-unorder-list">
    <li>Necessary / Essential Cookies</li>
    <li>Type: Session Cookies Administered by: Us</li>
    <li>
      Purpose: These Cookies are essential to provide You with services available through the Website and to enable You
      to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without
      these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide
      You with those services.
    </li>
  </ul>

  <ul class="content-unorder-list">
    <li>Functionality Cookies</li>
    <li>Type: Persistent Cookies</li>
    <li>Administered by: Us</li>
    <li>
      Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your
      login details or language preference. The purpose of these Cookies is to provide You with a more personal
      experience and to avoid You having to re-enter your preferences every time You use the Website.
    </li>
  </ul>

  <p class="content-sub-title">Your Choices Regarding Cookies</p>
  <p class="content-text">
    If You prefer to avoid the use of Cookies on the Website, first You must disable the use of Cookies in your browser
    and then delete the Cookies saved in your browser associated with this website. You may use this option for
    preventing the use of Cookies at any time. If You do not accept Our Cookies, You may experience some inconvenience
    in your use of the Website and some features may not function properly.
  </p>
  <p class="content-text">
    If You'd like to delete Cookies or instruct your web browser to delete or refuse Cookies, please visit the help
    pages of your web browser.
  </p>
  <ul class="content-unorder-list">
    <li class="mb-3">
      For the Chrome web browser, please visit this page from Google:
      <a href="https://support.google.com/accounts/answer/32050" target="_blank"
        >https://support.google.com/accounts/answer/32050</a
      >
    </li>
    <li class="mb-3">
      For the Internet Explorer web browser, please visit this page from Microsoft:
      <a href="http://support.microsoft.com/kb/278835" target="_blank">http://support.microsoft.com/kb/278835</a>
    </li>
    <li class="mb-3">
      For the Firefox web browser, please visit this page from Mozilla:
      <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored" target="_blank"
        >https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a
      >
    </li>
    <li class="mb-4">
      For the Safari web browser, please visit this page from Apple:
      <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank"
        >https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a
      >
    </li>
  </ul>
  <p class="content-text">For any other web browser, please visit your web browser's official web pages.</p>
</div>

<div class="content">
  <p class="content-title">Contact Us</p>
  <p class="content-text">If you have any questions about this Cookies Policy, contact us on {{ supportedEmail }}.</p>
</div>
