<div class="content">
  <div class="row">
    <div class="h-100 justify-content-around wrapper">
      <h1>{{ data.product.name }}</h1>
      <label *ngIf="data.product.summary" class="carbon__title d-inline-block w-100">Summary </label>
      <h3>{{ data.product.summary }}</h3>
      <div>
        <label *ngIf="data.product.name === 'Tablette PROCOPE'" class="carbon__title d-inline-block w-100"
          >Summary
        </label>
        <span class="product_description" [innerHTML]="data.product.description"></span>
      </div>

      <div *ngIf="data.product.reference">
        <label class="carbon__title d-inline-block w-100">Product reference number </label>
        <span class="product_description">{{ data.product.reference }} </span>
      </div>

      <div *ngIf="data.product.composition">
        <label class="carbon__title d-inline-block w-100">Composition: </label>
        <span class="product_description">{{ data.product.composition }} </span>
      </div>
      <div *ngIf="data.reference">
        <label class="carbon__title d-inline-block w-100">{{ 'Respect Code' | translate }}</label>
        <span class="product_description">{{ data.reference }} </span>
      </div>
    </div>
  </div>
  <app-custom-icon
    mat-dialog-close
    class="close-circle"
    [icon_category]="'essential'"
    [icon_name]="'close-circle'"
  ></app-custom-icon>
</div>
