<div class="wrapper">
  <div class="header">
    <h1>{{ data.group ? ('Edit Group' | translate) : ('Create Group' | translate) }}</h1>
  </div>
  <div class="content">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-12 p-0">
          <h5>{{ 'Step Group Name' | translate }}</h5>
          <div class="d-flex form-holder">
            <mat-form-field class="input-primary" appearance="outline">
              <mat-label>{{ 'Enter Step Group Name' | translate }}</mat-label>
              <input matInput formControlName="name" placeholder="{{ 'Enter Step Group Name' | translate }}" required />
            </mat-form-field>
            <div class="cluster-color">
              <div class="cluster-color-select align-items-center" (click)="colorPicker.click()">
                <div class="">
                  <h3>{{ data.group ? 'Edit group color' : 'Choose group color' }}</h3>
                </div>
                <div class="">
                  <div class="cluster-color" [style.background]="form.get('color')?.value"></div>
                </div>

                <input
                  #colorPicker
                  [colorPicker]="form.get('color')?.value"
                  (colorPickerChange)="colorPickerChange($event)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<mat-dialog-actions align="end">
  <app-rounded-button color="tetriary-link" [button_text]="'Cancel'" [size]="'sm'" mat-dialog-close>
  </app-rounded-button>
  <app-rounded-button
    color="primary"
    [button_text]="data.group ? 'Done Editing' : 'Create Group'"
    [size]="'md'"
    [icon_right]="!data.group"
    [icon_category]="'grid'"
    [icon_name]="'format-square'"
    (click)="!data.group ? saveChanges() : updateGroup()"
    [disabled]="form && !form.valid"
  >
  </app-rounded-button>
</mat-dialog-actions>
