import { ICertificateCategory } from './certificates.interface';
import { ICluster } from './cluster.interface';
import { ICo2Impact } from './productchain.interface';
import { IFactory } from './suppliers.interface';
import { IManufactory } from './manufactories.interface';
import { IMedia } from './medias.interface';
import { IPhase } from './phase-management.interface';

export interface IProductChainStepResponse {
  count: number;
  next: string;
  previous: string;
  results: IProductChainStep[];
  error?: IProductChainStepErrorResponse;
}

export interface IProductChainStep {
  description?: string;
  name: string;
  position_x: number;
  position_y: number;
  uuid: string;
  certification_manufactory?: IStepCertification[];
  custom_locations?: IStepLocation[];
  manufactories: IManufactory;
  ingredient?: {
    name: string;
    uuid: string;
  };
  next_steps?: string[];
  medias?: IMedia[];
  documents?: IMedia[];
  output_tags?: string;
  supplier?: SipSupplier;
  product_chain: string;
  type_step: {
    name: string;
    uuid: string;
    color: string;
    position: number;
  };
  youtube_url?: string;
  id?: string;
  phase?: {
    name: string;
    uuid: string;
    color: string;
  };
  group?: ICluster;
  quantity?: number;
  quantity_unit?: QuantityUnit;
  reference?: string;
  date_completion?: string;
  step_operator_name?: string;
  status?: Status;
  error?: IProductChainStepErrorResponse;
  supplier_batch_number: string;
  co2_impacts?: ICo2Impact[];
  step_supplier?: StepSupplier;
  brand_status?: string;
  supplier_status?: SupplierStatus;
  sip_supplier_identified?: SipSupplier;
  sip_supplier_identified_approved?: StepSupplierIdentified;
  reason_no_direct_supplier?: string;
  supplier_disapproval_reason?: string | null;
  proofs?: IMedia[];
  is_active?: boolean;
  isChainComplete?: boolean;
  is_hidden?: boolean; // TODO check this
  isCustomField?: boolean;
}

export enum Status {
  not_started = 'NOT STARTED',
  in_progress = 'IN PROGRESS',
  completed = 'COMPLETED',
  complete = 'COMPLETE',
}

export enum SupplierStatus {
  not_applicable = 'Not applicable',
  to_update = 'To update',
  updated = 'Updated',
  under_validation = 'Under Validation',
  validated = 'Validated',
}

export enum StepSupplierIdentified {
  unknown = 0,
  unapproved = 1,
  approved = 2,
}

export interface QuantityUnit {
  name?: string;
  symbol?: string;
  uuid?: string;
}

export interface SipSupplier {
  uuid: string;
  name: string;
  address?: string;
  city?: string;
  country?: {
    name: string;
    code: string;
  };
  region?: string;
  supplier_unique_identifier?: string;
  unique_identifier_type?: string;
  manufactories?: IFactory[];
}

export interface StepSupplier {
  uuid: string;
  email?: string;
  name?: string;
  role?: string;
  language_prefered?: LanguagePrefered;
}

export interface LanguagePrefered {
  uuid: string;
  name: string;
  short_name: string;
}

export interface IStepCertification {
  certificate: {
    name: string;
    uuid: string;
    category: ICertificateCategory;
  };
  certificator: string;
  document: string;
  validity_start: string | null;
  validity_end: string | null;
  uuid: string;
  step: string;
  supplier: string;
  manufactory?: string;
}

export interface IStepLocation {
  name: string;
  city: string;
  country: string;
  address: string;
  uuid?: string;
  step?: string;
  longitude: number;
  latitude: number;
  error?: {
    detail: string[];
  };
}

export interface IDeleteStepLocation {
  detail: string;
  error?: {
    detail: string[];
  };
}

export interface IProductChainStepErrorResponse {
  detail: string[];
}

export interface IDeleteSupplyChainStep {
  detail: string;
  error?: {
    detail: string[];
  };
}

export interface TypeStepResponse {
  count: number;
  next: string;
  previous: string;
  results: TypeStepResult[];
  error?: ITypeStepErrorResponse;
}

export interface TypeStepResult {
  name: string;
  uuid: string;
}

export interface ITypeStepErrorResponse {
  detail: string[];
}

export interface IDialogData {
  manufactories: IManufactory[];
  selectedManufactories: string[];
  name: string;
  selectedStepUuid: string;
  stepData: IProductChainStep;
  supplier: string;
  stepPhase: IPhase;
}

export interface IStepSiteBatchCertificate extends IManufactory {
  supplier: string;
  step: string;
}

export interface IInviteSupplierStep {
  error?: IProductChainStepErrorResponse;
}

export interface IStepSection {
  section: string;
  title: string;
  locked: boolean;
  icon: IIcon;
}
export interface IIcon {
  category: string;
  name: string;
}
