import { Component, OnInit, ViewChild } from '@angular/core';
import { IBrandFeatures, IBrandFeaturesMessage, IBrandFeaturesResponse } from '@app/core/interface/brands.interface';
import { NotificationType, PROJECT_MANAGEMENT, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { BrandsFacade } from '@app/core/facade/brands.facade';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { RegulationsComponent } from './regulations/regulations.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-features-management',
  templateUrl: './features-management.component.html',
  styleUrls: ['./features-management.component.scss'],
})
export class FeaturesManagementComponent implements OnInit {
  @ViewChild(RegulationsComponent)
  regulationsComponent!: RegulationsComponent;
  toggle_on!: boolean;
  brandFeatures!: IBrandFeatures[];
  projectManagement!: IBrandFeatures | undefined;
  uuid!: string;
  tooltip_on!: boolean;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  notQaOrProd = !environment.qa && !environment.production;
  constructor(
    private _dialog: MatDialog,
    private _brandsFacade: BrandsFacade,
    private _snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.getBrandFeatures();
  }

  getToggleStatus(event: MatSlideToggleChange) {
    const newValue = event.checked;
    if (newValue) {
      event.source.checked = this.toggle_on;
      this.reactivatingFeatureDialog(event);
    } else {
      this.onSwitchOff();
    }
  }

  onSwitchOff() {
    if (this.toggle_on) {
      this.warningDialog();
    }
  }

  getBrandFeatures(): void {
    this._brandsFacade.getBrandFeatures$().subscribe({
      next: this._success.bind(this),
      error: this._Error.bind(this),
    });
  }

  private _success(data: IBrandFeaturesResponse) {
    this.brandFeatures = data.results;
    if (this.brandFeatures) {
      this.projectManagement = this.brandFeatures.find(el => el.name === PROJECT_MANAGEMENT);
      if (this.projectManagement) {
        this.toggle_on = this.projectManagement.is_enabled;
        this.uuid = this.projectManagement.uuid;
      }
    }
    this.paginator.length = this.brandFeatures.length;
    ///if not in qa or production then add 1
    this.notQaOrProd && this.paginator.length++;
    this.paginator.pageSize = 6;
  }

  private _Error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  enableBrandFeatures(): void {
    this._brandsFacade.enableBrandFeatures$(this.uuid).subscribe({
      next: this._successEnable.bind(this),
      error: this._Error.bind(this),
    });
  }

  private _successEnable(data: IBrandFeaturesMessage) {
    this._snackbarService.openTypeSnackbar(data.message, NotificationType.success);
  }

  disableBrandFeatures(): void {
    this._brandsFacade.disableBrandFeatures$(this.uuid).subscribe({
      next: this._successDisable.bind(this),
      error: this._Error.bind(this),
    });
  }

  private _successDisable(data: IBrandFeaturesMessage) {
    this.toggle_on = false;
    this._snackbarService.openTypeSnackbar(data.message, NotificationType.success);
  }
  warningDialog() {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `Note that once you disable this feature, everything related to projects will be hidden, but you won’t lose your projects and data.`,
        confirmationText: 'Are you sure you want to continue?',
        btnText: 'Yes, continue',
        type: 'warning',
        text: 'warning-text',
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.toggle_on = false;
        this.disableBrandFeatures();
      }
    });
  }

  reactivatingFeatureDialog(event: MatSlideToggleChange) {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `You are about to reactivate this Feature, and all of the data related to the Projects will be visible again.`,
        confirmationText: 'Do you wish to continue?',
        btnText: 'Yes, continue',
        type: 'info-actions',
        text: 'info-text',
        reactivate: true,
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.toggle_on = true;
        this.enableBrandFeatures();
      } else {
        event.source.checked = false;
      }
    });
  }
}
