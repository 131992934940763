import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IProductResponse } from '@app/core/interface/products.interface';
import { take } from 'rxjs/internal/operators/take';

@Component({
  selector: 'app-chain-setup-header',
  templateUrl: './chain-setup-header.component.html',
  styleUrls: ['./chain-setup-header.component.scss'],
})
export class ChainSetupHeaderComponent implements OnInit {
  @Output() saveAndClose = new EventEmitter<boolean>();
  @Output() publish = new EventEmitter<boolean>();
  @Output() preview = new EventEmitter<boolean>();
  @Input() isFormValid = false;
  @Input() saveBtnLabel?: string;
  @Input() isChainBuilder = false;
  @Input() isPublication = false;
  @Input() icon?: string;
  @Input() isSupplyChainSetup = false;
  @Input() isChainComplete? = false;
  navigatedFromRoute = '';
  navigatedFromTab!: string;
  isBatchManagementRoute = false;
  productChainUUID!: string | null;
  product!: IProductResponse;
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.paramMap.pipe(take(1)).subscribe(snapshot => {
      const routeNavigatedFrom = snapshot.get('currentUrl');
      const tabNavigatedFrom = snapshot.get('tab');
      if (routeNavigatedFrom && tabNavigatedFrom) {
        this.navigatedFromRoute = routeNavigatedFrom;
        this.navigatedFromTab = tabNavigatedFrom;
      }
    });
    this.router.events.pipe(take(1)).subscribe(() => {
      const currentUrl = this.router.url;
      this.isBatchManagementRoute = currentUrl.includes('/batch-management/');
    });
  }

  goBack(): void {
    if (
      this.navigatedFromRoute.includes('/dashboard/product-chains') ||
      this.navigatedFromRoute.includes('dashboard/product') ||
      this.navigatedFromRoute.includes('supplier-management')
    ) {
      this.router.navigate([this.navigatedFromRoute.split(';')[0], { chains: this.navigatedFromTab === 'chains' }]);
    } else {
      this.router.navigate(['/dashboard/product-chains']);
    }
  }

  goToDashboard(): void {
    this.router.navigate(['/dashboard']);
  }
}
