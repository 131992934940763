<div class="header d-flex justify-content-between align-items-center">
  <h3>{{ 'Request creation' | translate }}</h3>
  <app-custom-icon
    mat-dialog-close
    [icon_category]="'essential'"
    [icon_name]="'close-circle'"
    class="cursor-pointer"
  ></app-custom-icon>
</div>

<form [formGroup]="form">
  <p>{{ 'Request type' }}*</p>
  <mat-form-field class="input-primary" appearance="outline">
    <mat-label>{{ 'Choose request type' | translate }}</mat-label>
    <mat-select formControlName="request_type" panelClass="dropdown-primary">
      <mat-option *ngFor="let request of requestTypes" [value]="request.value" placeholder="Request Type">
        {{ request.label }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form.controls['request_type'].hasError('required')">{{
      'this is a required field' | translate
    }}</mat-error>
  </mat-form-field>

  <div class="d-flex">
    <p>{{ 'How we can help?' }}</p>
    <app-custom-icon
      [icon_category]="'essential'"
      [icon_name]="'info-circle'"
      [matTooltip]="requestDialogTooltip"
      matTooltipClass="custom-tooltip-center"
      matTooltipPosition="above"
    ></app-custom-icon>
  </div>
  <mat-form-field class="input-primary textarea" appearance="outline">
    <mat-label>{{ 'Please explain your request' | translate }}</mat-label>
    <textarea
      id="area"
      matInput
      formControlName="additional_comment"
      placeholder="Enter comment"
      rows="6"
      appTextareaAutoResize
    ></textarea>
    <mat-error *ngIf="form.controls['additional_comment'].hasError('required')">{{
      'this is a required field' | translate
    }}</mat-error>
  </mat-form-field>
</form>

<div class="footer">
  <p>
    {{ 'All personal data is protected. Learn more in our' | translate }}
    <span [routerLink]="'/terms-and-conditions/Privacy policy'" mat-dialog-close>{{ 'Privacy Policy' }}</span>
  </p>
  <app-rounded-button
    color="primary"
    [button_text]="'Send request'"
    [icon_category]="'location'"
    [icon_name]="'direct-right'"
    [icon_right]="true"
    [size]="'md'"
    [disabled]="form && !form.valid"
    (click)="createRequest()"
  >
  </app-rounded-button>
</div>
