import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { SharedModule } from '../../../../shared/shared.module';
import { CommonModule } from '@angular/common';
import {
  FilePreview,
  IBatchPreview,
  IBrandPreview,
  IProductPreview,
} from '@app/core/interface/preview_publicaton.interface';
import { BatchInfoDialogComponent } from './batch-info-dialog/batch-info-dialog.component';
import { DialogInfoComponent } from './dialog-info/dialog-info.component';
import { ImageDialogComponent } from '../pin-info/pin-info-dialog/image-dialog/image-dialog.component';
import { IActiveCertificationProduct } from '@app/core/interface/products.interface';
import { CertificateInfoDialogComponent } from '../pin-info/pin-info-dialog/certificate-info-dialog/certificate-info-dialog.component';

@Component({
  selector: 'app-product-info',
  standalone: true,
  imports: [SharedModule, CommonModule],
  templateUrl: './product-info.component.html',
  styleUrl: './product-info.component.scss',
})
export class ProductInfoComponent {
  @Input() product?: IProductPreview;
  @Input() publication_reference?: string;
  @Input() reference?: string;
  @Input() batches?: IBatchPreview[];
  @Input() brand?: IBrandPreview;
  @Input() productCertificates?: IActiveCertificationProduct[];
  imagePlaceholder = '../../../../../assets/images/brand-avatar.png';
  constructor(public dialog: MatDialog) {}

  onSingleImgDialog(imgUrl?: string) {
    if (imgUrl === undefined) {
      return;
    }
    if (innerWidth <= 570) {
      this.dialog.open(ImageDialogComponent, {
        data: [imgUrl],
        width: 'max-content',
        height: 'fit-content',
        panelClass: 'step-info-dialog',
        maxWidth: '90vw',
      });
    } else {
      this.dialog.open(ImageDialogComponent, {
        data: [imgUrl],
        width: 'fit-content',
        height: 'fit-content',
        panelClass: 'step-info-dialog',
      });
    }
  }

  onImgDialog(imgUrl: FilePreview[], slide: FilePreview) {
    const sortedImages = this.sortImgs([...imgUrl], slide);
    const innerWidth = window.innerWidth;
    if (innerWidth <= 570) {
      this.dialog.open(ImageDialogComponent, {
        data: sortedImages,
        width: 'max-content',
        height: 'fit-content',
        panelClass: 'step-info-dialog',
        maxWidth: '90vw',
      });
    } else {
      this.dialog.open(ImageDialogComponent, {
        data: sortedImages,
        width: 'fit-content',
        height: 'fit-content',
        panelClass: 'step-info-dialog',
      });
    }
  }

  onBatchInfoDialog() {
    this.dialog.open(BatchInfoDialogComponent, {
      data: { brand: this.brand, product: this.product, batches: this.batches },
      width: '1160px',
      height: '660px',
      maxWidth: '90vw',
      panelClass: 'batch-info-dialog',
    });
  }

  sortImgs(items: FilePreview[], slide: FilePreview) {
    const indexToMove = items.findIndex(item => item.file === slide.file);
    if (indexToMove !== -1 && indexToMove !== 0) {
      const itemToMove = items.splice(indexToMove, 1)[0]; // Remove the item from the array
      items.unshift(itemToMove);
    }
    return items;
  }

  openDialog() {
    this.dialog.open(DialogInfoComponent, {
      data: { product: this.product, reference: this.reference, brand: this.brand },
      panelClass: 'product-info-dialog',
      maxWidth: '90vw',
    });
  }

  onCertificateDialog(certificate: IActiveCertificationProduct) {
    this.dialog.open(CertificateInfoDialogComponent, {
      data: certificate,
      width: '1160px',
      height: '660px',
      panelClass: ['step-info-dialog', 'overflow-hidden'],
    });
  }
}
