<div *ngIf="isVisibleBackToTopButton()" class="back-btn back-to-top-button cursor-pointer">
  <app-custom-icon
    id="back-btn"
    matTooltip="Back to top"
    [icon_category]="'arrows'"
    [icon_name]="'arrow-up-2'"
    (click)="backToTop()"
  >
  </app-custom-icon>
</div>

<div class="title-content" id="idDataProtectionPolicy">
  <p class="title">Data protection policy for SaaS service and support</p>
  <p class="title-description">
    This data protection policy (“Policy”) applies to the processing of Personal Data in the context of the Agreement.
  </p>
  <p class="title-description">
    It is hereby agreed that this Policy is incorporated into the terms of the Agreement. Any term not defined in this
    Policy has the meaning given to it in the Agreement. The terms “Personal Data”, “process”, “processing”,
    “processor”, “transfer”, “controller” shall have the meaning given to them under the Applicable Regulations.
  </p>
</div>

<div class="content">
  <!-- 1. General principles -->
  <p class="content-title" #generalPrinciples>1. General principles</p>
  <p class="content-sub-title">1.1</p>
  <div class="content-text">
    <p>Pursuant to the Applicable Regulations and in the context of the Agreement:</p>
    <p>• The Client is data controller of the Personal Data or, when applicable, data processor of its own clients;</p>
    <p>
      • PDNA is data processor of the Personal Data, processing exclusively on behalf and only on documented
      instructions from the Client.
    </p>
  </div>
  <p class="content-sub-title">1.2</p>
  <div class="content-text">
    <p>
      The Parties recognize that the Agreement, as well as the use of the Service and its functionalities, in accordance
      with the Agreement, form the documented instructions of the Client. Any additional instruction concerning the
      processing of Personal Data by PDNA shall be provided by the Client in written form. The instruction specifies the
      purpose of processing and the operation to be performed by PDNA, provided that the Client agrees beforehand on the
      estimate from PDNA for the additional instruction.
    </p>
    <p>
      PDNA shall inform the Client in a period of five (5) days from the date of the receipt from PDNA of the
      instruction by any means, if, in its opinion, an instruction infringes the Applicable Regulations.
    </p>
  </div>
  <p class="content-sub-title">1.3</p>
  <div class="content-text">
    <p>
      The Client recognizes that it has the exclusive control and knowledge, and notably, of the origin of the Personal
      Data processed for the specific purpose of the Agreement. Consequently, the Client shall fulfil its obligations as
      data controller.
    </p>
  </div>
  <p class="content-sub-title">1.4</p>
  <div class="content-text">
    <p>
      PDNA will delete the Personal Data and copies thereof in accordance with the Agreement, unless any applicable law
      or the Applicable Regulations require storage of the Personal Data.
    </p>
  </div>
  <p class="content-sub-title">1.5</p>
  <div class="content-text">
    <p>
      The Client shall inform PDNA, when signing the Agreement, of the person to contact for all information,
      communications, notifications, or requests made in respect of the Policy. If the Client does not provide PDNA with
      this information, the signatory will be considered as the relevant contact person.
    </p>
  </div>
  <p class="content-sub-title">1.6</p>
  <div class="content-text">
    <p>
      If it is strictly necessary for the performance of the Agreement, PDNA may transfer Personal Data provided that
      the Client is informed beforehand of such transfer. In any case, PDNA shall not transfer Personal Data, without
      implementing the appropriate safeguards outside:
    </p>
    <p>• the European Union, including Switzerland;</p>
    <p>• the European Economic Area, including Switzerland;</p>
    <p>
      • a third country or territory recognized by the European Commission as ensuring an adequate level of protection
      in application of the Applicable Regulations, including companies established in the United States of America
      certified under the “Privacy-Shield” framework.
    </p>
    <p>In any case, the Personal Data entrusted to PDNA is localized at one or more sites in the European Union.</p>
  </div>
  <!-- 2. Security of personal data -->
  <p class="content-title">2. Security of personal data</p>
  <p class="content-sub-title">2.1</p>
  <div class="content-text">
    <p>
      The Client and PDNA shall implement and maintain appropriate technical and organizational measures to ensure a
      level of security appropriate to the risk. The measures taken by PDNA are listed in a security measures document,
      an updated version of which is available to the Client upon request.
    </p>
  </div>
  <p class="content-sub-title">2.2</p>
  <div class="content-text">
    <p>
      The Client is responsible of the security and the confidentiality of its respective systems and the access it
      grants to the Service. The Client shall ensure that the use and the configuration of the Service meet the security
      requirements of the Applicable Regulations. PDNA is not bound by any obligation to protect Personal Data which is:
    </p>
    <p>• stored outside of the Service;</p>
    <p>• transferred out of the Service by the Client; or</p>
    <p>• transferred out of the Service by PDNA under instruction of the Client.</p>
  </div>
  <p class="content-sub-title">2.3</p>
  <div class="content-text">
    <p>
      PDNA ensures that persons authorized to process the Personal Data have committed themselves to confidentiality.
    </p>
  </div>
  <!-- 3. Cooperation with the client -->
  <p class="content-title">3. Cooperation with the client</p>
  <p class="content-sub-title">3.1</p>
  <div class="content-text">
    <p>
      PDNA shall communicate to the Client without undue delay after receiving any request, notice of investigation or
      complaint from any data subject concerning the processing of Personal Data under the Agreement (“Data Subject
      Requests”).
    </p>
    <p>
      Acting as data controller, the Client shall remain solely responsible for the answer to be provided to Data
      Subject Requests and PDNA shall not answer any Data Subject Requests. Notwithstanding the foregoing and taking
      into account the nature of the processing of the Personal Data, PDNA shall upon request assist the Client in the
      fulfillment of the Client’s obligations in responding to Data Subject Requests. Client acknowledges that PDNA will
      use appropriate technical and organizational measures in providing any such assistance, insofar as this is
      reasonably possible.
    </p>
  </div>
  <p class="content-sub-title">3.2</p>
  <div class="content-text">
    <p>
      Upon written request from the Client, PDNA shall provide the Client, at the expenses of the latter, with all the
      useful information in its possession for the purpose of assisting the Client, as data controller, to satisfy the
      privacy impact assessment requirements of the Applicable Regulations. Any such privacy impact assessment shall be
      carried out by and under the sole responsibility of the Client.
    </p>
  </div>
  <!-- 4. Notification of data breach -->
  <p class="content-title">4. Notification of data breach</p>
  <p class="content-sub-title">4.1</p>
  <div class="content-text">
    <p>
      PDNA shall notify the Client without undue delay after becoming aware of any breach of security leading to the
      accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, Personal Data
      transmitted, stored or otherwise processed (“Data Breach”).
    </p>
  </div>
  <p class="content-sub-title">4.2</p>
  <div class="content-text">
    <p>
      PDNA shall provide the Client without undue delay after the notification of the Data Breach and insofar as this is
      possible, the following information:
    </p>
    <p>• the categories and approximate number of data subjects concerned;</p>
    <p>• the categories and approximate number of Personal Data records concerned;</p>
    <p>• describe the likely consequences of the personal data breach;</p>
    <p>
      • describe the measures taken or proposed to be taken by PDNA to address the Data Breach, including, where
      appropriate, measures to mitigate its possible adverse effects.
    </p>
  </div>
  <!-- 5. Processor -->
  <p class="content-title">5. Processor</p>
  <p class="content-sub-title">5.1</p>
  <div class="content-text">
    <p>
      PDNA may engage a sub-processor for the processing of Personal Data that is, in PDNA’s sole discretion, strictly
      necessary for the performance of the Agreement.
    </p>
  </div>
  <p class="content-sub-title">5.2</p>
  <div class="content-text">
    <p>
      PDNA shall only engage sub-processors that provide sufficient guarantees to implement appropriate technical and
      organizational measures in such a manner that the processing will meet the requirements of the Applicable
      Regulations.
    </p>
  </div>
  <p class="content-sub-title">5.3</p>
  <div class="content-text">
    <p>
      PDNA shall by way of written agreement impose obligations substantively equivalent to those set out in the
      Agreement and in the Applicable Regulations on its sub-processors. PDNA shall remain fully liable to the Client
      for the performance of that sub-processor's obligations.
    </p>
  </div>
  <p class="content-sub-title">5.4</p>
  <div class="content-text">
    <p>PDNA may only engage a sub-processor which:</p>
    <p>• is established in one of the member states of the European Union or the European Economic Area, or;</p>
    <p>
      • is established in the United States of America and is Privacy Shield certified, or; proposes one of the
      appropriate safeguards pursuant to article 46 of the GDPR.
    </p>
  </div>
  <p class="content-sub-title">5.5</p>
  <div class="content-text">
    <p>
      The list of the sub-processors of PDNA shall be provided on written request. PDNA shall inform the Client of any
      addition or replacement of sub-processors as soon as possible. This information constitutes the information to the
      Client as specified in article 1.6 of this Policy. The Client may object in writing to such addition or
      replacement within a period of ten (10) business days from receipt of the information. The absence of objection
      from the Client after this period shall be considered acceptance of the sub-processor.
    </p>
    <p>
      In case of objection from the Client, PDNA may provide the Client with elements that could lift its objections. If
      the Client maintains its objections, the Parties shall discuss in good faith of the continuation of the Agreement.
    </p>
  </div>
  <!-- 6. Compliance and audit -->
  <p class="content-title">6. Compliance and audit</p>
  <div class="content-text">
    <p>
      On request, PDNA will send to the Client any document reasonably necessary to demonstrate PDNA’s compliance with
      its obligations as a processor under the Agreement by e-mail. Any other method for sending these documents will be
      at the Client’s expense.
    </p>
    <p>
      The Client may request additional verification from PDNA if the documents provided do not enable it to verify
      PDNA’s compliance with its obligations as a processor under the Agreement. In such case, the Client should make a
      written request to PDNA, by registered letter with acknowledgement of receipt, in which Client justifies its
      request for further information. PDNA shall answer the Client as soon as possible.
    </p>
    <p>
      If, despite PDNA’s answer, the Client questions the veracity or completeness of the information provided or, in
      the event of imminent risks to the security of Personal Data, the Client may carry out an on-site audit subject to
      compliance with the following conditions (“Audit”):
    </p>
    <p>
      • the Client makes a written request for an on-site Audit to PDNA, by registered letter with acknowledgement of
      receipt, by justifying and documenting its request;
    </p>
    <p>
      • PDNA shall provide a response to the Client specifying the scope and conditions of the on-site Audit. Since the
      security of PDNA’s information system and data centers is subject to restricted access, the scope of an on- site
      Audit will be limited to the operations and systems PDNA uses for the processing of Personal Data entrusted to
      PDNA by the Client under the Agreement;
    </p>
    <p>
      • The Audit shall not exceed two (2) business days which will be invoiced by PDNA to the Client at the rates in
      effect at the time the Audit is carried out;
    </p>
    <p>
      • This Audit may be carried out by the Client’s internal auditors or may be entrusted to any service provider
      chosen by the Client, that is not a competitor of PDNA;
    </p>
    <p>
      • Auditors must make a formal commitment not to disclose information collected at PDNA regardless of how it is
      obtained. A non-disclosure agreement must be signed by the auditors and communicated to PDNA before the Audit
      takes place.
    </p>
    <p>
      As part of the Audit, PDNA will provide access to its premises, and to the documents and persons reasonably
      necessary for the auditors to conduct the Audit in satisfactory conditions. The Client and/or the Auditors (as the
      case may be) must make reasonable endeavors to minimize any disruption to PDNA’s business operations including the
      operation of the Service. The Audit report must be made available to PDNA by the auditors before being finalized,
      so that PDNA can submit any comments, and the final report must take into account and respond to these comments.
      The Audit report will then be sent to PDNA and discussed at a meeting between the Parties.
    </p>
    <p>
      In the event that the final Audit report reveals any breach of the commitments made in relation to the Service,
      PDNA shall propose a corrective action plan within twenty (20) business days of the meeting between the Parties.
      For the purposes of this clause, “business day” means a day between Monday and Friday which is not a public
      holiday in Switzerland. Subject to material changes of circumstances and events which justify the implementation
      of an Audit at shorter notice, Audits may be carried out by the Client on PDNA’s site only once during the initial
      period of the Agreement, and subsequently only once per renewal period.
    </p>
  </div>
  <!-- 7. Description of the proccesing -->
  <p class="content-title">7. Description of the proccesing</p>
  <div class="content-text">
    <p>
      The nature of the Personal Data processing, the purpose of the processing, the Personal Data processed, the
      category of data subject concerned and the duration of the processing are described in the dedicated document
      available on the online client portal.
    </p>
  </div>
</div>
