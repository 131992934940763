<div
  class="ckeditor-wrapper rs-rte-wrap"
  [ngClass]="{
    'mat-form-field-invalid': !c.pristine && !c.valid,
    'mat-form-field-valid': !c.pristine && c.valid,
    minimized: !showToolbar,
  }"
>
  <app-custom-icon
    [icon_category]="'essential'"
    [icon_name]="showToolbar ? 'text-editor-active' : 'text-editor'"
    class="toggle-btn"
    matTooltip="{{ 'Text formatting options' | translate }}"
    matTooltipClass="custom-tooltip-end"
    matTooltipPosition="above"
    (click)="toggleToolbar()"
  ></app-custom-icon>

  <ckeditor
    #ckeditor
    [(ngModel)]="value"
    [editor]="Editor"
    [config]="editorConfig"
    (change)="onEditorChange($event)"
    (ready)="onReady()"
    [style]="editorStyle"
    (focusout)="c.markAsDirty()"
    (blur)="onBlur($event)"
    *ngIf="showToolbar"
  ></ckeditor>
  <ckeditor
    #ckeditor
    [(ngModel)]="value"
    [editor]="Editor"
    [config]="noEditorConfig"
    (change)="onEditorChange($event)"
    (ready)="onReady()"
    (focusout)="c.markAsDirty()"
    (blur)="onBlur($event)"
    [style]="editorStyle"
    *ngIf="!showToolbar"
  ></ckeditor>

  <div class="placeholder" *ngIf="!value">{{ placeholder }}</div>
</div>

<div class="error-messages" *ngIf="!c.pristine && !c.valid">
  <mat-error *ngFor="let error of errors">{{ error }}</mat-error>
</div>
