import {
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IProduct, IProductResponse } from '@app/core/interface/products.interface';
import { SharedModule } from '../../../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { ProductsService } from '@app/core/service/products.service';
import { FormArray, FormBuilder, FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { catchError, EMPTY } from 'rxjs';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { NotificationType } from '@app/core/constants';
import { SetupPublicationProductRequest } from '@app/core/interface/publication.interface';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-product-publication-setup',
  standalone: true,
  imports: [SharedModule, CommonModule],
  templateUrl: './product-publication-setup.component.html',
  styleUrl: './product-publication-setup.component.scss',
})
export class ProductPublicationSetupComponent implements OnInit, OnChanges {
  @Input() product!: IProduct | IProductResponse;
  form!: UntypedFormGroup;
  @Output() refresh = new EventEmitter<boolean>();
  // Can not find the type for this initial value.
  initialFormValue: {
    dynamicFields: Array<{
      [key: string]: string | number | object | boolean | Array<string | number | object | boolean> | undefined | null;
    }>;
  } = { dynamicFields: [] };
  fields: Array<{ name: string; type: string }> = [
    { name: 'is_description_private', type: 'boolean' },
    { name: 'is_reference_private', type: 'boolean' },
  ];
  originalFields: Array<{ name: string; type: string }> = [
    { name: 'is_description_private', type: 'boolean' },
    { name: 'is_reference_private', type: 'boolean' },
  ];
  readonly #productService = inject(ProductsService);
  readonly #productFacade = inject(ProductsFacade);
  readonly #snackBarService = inject(SnackbarService);

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      dynamicFields: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.generateForm();
    // Change the value into productService if there is a change into the form.
    this.form.valueChanges.subscribe(() => {
      if (this.form.dirty && !this.isFormUnchanged()) {
        this.#productService.changesBehaviour.next(true);
      } else this.#productService.changesBehaviour.next(false);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['product']) this.generateForm();
  }

  // Generate form for the custom fields.
  generateForm() {
    this.fields = [...this.originalFields];
    this.product.custom_fields?.map(element => this.fields.push({ name: element.uuid, type: 'boolean' }));

    const controlArray = this.form.get('dynamicFields') as FormArray<FormGroup>;
    controlArray.clear();

    this.fields.forEach(field => {
      controlArray.push(this.createFieldControl(field) as FormGroup);
    });

    // Save the initial form value for further check.
    this.initialFormValue = this.form.value;
  }

  // Create a field Controls for the Description, referals and all custom fields.
  createFieldControl(field: { name: string; type: string }): FormGroup {
    return this.fb.group({
      [field.name]: new FormControl(this.getInitialValue(field.name)),
    });
  }

  getInitialValue(name: string): boolean {
    if (name == 'is_description_private') return this.product.is_description_private ?? false;
    else if (name == 'is_reference_private') return this.product.is_reference_private ?? false;
    else return this.product.custom_fields?.find(element => element.uuid == name)?.is_field_private ?? false;
  }

  get dynamicFields() {
    return this.form.get('dynamicFields') as FormArray<FormGroup>;
  }

  getCustomFieldName(control: FormGroup) {
    const controlUuid = Object.keys(control['controls'])[0];
    return this.product.custom_fields?.find(element => element.uuid === controlUuid)?.field_name as string;
  }

  getCustomFieldFormControl(control: FormGroup) {
    return Object.keys(control['controls'])[0] as string;
  }

  // CANT NOT FIND TYPE FOR THIS EVENT.
  // Show default modal from the browser when user want to close or reload the page.
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: BeforeUnloadEvent): void {
    if (this.form.dirty && !this.isFormUnchanged()) {
      $event.returnValue = true;
    }
  }

  onSubmit() {
    const dynamicFields = this.form.get('dynamicFields')?.value as Array<FormGroup>;

    const productFields = dynamicFields.slice(0, 2);

    // Transform data for customFields in format [{uuid: $uuid, is_field_private: boolean }];
    const customFields = dynamicFields.slice(2)?.map(item => {
      const key = Object.keys(item)[0];
      const value = Object.values(item)[0];
      return {
        uuid: key,
        is_field_private: value,
      };
    });

    const formData = {
      product: productFields,
      custom_fields: customFields ?? [],
    };

    this.#productFacade
      .saveSetup$(this.product.uuid, formData as SetupPublicationProductRequest)
      .pipe(
        catchError(() => {
          this.#snackBarService.openTypeSnackbar(
            'Error, please contact our support for further details',
            NotificationType.error
          );
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.refresh.emit(true);
        this.#productService.changesBehaviour.next(false);
        this.#snackBarService.openTypeSnackbar('Publication setup updated successfully', NotificationType.success);
      });
  }

  // Check if the form has a change or has a initial value
  isFormUnchanged(): boolean {
    // Compare the initial form value with the current form value
    return JSON.stringify(this.initialFormValue) === JSON.stringify(this.form.value);
  }

  onToggleChange(index: number, controlName: string, event: MatSlideToggleChange): void {
    const control = (this.form.get('dynamicFields') as FormArray).at(index).get(controlName);
    if (control) {
      // Update the control with the negated value
      control.setValue(!event.checked);
    }
    this.#productService.changesBehaviour.next(true);
  }
}
