import { Injectable } from '@angular/core';
import { LanguagesService } from '../service/languages.service';
import { Observable } from 'rxjs/internal/Observable';
import { take } from 'rxjs/internal/operators/take';
import { ILanguagesResponse } from '../interface/languages.interface';
import { catchError, ObservableInput, throwError } from 'rxjs';

@Injectable()
export class LanguagesFacade {
  constructor(private _languagesService: LanguagesService) {}

  getLanguages$(): Observable<ILanguagesResponse> {
    return this._languagesService.getLanguages$().pipe(
      take(1),
      catchError(({ error }: ILanguagesResponse): ObservableInput<ILanguagesResponse> => throwError(() => error))
    );
  }
}
