<div class="wrapper">
  <div class="header d-flex align-items-center justify-content-between">
    <div class="breadcrumb d-flex align-items-center">
      <app-custom-icon
        [icon_category]="'arrows'"
        [icon_name]="'arrow-circle-left'"
        class="back-arrow cursor-pointer"
        (click)="goBack()"
      ></app-custom-icon>
      <h6 class="cursor-pointer" (click)="goBack()">{{ 'Publication tool' | translate }}</h6>
      <app-custom-icon
        [icon_category]="'arrows'"
        [icon_name]="'arrow-right-short'"
        class="arrow-short"
      ></app-custom-icon>
      <h5>{{ 'Preview brand page' | translate }}</h5>
    </div>
    <app-custom-icon
      [icon_category]="'essential'"
      [icon_name]="'close-circle'"
      class="close-header cursor-pointer"
      (click)="goBack()"
    ></app-custom-icon>
  </div>
  <div class="row">
    <div class="col-12 landing-setup" *ngIf="selectedBrand">
      <div class="row landing-page">
        <div
          class="cover-photo"
          [style.backgroundImage]="
            selectedBrand.banner
              ? 'url(' + selectedBrand.banner + ')'
              : 'url(../../../../assets/images/upload-placeholder-blue.svg)'
          "
        ></div>
        <div class="col-12">
          <img
            class="logo"
            src="{{
              selectedBrand.logo ? selectedBrand.logo : '../../../../assets/images/upload-placeholder-blue.svg'
            }}"
          />
        </div>
        <div class="col-6 brand-info">
          <h5>{{ selectedBrand.name }}</h5>
          <p>{{ productList?.length }} {{ 'traced products' | uppercase }}</p>
        </div>
        <div class="col-6 d-flex justify-content-end open-brand-button">
          <app-rounded-button
            color="secondary"
            [button_text]="'Visit Brand Website'"
            [icon_category]="'arrows'"
            [icon_name]="'arrow-right-long'"
            [size]="'md'"
            [icon_right]="true"
            (click)="openBrandWebsite()"
          >
          </app-rounded-button>
        </div>
        <!-- <h4 *ngIf="selectedBrand.traceability_commitment" class="traceability">
          {{ 'Traceability Commitment' | translate }}
        </h4>
        <div class="col-12 traceability tr-cont">
          <h4 *ngIf="selectedBrand.rc_section_title">
            <app-view-rich-text [content]="selectedBrand.rc_section_title"></app-view-rich-text>
          </h4>
          <p>
            <app-view-rich-text [content]="selectedBrand.traceability_commitment"></app-view-rich-text>
          </p>
        </div> -->
        <div class="col-12 traceability tr-cont">
          <p>
            <app-view-rich-text [content]="selectedBrand.description"></app-view-rich-text>
          </p>
        </div>
        <div class="row mt-3 product-row">
          <div class="col-2 product" *ngFor="let product of productList">
            <div
              class="image"
              [style.backgroundImage]="
                product.image ? 'url(' + product.image + ')' : '../../../../assets/images/image-placeholder.jpg'
              "
            ></div>
            <p>{{ product.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
