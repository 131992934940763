import { CommonModule } from '@angular/common';
import { DataPrivacyComponent } from './data-privacy.component';
import { DataPrivacyGroup } from './data-privacy.group';
import { DataPrivacyRoutingModule } from './data-privacy-routing.module';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RequestCreationComponent } from './request-creation/request-creation.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [DataPrivacyComponent, RequestCreationComponent],
  imports: [CommonModule, SharedModule, DataPrivacyRoutingModule, ReactiveFormsModule],
  providers: [DataPrivacyGroup],
})
export class DataPrivacyModule {}
