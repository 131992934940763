<ng-container>
  <app-loader [delayed]="0.3"></app-loader>
  <mat-sidenav-container class="main-container" autosize>
    <mat-sidenav #sidenav mode="side" opened="true">
      <app-sidenav *ngIf="isSidenavVisible"> </app-sidenav>
    </mat-sidenav>
    <mat-sidenav-content [ngClass]="sidenavContentClass">
      <app-header
        *ngIf="isHeaderVisible"
        [isChainSetup]="chainSetup"
        [isPublish]="isPublish"
        [showWelcomeMessage]="showWelcomeMessage"
      ></app-header>

      <div [ngClass]="contentClass">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <img
    *ngIf="isUserLoggedIn"
    class="cookies-icon"
    src="../../../assets/images/cookie.png"
    matTooltip="{{ 'Manage cookies' | translate }}"
    matTooltipClass="custom-tooltip-center"
    matTooltipPosition="above"
    (click)="openCookiesManagement()"
  />
</ng-container>
