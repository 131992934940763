import { Component, ElementRef, QueryList, signal, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-acceptable-policy',
  templateUrl: './acceptable-policy.component.html',
  styleUrl: './acceptable-policy.component.scss',
})
export class AcceptablePolicyComponent {
  @ViewChildren('welcome') contentItems!: QueryList<ElementRef>;

  protected supportEmail: string = 'support@productdna.com';
  protected infoEmail: string = 'info@productdna.com';
  protected isVisibleBackToTopButton = signal<boolean>(false);

  private observer!: IntersectionObserver;

  ngAfterViewInit(): void {
    this._initIntersectionObserver();
    this._observeElements();
  }

  ngOnDestroy(): void {
    this._disconnectObserver();
  }

  backToTop(): void {
    const element = document.getElementById('idAcceptablePolicy');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  ////////////////////////////// PRIVATE METHODS //////////////////////////////

  private _disconnectObserver(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private _initIntersectionObserver(): void {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: 0.1, // Trigger callback when 10% of the element is in view
    };

    this.observer = new IntersectionObserver(this._handleIntersection.bind(this), options);
  }

  private _observeElements(): void {
    this.contentItems.forEach(item => {
      this.observer.observe(item.nativeElement);
    });
  }

  private _handleIntersection(entries: IntersectionObserverEntry[]): void {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.isVisibleBackToTopButton.set(false);
      } else {
        this.isVisibleBackToTopButton.set(true);
      }
    });
  }
}
