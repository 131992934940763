import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@app/core/guard/auth.guard';
import { ChainSetupModule } from './module/chain-setup/chain-setup.module';
import { ChainStepsInfoModule } from './module/chain-steps-info/chain-steps-info.module';
import { CompanyInfoModule } from './module/company-info/company-info.module';
import { CustomerSuccessManagerModule } from './module/customer-success-manager/customer-success-manager.module';
import { DashboardModule } from '@app/module/dashboard/dashboard.module';
import { DataPrivacyModule } from './module/data-privacy/data-privacy.module';
import { ForgotPasswordModule } from './module/forgot-password/forgot-password.module';
import { LandingModule } from './module/landing/landing.module';
import { LoginGuard } from '@app/core/guard/login.guard';
import { LoginModule } from './module/login/login.module';
import { NgModule } from '@angular/core';
import { ProductsModule } from './module/products/products.module';
import { PublicationModule } from './module/publication/publication.module';
import { PurchaseModule } from './module/purchase/purchase.module';
import { RegisterModule } from '@module/register/register.module';
import { SettingsModule } from './module/settings/settings.module';
import { SupplierLandingModule } from './module/supplier-landing/supplier-landing.module';
import { SupplierManagementModule } from './module/supplier-management/supplier-management.module';
import { SwitchBrandComponent } from './shared/components/switch-brand/switch-brand.component';
import { TermsAndConditionsModule } from './module/terms-and-conditions/terms-and-conditions.module';

export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'register',
    loadChildren: () => RegisterModule,
  },
  {
    path: 'landing',
    loadChildren: () => LandingModule,
  },
  {
    path: 'login',
    loadChildren: () => LoginModule,
    canActivate: [LoginGuard],
  },
  {
    path: 'password_reset',
    loadChildren: () => ForgotPasswordModule,
    canActivate: [LoginGuard],
  },
  {
    path: 'products',
    canActivate: [AuthGuard],
    loadChildren: () => ProductsModule,
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => DashboardModule,
  },
  {
    path: 'supplier-management/:uuid',
    canActivate: [AuthGuard],
    loadChildren: () => SupplierManagementModule,
  },
  {
    path: 'purchase',
    loadChildren: () => PurchaseModule,
  },
  {
    path: 'csm',
    canActivate: [AuthGuard],
    loadChildren: () => CustomerSuccessManagerModule,
    data: {
      role: 'customer_success_manager',
    },
  },
  {
    path: 'chain-setup',
    canActivate: [AuthGuard],
    loadChildren: () => ChainSetupModule,
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => SettingsModule,
  },
  {
    path: 'publish/:id',
    canActivate: [AuthGuard],
    loadChildren: () => PublicationModule,
  },
  {
    path: 'chain-steps-info',
    canActivate: [AuthGuard],
    loadChildren: () => ChainStepsInfoModule,
    data: {
      role: 'is_supplier',
    },
  },
  {
    path: 'overview',
    canActivate: [AuthGuard],
    loadChildren: () => SupplierLandingModule,
    data: {
      role: 'is_supplier',
    },
  },
  {
    path: 'select-brand',
    canActivate: [AuthGuard],
    component: SwitchBrandComponent,
    data: {
      role: 'is_supplier',
    },
  },
  {
    path: 'company-info',
    canActivate: [AuthGuard],
    loadChildren: () => CompanyInfoModule,
    data: {
      role: 'is_supplier',
    },
  },
  {
    path: 'create-password',
    loadChildren: () => RegisterModule,
    data: {
      role: 'is_supplier',
    },
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => TermsAndConditionsModule,
  },
  {
    path: 'data-privacy',
    canActivate: [AuthGuard],
    loadChildren: () => DataPrivacyModule,
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
