import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class SupplierPublicationGroup {
  publicationForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.publicationForm = this._fb.group({
      selectInput: [''],
      is_name_private: [false],
      is_description_private: [false],
      is_hidden: [false],
      is_location_private: [false],
    });
  }
}
