import { AfterViewInit, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { IProduct, IProductResponse } from '@app/core/interface/products.interface';

import { IProductPublication } from '@app/core/interface/publication.interface';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PublicationFacade } from '@app/core/facade/publication.facade';
import { Router } from '@angular/router';
import { NotificationType, Storage } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { environment } from '@env/environment';
import { MatDialog } from '@angular/material/dialog';
import { AccessPublicationDialogComponent } from './access-publication-dialog/access-publication-dialog.component';

@Component({
  selector: 'app-product-publications',
  templateUrl: './product-publications.component.html',
  styleUrls: ['./product-publications.component.scss'],
  providers: [PublicationFacade],
})
export class ProductPublicationsComponent implements AfterViewInit {
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Input() product!: IProduct | IProductResponse;
  dataSource = new MatTableDataSource<IProductPublication>();
  displayedColumns = ['reference', 'name', 'created', 'updated', 'status', 'actions'];
  url!: string;
  isAmway: boolean = false;
  constructor(
    private _publicationFacade: PublicationFacade,
    private _snackbarService: SnackbarService,
    private _router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private _dataStorageService: DataStorageService,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    const brandName = this._dataStorageService.get('brand_name', Storage.local);
    this.isAmway = brandName?.toLowerCase() === 'amway';
    this._publicationFacade.getPublicationProductChains$(this.product?.uuid).subscribe({
      next: data => {
        this.dataSource.data = data;
      },
      error: this._error.bind(this),
    });
    this.url = `${environment.respectCodeUrl}/brand/amway`;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator.pageSize = 6;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'created':
          return item['created_at'];
        case 'updated':
          return item['updated_at'];
        default:
          return item[property as keyof IProductPublication] as string;
      }
    };
    this._changeDetectorRef.detectChanges();
  }

  editPublication(publication: IProductPublication): void {
    const brandName = this._dataStorageService.get('brand_name', Storage.local);
    const isAmway = brandName?.toLowerCase() === 'amway';
    const isNavigatingToAmway = isAmway;
    if (isAmway) {
      this._router.navigate([`/publish/${publication?.uuid}/custom-publications/amway`], {
        queryParams: { product: this.product.uuid, isNavigatingToAmway: isNavigatingToAmway },
      });
    } else {
      this._router.navigate([
        `chain-setup/chain-management/${publication?.uuid}`,
        { currentUrl: this._router.url, tab: 'chains' },
      ]);
    }
  }

  viewPublication(): void {
    const brandName = this._dataStorageService.get('brand_name', Storage.local);
    const isAmway = brandName?.toLowerCase() === 'amway';
    if (isAmway) {
      window.open(this.url, '_blank');
    } else {
      // TODO Open Publication in respect code
      this._snackbarService.openTypeSnackbar('TODO Open Publication in respect code', NotificationType.success);
    }
  }

  accessToPublication(reference: string): void {
    const ref = this._dialog.open(AccessPublicationDialogComponent, {
      data: reference,
      width: '1160px',
      height: '660px',
      panelClass: ['blueheader-modal'],
    });

    ref.afterClosed().subscribe((res: string) => {
      if (res) this._snackbarService.openTypeSnackbar(`Invitation has been sent to ${res}`, NotificationType.success);
    });
    //On clicking on the link button, it should open a model so the user can access the link to the Respect code page
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
