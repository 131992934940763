<div class="wraper" cdkScrollable>
  <div class="row holder">
    <div
      class="row p-0"
      cdkDropList
      [cdkDropListAutoScrollStep]="20"
      cdkDropListGroup
      [cdkDropListData]="classifications"
      (cdkDropListDropped)="!isChainComplete && dropClassification($event)"
    >
      <div class="col-6 left-side">
        <div class="create-new box" *ngIf="!isChainComplete" (click)="editClassification()">
          <app-rounded-button color="primary" [button_text]="'Create New Chain Phase'" [size]="'md'">
          </app-rounded-button>
        </div>

        <div class="manage-classifications box">
          <div
            class="classification-card"
            *ngFor="let classification of classifications; let i = index"
            cdkDropListGroup
            cdkDrag
            [cdkDragData]="classification"
          >
            <div class="card">
              <div class="classification-info">
                <div class="name">
                  <div class="index-circle" [style.background]="classification?.color">
                    {{ i + 1 }}
                  </div>
                  <h2>{{ classification.name }}</h2>
                  <app-custom-icon
                    *ngIf="!isChainComplete"
                    [icon_category]="'files'"
                    [icon_name]="'edit'"
                    (click)="editClassification(classification)"
                    matTooltip="{{ 'Edit Phase' | translate }}"
                    matTooltipClass="custom-tooltip-center"
                    [matTooltipPosition]="'above'"
                  ></app-custom-icon>
                  <app-custom-icon
                    *ngIf="!isChainComplete"
                    [icon_category]="'essential'"
                    [icon_name]="'trash'"
                    (click)="removeClassification(classification.uuid)"
                    matTooltip="{{ 'Delete Phase' | translate }}"
                    matTooltipClass="custom-tooltip-center"
                    [matTooltipPosition]="'above'"
                  ></app-custom-icon>
                </div>
                <div class="reorder-info" *ngIf="!isChainComplete">
                  <span>{{ 'click & drag up or down' | translate }}</span>
                  <div class="icon-wraper">
                    <app-custom-icon class="edit" [icon_category]="'arrows'" [icon_name]="'arrow-2'"></app-custom-icon>
                  </div>
                </div>
              </div>
              <div *ngIf="!isChainComplete" class="create-group" (click)="addEditGroup(classification.uuid)">
                <h3>{{ 'Create a group' | translate }}</h3>
                <app-custom-icon [icon_category]="'essential'" [icon_name]="'add'"></app-custom-icon>
              </div>
              <div
                class="drop-steps"
                cdkDropList
                [cdkDropListAutoScrollStep]="20"
                id="{{ classification.uuid }}"
                [cdkDropListData]="classification.steps || []"
                (cdkDropListDropped)="
                  !isChainComplete && !$event.item.data.isCustomField && dropItem($event, classification.uuid)
                "
                [cdkDropListConnectedTo]="getConnectedListAll()"
              >
                <div class="steps-drop">
                  <app-step-item
                    *ngFor="let step of classification.steps"
                    [step]="step"
                    [color]="classification.color"
                    [isChainComplete]="isChainComplete"
                    cdkDrag
                    [cdkDragData]="step"
                    (editStepClick)="editStep($event)"
                    (hideStepClick)="hideStep($event)"
                  ></app-step-item>

                  <div class="drop-placeholder" *ngIf="!isChainComplete">
                    <div class="text">
                      <p>Drop the step here</p>
                      <app-custom-icon [icon_category]="'essential'" [icon_name]="'mouse-square'"></app-custom-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="groups"
                cdkDropList
                [cdkDropListAutoScrollStep]="20"
                id="{{ classification.uuid }}"
                [cdkDropListData]="classification.groups"
                (cdkDropListDropped)="!isChainComplete && dropGroup($event, classification)"
                [cdkDropListConnectedTo]="getConnectedListAll()"
              >
                <div class="group-card" *ngFor="let group of classification.groups" cdkDrag [cdkDragData]="group">
                  <div class="group-info">
                    <div class="name">
                      <h2>{{ group.name }}</h2>
                      <app-custom-icon
                        *ngIf="!isChainComplete"
                        [icon_category]="'files'"
                        [icon_name]="'edit'"
                        (click)="addEditGroup(classification.uuid, group)"
                        matTooltip="{{ 'Edit Group' | translate }}"
                        matTooltipClass="custom-tooltip-center"
                        [matTooltipPosition]="'above'"
                      ></app-custom-icon>
                      <app-custom-icon
                        *ngIf="!isChainComplete"
                        [icon_category]="'essential'"
                        [icon_name]="'trash'"
                        (click)="deleteGroup(group.uuid)"
                        matTooltip="{{ 'Delete Group' | translate }}"
                        matTooltipClass="custom-tooltip-center"
                        [matTooltipPosition]="'above'"
                      ></app-custom-icon>
                    </div>
                    <div *ngIf="!isChainComplete" class="reorder-info">
                      <span>{{ 'click & drag up or down' | translate }}</span>
                      <div class="icon-wraper">
                        <app-custom-icon
                          class="edit"
                          [icon_category]="'arrows'"
                          [icon_name]="'arrow-2'"
                        ></app-custom-icon>
                      </div>
                    </div>
                  </div>
                  <div
                    class="steps"
                    cdkDropList
                    [cdkDropListAutoScrollStep]="20"
                    id="{{ classification.uuid + '-' + group.uuid }}"
                    [cdkDropListData]="group.steps"
                    (cdkDropListDropped)="!isChainComplete && dropGroupItem($event, classification.uuid, group.uuid)"
                    [cdkDropListConnectedTo]="getConnectedListAll()"
                  >
                    <app-step-item
                      *ngFor="let groupStep of group.steps"
                      [step]="groupStep"
                      [color]="classification.color"
                      [isChainComplete]="isChainComplete"
                      cdkDrag
                      [cdkDragData]="groupStep"
                      (editStepClick)="editStep($event)"
                      (hideStepClick)="hideStep($event)"
                    ></app-step-item>

                    <div *ngIf="!isChainComplete" class="drop-placeholder">
                      <div class="text">
                        <p>Drop the step here</p>
                        <app-custom-icon [icon_category]="'essential'" [icon_name]="'mouse-square'"></app-custom-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 right-side">
        <div class="create-new-step box" *ngIf="!isChainComplete" (click)="createNewStep()">
          <app-rounded-button color="turquoise" [button_text]="'Create New Step'" [size]="'md'"> </app-rounded-button>
        </div>
        <mat-accordion *ngIf="!isChainComplete">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title
                ><app-custom-icon [icon_category]="'essential'" [icon_name]="'info-circle'"></app-custom-icon>
                <p>{{ 'How to put the steps into phases?' | translate }}</p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
              All of the unclassified steps are your original steps and they must be classified before publishing. In
              order to classify them, please drag & drop each step into a corresponding phase, or hide the step.
            </p>
          </mat-expansion-panel>
        </mat-accordion>
        <div class="manage-steps">
          <h4>{{ 'List of steps that are not inside any phase' | translate }}</h4>
          <div
            cdkDropList
            id="unclassified"
            [cdkDropListAutoScrollStep]="20"
            [cdkDropListData]="unclassifiedSteps || []"
            (cdkDropListDropped)="!isChainComplete && dropItem($event)"
            [cdkDropListConnectedTo]="getConnectedListAll()"
          >
            <app-step-item
              *ngFor="let step of unclassifiedSteps"
              [step]="step"
              cdkDrag
              [cdkDragData]="step"
              [isChainComplete]="isChainComplete"
              (editStepClick)="editStep($event)"
              (hideStepClick)="hideStep($event)"
            ></app-step-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
