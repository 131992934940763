<div *ngIf="isVisibleBackToTopButton()" class="back-btn back-to-top-button cursor-pointer">
  <app-custom-icon
    id="back-btn"
    matTooltip="Back to top"
    [icon_category]="'arrows'"
    [icon_name]="'arrow-up-2'"
    (click)="backToTop()"
  >
  </app-custom-icon>
</div>

<div class="title-content" id="idPrivacyPolicy">
  <p class="title">Privacy policy</p>
  <p class="title-description">
    This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information
    when You use the Service and tells You about Your privacy rights and how the law protects You. We use Your Personal
    data to provide and improve the Service. By using the Service, You agree to the collection and use of information in
    accordance with this Privacy Policy.
  </p>
  <p class="title-description">
    We retain your data for a period of 7 years from the date of your last interaction to comply with legal and
    regulatory requirements. After this period, your data will be securely deleted or anonymized. If you have any
    questions about our data retention practices, please contact us at {{ supportEmail }}
  </p>
</div>
<div class="content">
  <!-- Interpretation and Definitions -->
  <p class="content-title" #interpretation>Interpretation and Definitions</p>
  <p class="content-sub-title">Interpretation</p>
  <div class="content-text">
    <p>
      The words of which the initial letter is capitalized have meanings defined under the following conditions. The
      following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
    </p>
  </div>
  <p class="content-sub-title">Definitions</p>
  <div class="content-text">
    <p>For the purposes of this Privacy Policy:</p>

    <ul class="content-unorder-list">
      <li><b>Account</b> means a unique account created for You to access our Service or parts of our Service.</li>
      Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control"
      means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of
      directors or other managing authority.
      <li>
        <b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to PDNA.
      </li>
      <li>
        <b>Cookies</b> are small files that are placed on Your computer, mobile device or any other device by a website,
        containing the details of Your browsing history on that website among its many uses.
      </li>
      <li>
        <b>Device</b> means any device that can access the Service such as a computer, a cellphone or a digital tablet.
      </li>
      <li><b>Personal Data</b> is any information that relates to an identified or identifiable individual.</li>
      <li><b>Service</b> refers to the Website.</li>
      <li>
        <b>Service Provider</b> means any natural or legal person who processes the data on behalf of the Company. It
        refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the
        Service on behalf of the Company, to perform services related to the Service or to assist the Company in
        analyzing how the Service is used.
      </li>
      <li>
        <b>Usage Data</b> refers to data collected automatically, either generated by the use of the Service or from the
        Service infrastructure itself (for example, the duration of a page visit).
      </li>
      <li>
        <b>You</b> means the individual accessing or using the Service, or the company, or other legal entity on behalf
        of which such individual is accessing or using the Service, as applicable.
      </li>
    </ul>
  </div>
  <!-- Collecting and Using Your Personal Data -->
  <p class="content-title">Collecting and Using Your Personal Data</p>
  <p class="content-sub-title">Types of Data Collected</p>
  <div class="content-text">
    <p>
      <b>Personal Data</b>
    </p>
    <p>
      While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be
      used to contact or identify You. Personally identifiable information may include, but is not limited to:
    </p>
    <ul class="content-unorder-list">
      <li>Email address</li>
      <li>First name and last name</li>
      <li>Phone number</li>
      <li>Address, State, Province, ZIP/Postal code, City</li>
      <li>Usage Data</li>
    </ul>
    <p>
      <b>Usage Data</b>
    </p>
    <p>Usage Data is collected automatically when using the Service.</p>
    <p>
      Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser
      type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on
      those pages, unique device identifiers and other diagnostic data. When You access the Service by or through a
      mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile
      device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system,
      the type of mobile Internet browser You use, unique device identifiers and other diagnostic data. We may also
      collect information that Your browser sends whenever You visit our Service or when You access the Service by or
      through a mobile device.
    </p>
  </div>
  <!-- Use of Your Personal Data -->
  <p class="content-title">Use of Your Personal Data</p>
  <div class="content-text">
    <p>The Company may use Personal Data for the following purposes:</p>
    <ul class="content-unorder-list">
      <li><b>To provide and maintain our Service,</b>including to monitor the usage of our Service.</li>
      <li>
        <b>To manage Your Account</b>to manage Your registration as a user of the Service. The Personal Data You provide
        can give You access to different functionalities of the Service that are available to You as a registered user.
      </li>
      <li>
        <b>For the performance of a contract:</b>the development, compliance and undertaking of the purchase contract
        for the products, items or services You have purchased or of any other contract with Us through the Service.
      </li>
      <li>
        <b>To contact You:</b>To contact You by email, telephone calls, SMS, or other equivalent forms of electronic
        communication, such as a mobile application's push notifications regarding updates or informative communications
        related to the functionalities, products or contracted services, including the security updates, when necessary
        or reasonable for their implementation.
      </li>
      <li><b>To manage Your requests:</b>To attend and manage Your requests to Us.</li>
      <li>
        <b>For business transfers:</b>We may use Your information to evaluate or conduct a merger, divestiture,
        restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a
        going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us
        about our Service users is among the assets transferred.
      </li>
    </ul>
  </div>
  <!-- Retention of Your Personal Data -->
  <p class="content-title">Retention of Your Personal Data</p>
  <div class="content-text">
    <p>
      PDNA will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy
      Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations
      (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and
      enforce our legal agreements and policies.
    </p>
    <p>
      The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a
      shorter period of time, except when this data is used to strengthen the security or to improve the functionality
      of Our Service, or We are legally obligated to retain this data for longer time periods.
    </p>
  </div>
  <!-- Delete Your Personal Data -->
  <p class="content-title">Delete Your Personal Data</p>
  <div class="content-text">
    <p>
      You have the right to delete or request that We assist in deleting the Personal Data that We have collected about
      You. Our Service may give You the ability to delete certain information about You from within the Service. You may
      update, amend, or delete Your information at any time by signing in to Your Account. You may also contact Us to
      request access to, correct, or delete any personal information that You have provided to Us.
    </p>
  </div>
  <!-- Contact Us -->
  <p class="content-title">Contact Us</p>
  <div class="content-text">
    <p>If you have any questions about this Privacy Policy, contact us on {{ infoEmail }}.</p>
  </div>
</div>
